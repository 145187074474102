//React
import React from "react"
import { useState, useEffect } from "react"
//Fetch
import axios from "axios"
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext"
//Bootstrap
import { Form } from "react-bootstrap"
//Notifications
import { toast } from "react-toastify"
//Components
import FormControl from "../../../Components/Forms/FormControl"
import Spinner from "../../../Components/Loading/Spinner"
import Error from "../../../Components/Errors/Error"
import FloatingInteractionTypeSelect from "../../../Components/SelectDropdowns/FloatingInteractionTypeSelect"

export default function CreateInteractionForm({
  enquiryId,
  updateEnquiry,
}) {
  const { user } = useAuthContext()
  const [form, setForm] = useState({})
  const [users, setUsers] = useState([])
  const [contacts, setContacts] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const [contactSearch, setContactSearch] = useState("")
  const [showContacts, setShowContacts] = useState(false)
  const [selectingContact, setSelectingContact] = useState(false)
  const [filteredContacts, setFilteredContacts] = useState([])

  useEffect(() => {
    const getselectOptions = async () => {
      try {
        const userRes = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/users?pageNumber=1&pageSize=10000`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        const contactRes = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/contacts?pageNumber=1&pageSize=10000`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )

        setContacts(contactRes.data.results)
        setUsers(userRes.data)
      } catch (error) {
        setError(error.response.status)
      } finally {
        setLoading(false)
      }
    }
    getselectOptions()
  }, [user])

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    //create
    try {
      const res = await axios.post(
        `https://${process.env.REACT_APP_IP}/api/interaction/enquiry/${enquiryId}`,
        form,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      toast.success("Interaction created.")
      updateEnquiry(res.data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    var filtered = contacts.filter(
      (x) =>
        x.firstName.toLowerCase().includes(contactSearch) ||
        x.lastName.toLowerCase().includes(contactSearch) ||
        (x.firstName + " " + x.lastName)
          .toLowerCase()
          .includes(contactSearch) ||
        (x.lastName + " " + x.firstName).toLowerCase().includes(contactSearch)
    )

    setFilteredContacts(filtered)
  }, [contactSearch])

  useEffect(() => {
    setFilteredContacts(contacts)
  }, [contacts])

  if (!loading) {
    return error ? (
      <Error code={error} />
    ) : (
      <Form onSubmit={handleSubmit} className=" p-2 p-md-3">
        <div className="mb-3">
          <h4 className="ps-2">Interaction Details</h4>
          <div className="d-flex flex-column flex-md-row ">
            <Form.Group className="mb-1 p-1 col-md-6">
              <div className="form-floating">
                <Form.Select
                  value={form.interactionDirection ?? ""}
                  onChange={(e) => setField("interactionDirection", e.target.value)}
                  required
                >
                  <option disabled value="">
                    Select status...
                  </option>
                  <option value="Incoming">Incoming</option>
                  <option value="Outgoing">Outgoing</option>
                  {/* <option value="Other">Other</option> */}
                </Form.Select>
                <label>
                  Interaction Status <span className="text-danger">*</span>
                </label>
              </div>
            </Form.Group>
            
              <Form.Group
                className={`mb-1 p-1 col-md-6`}
                style={{ position: "relative" }}
              >
                <div className="form-floating">
                  <input
                    onBlur={() => {
                      if (!selectingContact) {
                        setShowContacts(false)
                      }
                    }}
                    onFocus={() => {
                      setShowContacts(true)
                      setContactSearch("")
                    }}
                    required
                    placeholder="Contact Search"
                    type="text"
                    className="form-control"
                    value={contactSearch ?? ""}
                    onChange={(e) => setContactSearch(e.target.value)}
                  />
                  <label>
                    Contact Search <span className="text-danger">*</span>
                  </label>
                </div>
                {showContacts && (
                  <div
                    onMouseEnter={() => setSelectingContact(true)}
                    onMouseLeave={() => setSelectingContact(false)}
                    className="mb-2 searchDropDownCon overflow-auto innerCon"
                    style={{ maxHeight: "300px" }}
                  >
                    {filteredContacts.map((contact) => (
                      <div
                        key={contact.id}
                        className="hoverMe listItem p-1"
                        onClick={() => {
                          setField("contactId", contact.id)
                          setShowContacts(false)
                          setContactSearch(
                            contact.firstName + " " + contact.lastName
                          )
                        }}
                      >
                        {contact.lastName + ", " + contact.firstName}
                      </div>
                    ))}
                  </div>
                )}
              </Form.Group>
            

            {/*form.interactionDirection === "Outgoing" && (
              <Form.Group className="mb-1 p-1 col-md-6">
                <div className="form-floating">
                  <Form.Select
                    value={form.userId ?? ""}
                    onChange={(e) => {
                      setField("userId", e.target.value)
                    }}
                    required
                  >
                    <option disabled value="">
                      Select user...
                    </option>

                    {users.map((user) => (
                      <option
                        key={user.id}
                        value={user.id}
                      >{`${user.firstName} ${user.lastName}`}</option>
                    ))}
                   
                  </Form.Select>
                  <label>
                    Select User <span className="text-danger">*</span>
                  </label>
                </div>
              </Form.Group>
            )*/}
          </div>
          <div className="d-flex flex-column flex-md-row ">
            <FloatingInteractionTypeSelect
              value={form.type}
              field={"type"}
              setField={setField}
              classes={"col-md-4"}
            />
            <FormControl
              value={form.dateAndTime ? form.dateAndTime : ""}
              type={"datetime-local"}
              label={"Interaction Date and Time"}
              field={"dateAndTime"}
              setField={setField}
              classes={"col-md-4"}
              required
            />
            <FormControl
              value={form.timeSpent ? form.timeSpent : ""}
              type="number"
              label={"Time Spent (hrs)"}
              field={"timeSpent"}
              setField={setField}
              classes={"col-md-4"}
            />
          </div>

          <div>
            <Form.Group className={`mb-1  p-1`}>
              <div className="form-floating">
                <Form.Control
                  as="textarea"
                  rows={5}
                  value={form.description ?? ""}
                  placeholder="Client name"
                  onChange={(e) => setField("description", e.target.value)}
                  style={{ minHeight: "120px" }}
                  required
                />
                <label>
                  Description <span className="text-danger">*</span>
                </label>
              </div>
            </Form.Group>
          </div>
          <div className="d-flex flex-column flex-md-row "></div>
        </div>

        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </Form>
    )
  } else {
    return <Spinner text={"Loading..."} />
  }
}
