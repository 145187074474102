import React from "react";
import { Form } from "react-bootstrap";

export default function FloatingInteractionTypeSelect({
  value,
  setField,
  field,
  classes,
  required = false,
}) {

    console.log("value set to:", value)

  return (
    <Form.Group className={`mb-1 ${classes} p-1`}>
      <div className="form-floating">
        <Form.Select
          value={value ?? ""}
          required={required}
          onChange={(e) => setField(field, e.target.value)}
        >
          <option disabled value="">
            Select type
          </option>
          <option value="Conversation">Conversation</option>
          <option value="CVH Network">CVH Network</option>
          <option value="Email">Email</option>
          <option value="Event">Event</option>
          <option value="Facebook">Facebook</option>
          <option value="Internal">Internal</option>
          <option value="Meeting">Meeting</option>
          <option value="Phone">Phone</option>
          <option value="Post">Post</option>
          <option value="Twitter">Twitter</option>
        </Form.Select>
        <label>
          Interaction Type {required && <span className="text-danger">*</span>}
        </label>
      </div>
    </Form.Group>
  );
}
