//React
import React from "react"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
//Fetch
import axios from "axios"
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext"
//Icons
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Notifications
import { toast } from "react-toastify"
//Bootstrap
import { FloatingLabel, Form } from "react-bootstrap"
//Components
import FormCheck from "../../../Components/Forms/FormCheck"
import FormControl from "../../../Components/Forms/FormControl"
import FloatingDistrictSelect from "../../../Components/SelectDropdowns/FloatingDistrictSelect"
import FloatingParishSelect from "../../../Components/SelectDropdowns/FloatingParishSelect"

import FloatingUnitarySelect from "../../../Components/SelectDropdowns/FloatingUnitarySelect"

import AssetSearch from "../../../Components/Search/AssetSearch"
import FloatingLocalitySelect from "../../../Components/SelectDropdowns/FloatingLocalitySelect"

export default function CreateEditContactForm({
  contact,
  setContacts,
  setContact,
  updateContacts,
  setEdit,
}) {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const [form, setForm] = useState({
    tags: [],
    subscribedToNewsletter: true,
    groupName: "",
    locality: "Unknown",
    actMailings: "Unknown",
    communityBuildings: [],
    communityBuildingsToRemove: [],
      dateSupporterJoined: null
  })
  const [submitting, setSubmitting] = useState(false)
  const [deletedTags, setDeletedTags] = useState([])

  useEffect(() => {
    if (contact) {
      setForm({ ...contact, communityBuildingsToRemove: [] })
    }
  }, [contact])

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })

      console.log(form);
  }

    const handleSubmit = async (e) => {
        console.log("about to save")
        console.log(form);
    setSubmitting(true)
    e.preventDefault()
    if (contact) {
      //Edit
      try {
        const res = await axios.patch(
          `https://${process.env.REACT_APP_IP}/api/contact/${form.id}`,
          { ...form, deletedTags: deletedTags },
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        toast.success("Contact updated.")
        setEdit(false)
        setContact(res.data)
        const listView = {
          id: res.data.id,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          contactType: res.data.contactType,
        }
        updateContacts(listView)
      } catch (err) {
        console.log(err)
        toast.error(
          "Oops that didnt work. Please try again. If the issue persists contact the administrator."
        )
      } finally {
        setSubmitting(false)
      }
    } else {
      //create
      try {
        const res = await axios.post(
          `https://${process.env.REACT_APP_IP}/api/contact`,
          { ...form, deletedTags: deletedTags },
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        toast.success("Contact created.")
        setForm({ tags: [], subscribedToNewsletter: true, groupName: "" })

        setContacts((prev) => {
          return [...prev, res.data]
        })
        navigate(`../view_contact/${res.data.id}`)
      } catch (err) {
        console.log(err)
        toast.error(
          "Oops that didnt work. Please try again. If the issue persists contact the administrator."
        )
      } finally {
        setSubmitting(false)
      }
    }
  }

  const handleDeleteBuilding = (building) => {
    setForm((prev) => {
      return {
        ...prev,
        communityBuildingsToRemove: [...prev.communityBuildingsToRemove, building.id],
        communityBuildings: prev.communityBuildings.filter((b) => b.id !== building.id),
      }
    })
  }

  

  return (
    <Form onSubmit={handleSubmit} className="primaryShadow p-2 p-md-3">
      <div className="mb-3">
        <h4 className="ps-2">Personal Details</h4>
        <hr />
        <div className="d-flex flex-column flex-md-row  ">
          <Form.Group className="mb-1 p-1 col">
            <FloatingLabel label={"Title"}>
              <Form.Select
                value={form.title ?? ""}
                onChange={(e) => setField("title", e.target.value)}
              >
                <option disabled value="">
                  Select...
                </option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Ms">Ms</option>
                <option value="Miss">Miss</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>

          <FormControl
            value={form.firstName}
            label={"First Name"}
            field={"firstName"}
            setField={setField}
            classes={"col-md-5"}
            required
          />
          <FormControl
            value={form.lastName}
            label={"Last Name"}
            field={"lastName"}
            setField={setField}
            classes={"col-md-5"}
            required
          />
        </div>
        <div className="d-flex flex-column flex-md-row ">
          <FloatingLocalitySelect
            value={form.locality}
            label={"Locality/Panel"}
            field={"locality"}
            setField={setField}
            classes={"col-md-4"}
          />
          <FloatingUnitarySelect
            value={form.unitaryAuthority}
            field={"unitaryAuthority"}
            setField={setField}
            classes={"col-md-4"}
            required
          />

          <Form.Group className="mb-1 p-1 col-md-4">
            <div className="form-floating">
              <Form.Select
                value={form.contactType ?? ""}
                onChange={(e) => setField("contactType", e.target.value)}
                required
              >
                <option disabled value="">
                  Select type...
                </option>
                <option value="ACT Trustee">ACT Trustee</option>
                <option value="Attendee">Attendee</option>
                <option value="Community Asset/Building Group">
                  Community Asset/Building Group
                </option>
                <option value="Community Planning Group">
                  Community Planning Group
                </option>
                <option value="Individual">Individual</option>
                <option value="Local Authority">Local Authority</option>
                <option value="Parish Council">Parish Council</option>
                <option value="Strategic Partner">Strategic Partner</option>
                <option value="Other">Other</option>
              </Form.Select>
              <label>
                Contact Type <span className="text-danger">*</span>
              </label>
            </div>
          </Form.Group>
        </div>

        <div className="d-flex flex-column flex-md-row ">
          <FormControl
            value={form.groupName ?? ""}
            label={"Group/Organization Name"}
            field={"groupName"}
            setField={setField}
            classes={"col"}
          />
          <FormControl
            value={form.jobTitle ?? ""}
            label={"Job Title"}
            field={"jobTitle"}
            setField={setField}
            classes={"col-md-5"}
          />
        </div>

        <div className="d-flex flex-column flex-md-row ">
          <FloatingParishSelect
            value={form.parish}
            field={"parish"}
            setField={setField}
            classes={"col-md-4"}
          />
          <FloatingDistrictSelect
            value={form.district}
            field={"district"}
            setField={setField}
            classes={"col-md-4"}
          />
        </div>
      </div>
      <div className="mb-3">
        <h4 className="ps-2">Address</h4>
        <hr />
        <div className="d-flex flex-column flex-md-row  ">
          <FormControl
            value={form.addressLine1}
            label={"Address Line 1"}
            field={"addressLine1"}
            setField={setField}
            classes={"col-md-4"}
          />
          <FormControl
            value={form.addressLine2}
            label={"Address Line 2"}
            field={"addressLine2"}
            setField={setField}
            classes={"col-md-4"}
          />
          <FormControl
            value={form.addressLine3}
            label={"Address Line 3"}
            field={"addressLine3"}
            setField={setField}
            classes={"col-md-4"}
          />
        </div>
        <div className="d-flex flex-column flex-md-row ">
          <FormControl
            value={form.county}
            label={"County"}
            field={"county"}
            setField={setField}
            classes={"col-md-4"}
          />
          <FormControl
            value={form.town}
            label={"Town"}
            field={"town"}
            setField={setField}
            classes={"col-md-4"}
          />
          <FormControl
            value={form.postcode}
            label={"Postcode"}
            field={"postcode"}
            setField={setField}
            classes={"col-md-4"}
          />
        </div>
      </div>
      <div className="mb-3">
        <h4 className="ps-2">Contact Info</h4>
        <hr />
        <div className="d-flex flex-column flex-md-row ">
          <FormControl
            value={form.primaryEmail}
            label={"Primary Email"}
            field={"primaryEmail"}
            type={"email"}
            setField={setField}
            classes={"col-md-4"}
          />
          <FormControl
            value={form.secondaryEmail}
            label={"Secondary Email"}
            field={"secondaryEmail"}
            type={"email"}
            setField={setField}
            classes={"col-md-4"}
          />
        </div>
        <div className="d-flex flex-column flex-md-row ">
          <FormControl
            value={form.landline}
            label={"Landline"}
            field={"landline"}
            type={"tel"}
            setField={setField}
            classes={"col-md-4"}
          />
          <FormControl
            value={form.mobile}
            label={"Mobile"}
            field={"mobile"}
            type={"tel"}
            setField={setField}
            classes={"col-md-4"}
          />
        </div>
      </div>

      <div className="d-flex flex-column flex-md-row mb-2">
        <div className="col">
          <h4 className="ps-1">ACT Mailings</h4>
          <hr />
          <Form.Group className="mb-1 p-1 col-md-6">
            <div className="form-floating">
              <Form.Select
                value={form.actMailings ?? ""}
                onChange={(e) => setField("actMailings", e.target.value)}
                required
              >
                <option disabled value="">
                  Select type...
                </option>
                <option value="Unknown">Unknown</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Group Mailings">Group Mailings</option>
              </Form.Select>
              <label>
                ACT Mailings <span className="text-danger">*</span>
              </label>
            </div>
          </Form.Group>

          <FormCheck
            value={form.actStaff}
            label={"ACT Staff"}
            field={"actStaff"}
            setField={setField}
            classes={"col-md-6"}
          />
          <FormCheck
            value={form.actSupporter}
            label={"ACT Supporter"}
            field={"actSupporter"}
            setField={setField}
            classes={"col-md-6"}
          />
          {form.actSupporter && (
            <FormControl
              value={form.dateSupporterJoined ? form.dateSupporterJoined.split("T")[0] : ""}
              type={"date"}
              label={"Date Joined"}
              field={"dateSupporterJoined"}
              setField={setField}
              classes={"col-md-6"}
            />
          )}
          <FormCheck
            value={form.actTrustee}
            label={"ACT Trustee"}
            field={"actTrustee"}
            setField={setField}
            classes={"col-md-6"}
          />

          <FormCheck
            value={form.supporterCheckApril2020}
            label={"Supporter Check"}
            field={"supporterCheckApril2020"}
            setField={setField}
            classes={"col-md-6"}
          />
          <FormControl
            value={form.letterSent ? form.letterSent.split("T")[0] : ""}
            type={"date"}
            label={"Letter Sent"}
            field={"letterSent"}
            setField={setField}
          />
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row mb-2">
        <div className="col">
          <h4 className="ps-1">Buildings</h4>
          <hr />
          <AssetSearch
            setForm={setForm}
            idsToSkip={form.communityBuildings && form.communityBuildings.map((b) => b.id)}
          />
          { form.communityBuildings.map((building) => (
            <div
              key={building.id}
              className="col-md-3 innerCon p-2 d-flex flex-md-column align-items-center justify-content-between"
            >
              <div className="mb-md-2"></div>
              <div className="d-flex flex-column justify-content-center align-items-center mb-md-2">
                <h5 className="d-flex">{building.assetName}</h5>
                <p className="m-0 text-center">{building.assetType}</p>
                <p className="m-0">{building.district}</p>
                <p className="m-0">{building.parish}</p>
              </div>

              <div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleDeleteBuilding(building)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button disabled={submitting} type="submit" className="btn btn-primary">
          {!submitting ? (
            "Submit"
          ) : (
            <div>
              Please wait <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          )}
        </button>
      </div>
    </Form>
  )
}
