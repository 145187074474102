//React
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//React Select

//Fetch
import axios from "axios";
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext";
//Icons
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Notifications
import { toast } from "react-toastify";
//Bootstrap
import { FloatingLabel, Form } from "react-bootstrap";

//Components
import FormControl from "../../../Components/Forms/FormControl";
import FormCheck from "../../../Components/Forms/FormCheck"
import FloatingDistrictSelect from "../../../Components/SelectDropdowns/FloatingDistrictSelect";
import FloatingParishSelect from "../../../Components/SelectDropdowns/FloatingParishSelect";

import FloatingUnitarySelect from "../../../Components/SelectDropdowns/FloatingUnitarySelect";
import FloatingTimesheetCodeSelect from "../../../Components/SelectDropdowns/FloatingTimesheetCodeSelect";
import FloatingGroupTypeSelect from "../../../Components/SelectDropdowns/FloatingGroupTypeSelect";
import AssetSearch from "../../../Components/Search/AssetSearch";
import FloatingInitialSource from "../../../Components/SelectDropdowns/FloatingInitialSource";
import ContactSearch from "../../../Components/Search/ContactSearch";
import LinkedTags from "../../../Components/Tags/LinkedTags";
import FloatingDesignationSelect from "../../../Components/SelectDropdowns/FloatingDesignationSelect";
import FloatingLocalitySelect from "../../../Components/SelectDropdowns/FloatingLocalitySelect"

export default function CreateEditEnquiryForm({
  enquiry,
  setEnquiries,
  setEnquiry,
  updateEnquiries,
  setEdit,
  tagList,
  setTagList,
}) {
  console.log(tagList);
  const { user, dispatch } = useAuthContext();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    contacts: [],
    communityBuildings: [],
    tags: [],
    contactsToRemove: [],
    communityBuildingsToRemove: [],
    deletedTags: [],
  });
    const [contactForm, setContactForm] = useState({
        actMailings: "Unknown",
        locality: "Unknown"
    });
  const [userList, setUserList] = useState();
  const [timesheetCodeList, setTimesheetCodeList] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [submittingContact, setSubmittingContact] = useState(false);
  const [error, setError] = useState(false);

  const [addContact, setAddContact] = useState(false);

  const [deletedTags, setDeletedTags] = useState([]);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    const listUsers = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/users?pageSize=500`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        );
        setUserList(res.data);
      } catch (error) {
        setError(true);
      } finally {
        setPageLoading(false);
      }
    };

    const listTimesheetCodes = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/timesheetcodes/TimesheetCode?active=true`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        );

        
        setTimesheetCodeList(res.data);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch({ type: "LOGOUT" });
          navigate("../../expired");
        } else {
          setError(error.response.status);
        }
      } finally {
        setLoading(false);
      }
    };

    listTimesheetCodes();
    listUsers();
  }, [user]);

    useEffect(() => {
      console.log("test")
        if (enquiry) {
            console.log("set code")
        console.log(enquiry.timesheetCode)
      setForm({
        ...enquiry,
        timesheetCodeId: enquiry.timesheetCodeId,
        contactsToRemove: [],
        communityBuildingsToRemove: [],
        deletedTags: [],
      });
        console.log("Contacts:")
        console.log(form.contacts)
      console.log("Community Buildings:")
        console.log(form.communityBuildings)
    }
  }, [enquiry]);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const setContact = (field, value) => {
    setContactForm({
      ...contactForm,
      [field]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
        console.log("handle submit")
      console.log(form);
      setErrorText("");
      setSubmitting(true);
      if (enquiry) {
        //Edit
        try {
          const res = await axios.patch(
            `https://${process.env.REACT_APP_IP}/api/enquiry/${form.id}`,
            {
              ...form,
              deletedTags: deletedTags,
            },
            {
              headers: { Authorization: `bearer ${user.token}` },
            }
          );
          toast.success("Enquiry updated.");
          setEdit(false);
          setEnquiry(res.data);
          updateEnquiries(res.data);
        } catch (err) {
          console.log(err);
          toast.error(
            "Oops that didnt work. Please try again. If the issue persists contact the administrator."
          );
        } finally {
          setSubmitting(false);
        }
      } else {
        //create
        try {
          const res = await axios.post(
            `https://${process.env.REACT_APP_IP}/api/enquiry`,
            { ...form, timesheetCodeId: form.timesheetCodeId },
            {
              headers: { Authorization: `bearer ${user.token}` },
            }
          );
          toast.success("Enquiry created.");
          setForm({});
          setEnquiries((prev) => {
            return [...prev, res.data];
          });
          navigate(`../view_enquiry/${res.data.id}`);
        } catch (err) {
          console.log(err);
          toast.error(
            "Oops that didnt work. Please try again. If the issue persists contact the administrator."
          );
        } finally {
          setSubmitting(false);
        }
      
    }
  };

  const handleRemoveContact = (contact) => {
    setForm((prev) => ({
      ...prev,
      contacts: prev.contacts.filter((c) => c.id !== contact.id),
      contactsToRemove: [...prev.contactsToRemove, contact.id],
    }));
  };




    const handleRemoveCommunityBuilding = (building) => {
        setForm((prev) => ({
            ...prev,
            communityBuildings: prev.communityBuildings.filter((c) => c.id !== building.id),
            communityBuildingsToRemove: [...prev.communityBuildingsToRemove, building.id],
        }));
    };

    const handleSelectAsset = (asset) => {
        setForm((prev) => {
            return {
                ...prev,
                communityAssetId: asset.id,
                communityAsset: asset,
            };
        });
    };

  const addNewContact = async (e) => {
    setSubmittingContact(true);
    e.preventDefault();
    try {
      const res = await axios.post(
        `https://${process.env.REACT_APP_IP}/api/contact`,
        contactForm,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      );
      toast.success("Contact created.");

      setForm((prev) => {
        return {
          ...prev,
          contacts: [...prev.contacts, res.data],
        };
      });
    } catch (err) {
      console.log(err);
      toast.error(
        "Oops that didnt work. Please try again. If the issue persists contact the administrator."
      );
    } finally {
      setSubmittingContact(false);
      setAddContact(false);
      setContactForm({});
    }
  };

  if (!pageLoading && !loading) {
    return error ? (
      <div>Error here...</div>
    ) : (
      <Form onSubmit={handleSubmit} className="primaryShadow p-2 p-md-3">
        <div className="mb-3">
          <div className="d-md-flex">
            <FormControl
              value={form.title}
              label={"Title"}
              field={"title"}
              setField={setField}
              classes={"col"}
              required
            />
            <Form.Group className="mb-1 p-1 col-md-3">
              <FloatingLabel label={"Current State"}>
                <Form.Select
                  value={form.state ?? "Open"}
                  required
                  onChange={(e) => setField("state", e.target.value)}
                >
                  <option disabled value="">
                    Select a state
                  </option>
                  <option value="Open">Open</option>
                  <option value="Closed-Resolved">Closed - Resolved</option>
                  <option value="Closed-UnResolved">Closed - Unresolved</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </div>
        </div>
        <div className="mb-3">
          <h4 className="ps-2">Group Details</h4>
          <hr />
          <div className="d-md-flex">
            <FormControl
              value={form.groupName}
              label={"Group Name"}
              field={"groupName"}
              setField={setField}
              classes={"col-md-6"}
            />
            <FloatingGroupTypeSelect
              value={form.groupType}
              field={"groupType"}
              setField={setField}
              classes={"col-md-6"}
              required
            />
          </div>
          <div className="d-md-flex">
            <FloatingParishSelect
              value={form.parish}
              field={"parish"}
              setField={setField}
              classes={"col-md-6"}
            />
            <FloatingDistrictSelect
              value={form.district}
              field={"district"}
              setField={setField}
              classes={"col-md-6"}
              required
            />
          </div>
          <div className="d-md-flex">
            <FloatingUnitarySelect
              value={form.unitaryAuthority}
              field={"unitaryAuthority"}
              setField={setField}
              classes={"col-md-6"}
              required
                        />
           
                <FloatingTimesheetCodeSelect
                    value={form.timesheetCodeId}
                    field={"timesheetCodeId"}
                    setField={setField}
                    classes={"col-md-6"}
                    timesheetCodeList={timesheetCodeList}
                    required
                />
          </div>
        </div>

        <LinkedTags
          setField={setField}
          tags={form.tags}
          deletedTags={deletedTags}
          setDeletedTags={setDeletedTags}
          setTagList={setTagList}
          tagList={tagList}
          type={"ENQUIRY"}
        />
        <div className="d-flex">
          <h4 className="ms-2 me-4 mt-1">Contacts</h4>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setAddContact(true)}
          >
            New Contact
          </button>
        </div>
        <hr />
        {addContact && (
          <div>
            <div className="d-flex flex-column flex-md-row  ">
              <Form.Group className="mb-1 p-1 col">
                <FloatingLabel label={"Title"}>
                  <Form.Select
                    value={contactForm.title ?? ""}
                    onChange={(e) => setContact("title", e.target.value)}
                  >
                    <option disabled value="">
                      Select...
                    </option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Ms">Ms</option>
                    <option value="Miss">Miss</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <FormControl
                value={contactForm.firstName}
                label={"First Name"}
                field={"firstName"}
                setField={setContact}
                classes={"col-md-5"}
                required
              />
              <FormControl
                value={contactForm.lastName}
                label={"Last Name"}
                field={"lastName"}
                setField={setContact}
                classes={"col-md-5"}
                required
              />
            </div>

            <div className="d-flex flex-column flex-md-row ">
              <FormControl
                value={contactForm.primaryEmail}
                label={"Primary Email"}
                field={"primaryEmail"}
                type={"email"}
                setField={setContact}
                classes={"col-md-6"}
              />
              <FormControl
                value={contactForm.secondaryEmail}
                label={"Secondary Email"}
                field={"secondaryEmail"}
                type={"email"}
                setField={setContact}
                classes={"col-md-6"}
              />
            </div>

            <div className="d-flex flex-column flex-md-row ">
              <FormControl
                value={contactForm.landline}
                label={"Landline"}
                field={"landline"}
                type={"tel"}
                setField={setContact}
                classes={"col-md-6"}
              />
              <FormControl
                value={contactForm.mobile}
                label={"Mobile"}
                field={"mobile"}
                type={"tel"}
                setField={setContact}
                classes={"col-md-6"}
              />
            </div>

            <div className="d-flex flex-column flex-md-row  ">
                <FloatingLocalitySelect
                    value={contactForm.locality}
                    label={"Locality/Panel"}
                    field={"locality"}
                    setField={setContact}
                    classes={"col-md-4"}
                    required
                />
              <FloatingUnitarySelect
                value={contactForm.unitaryAuthority}
                field={"unitaryAuthority"}
                setField={setContact}
                classes={"col-md-4"}
                required
              />

              <Form.Group className="mb-1 p-1 col-md-4">
                <div className="form-floating">
                  <Form.Select
                    value={contactForm.contactType ?? ""}
                    onChange={(e) => setContact("contactType", e.target.value)}
                    required
                  >
                    <option disabled value="">
                      Select type...
                    </option>
                    <option value="ACT Trustee">ACT Trustee</option>
                    <option value="Attendee">Attendee</option>
                    <option value="Community Asset/Building Group">
                      Community Asset/Building Group
                    </option>
                    <option value="Community Planning Group">
                      Community Planning Group
                    </option>
                    <option value="Individual">Individual</option>
                    <option value="Local Authority">Local Authority</option>
                    <option value="Parish Council">Parish Council</option>
                    <option value="Strategic Partner">Strategic Partner</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                  <label>
                    Contact Type <span className="text-danger">*</span>
                  </label>
                </div>
              </Form.Group>
            </div>

            {/* ACT Mailings */}
            <div className="d-flex flex-column flex-md-row">
                            
                <Form.Group className="mb-1 p-1 col-md-6">
                    <div className="form-floating">
                        <Form.Select
                            value={contactForm.actMailings ?? "Unknown"}
                            onChange={(e) => setContact("actMailings", e.target.value)}
                            required
                        >
                            <option disabled value="">
                                Select type...
                            </option>
                            <option value="Unknown">Unknown</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="Group Mailings">Group Mailings</option>
                        </Form.Select>
                        <label>
                            ACT Mailings <span className="text-danger">*</span>
                        </label>
                    </div>
                </Form.Group>
            </div>

            {/* ACT Supporter */}
            <div className="d-flex flex-column flex-md-row">         
                <FormCheck
                    value={contactForm.actSupporter}
                    label={"ACT Supporter"}
                    field={"actSupporter"}
                    setField={setContact}
                    classes={"col-md-6"}
                            />
            </div>

            {/* Date Supporter Joined */}
            {contactForm.actSupporter && (<div className="d-flex flex-column flex-md-row">
                <FormControl
                    value={contactForm.dateSupporterJoined ? contactForm.dateSupporterJoined.split("T")[0] : ""}
                    type={"date"}
                    label={"Date Joined"}
                    field={"dateSupporterJoined"}
                    setField={setContact}
                    classes={"col-md-6"}
                />
            </div>)}
             

                           
         

            <div className="d-flex justify-content-end me-2 mb-3 mt-1">
              <button
                disabled={submittingContact}
                type="button"
                onClick={(e) => addNewContact(e)}
                className="btn btn-primary"
              >
                {!submittingContact ? (
                  "Add Contact"
                ) : (
                  <div>
                    Please wait <FontAwesomeIcon icon={faSpinner} spin />
                  </div>
                )}
              </button>
            </div>
          </div>
        )}
        <ContactSearch setForm={setForm} contacts={form.contacts} />
        <div className="my-grid-container">
          {form.contacts.map((contact) => (
            <div
              className=" p-2 innerCon mb-1 d-flex justify-content-between align-items-center "
              key={contact.id}
            >
              <div>
                <h5>
                  {contact.firstName} {contact.lastName}
                </h5>
                <div>{contact.contactType}</div>
              </div>
              <div className="">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleRemoveContact(contact)}
                >
                  X
                </button>
              </div>
            </div>
          ))}
        </div>

        <h4 className="ms-2 mt-3">Community Building</h4>
        <hr />
        
                <AssetSearch setForm={setForm} />
                <div className="my-grid-container">
                    {form.communityBuildings.map((building) => (
                        <div
                            className=" p-2 innerCon mb-1 d-flex justify-content-between align-items-center "
                            key={building.id}
                        >
                            <div>
                                <h5>{building.assetName}</h5>
                                <div>{building.assetType}</div>
                            </div>
                            <div className="">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleRemoveCommunityBuilding(building)}
                                >
                                    X
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

        

        <div className="mb-3">
          <h4 className="ps-2 mt-4">Enquiry Details</h4>
          <hr />
          <div className="d-md-flex">
            <FormControl
              value={form.dateOfQuery ? form.dateOfQuery.split("T")[0] : ""}
              type={"date"}
              label={"Query Received"}
              field={"dateOfQuery"}
              setField={setField}
              classes={"col-md-6"}
              required
            />
            <Form.Group className="mb-1 p-1 col-md-6">
              <div className="form-floating">
                <Form.Select
                  value={form.queryTakenById ?? ""}
                  required
                  onChange={(e) => setField("queryTakenById", e.target.value)}
                >
                  <option disabled value="">
                    Select user...
                  </option>
                  {userList.map((u) => (
                    <option key={u.id} value={u.id}>
                      {u.firstName} {u.lastName}
                    </option>
                  ))}
                </Form.Select>
                <label>
                  Query Taken by <span className="text-danger">*</span>
                </label>
              </div>
            </Form.Group>
          </div>
          <div className="d-md-flex">
            <Form.Group className="mb-1 p-1 col-md-6">
              <div className="form-floating">
                <Form.Select
                  required
                  value={form.leadForOngoingId ?? ""}
                  onChange={(e) => setField("leadForOngoingId", e.target.value)}
                >
                  <option disabled value="">
                    Select user...
                  </option>
                  {userList.map((u) => (
                    <option key={u.id} value={u.id}>
                      {u.firstName} {u.lastName}
                    </option>
                  ))}
                </Form.Select>
                <label>
                  Lead For Future <span className="text-danger">*</span>
                </label>
              </div>
            </Form.Group>
            <FloatingInitialSource
              classes={"col-md-6"}
              form={form}
              setField={setField}
            />
          </div>

          <div className="d-md-flex">
            <Form.Group className="mb-3 p-1 col-md-4">
              <FloatingLabel label={"Action Completed By"}>
                <Form.Select
                  value={form.actionCompletedById ?? ""}
                  onChange={(e) =>
                    setField("actionCompletedById", e.target.value)
                  }
                >
                  <option disabled value="">
                    Select user...
                  </option>
                  {userList.map((u) => (
                    <option key={u.id} value={u.id}>
                      {u.firstName} {u.lastName}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <FormControl
              value={
                form.dateActionCompleted
                  ? form.dateActionCompleted.split("T")[0]
                  : ""
              }
              type={"date"}
              label={"Action Completed"}
              field={"dateActionCompleted"}
              setField={setField}
              classes={"col-md-4"}
            />

            <FormControl
              value={
                form.dateOfLastContact
                  ? form.dateOfLastContact.split("T")[0]
                  : ""
              }
              type={"date"}
              label={"Last Contact"}
              field={"dateOfLastContact"}
              setField={setField}
              classes={"col-md-4"}
            />
          </div>
        </div>
        <div>
          <h4 className="ms-2">Nature Of Query</h4>
          <hr />
          <Form.Group className={`mb-1  p-1`}>
            <Form.Control
              as="textarea"
              rows={5}
              value={form.natureOfQuery ?? ""}
              placeholder="Nature of query..."
              onChange={(e) => setField("natureOfQuery", e.target.value)}
              style={{ minHeight: "120px" }}
            />
          </Form.Group>
        </div>

        <div className="d-flex justify-content-end row">
          <p
            className="col"
            style={{
              color: "red",
              fontSize: 22,
              marginLeft: 30,
              marginTop: 3,
              marginBottom: -5,
            }}
          >
            {errorText}
          </p>
          <button
            disabled={submitting}
            type="submit"
            className="btn btn-primary col-2"
          >
            {!submitting ? (
              "Submit"
            ) : (
              <div>
                Please wait <FontAwesomeIcon icon={faSpinner} spin />
              </div>
            )}
          </button>
        </div>
      </Form>
    );
  } else {
    return <div>Loading ...</div>;
  }
}
