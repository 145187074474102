//React
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
//Fetch
import axios from "axios";
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext";
//Icons
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Components
import MainContainer from "../../../Components/Containers/MainContainer";
import Error from "../../../Components/Errors/Error";
import AssetDetails from "../Components/AssetDetails";
import CreateEditAssetForm from "../Components/CreateEditAssetForm";

export default function ViewAsset({ updateAssets }) {
  const params = useParams();
  const navigate = useNavigate();
  const { user, dispatch } = useAuthContext();
  const [asset, setAsset] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const [edit, setEdit] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/communityasset/${params.id}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        );
        setAsset(res.data);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch({ type: "LOGOUT" });
          navigate("../../expired");
        } else {
          setError(error.response.status);
        }
      } finally {
        setLoading(false);
      }
    };

    getUser();
  }, [params.id, user, dispatch, navigate]);

  if (!loading) {
    return error ? (
      <MainContainer>
        <Error item={"Building"} code={error} />
      </MainContainer>
    ) : (
      <MainContainer classes={"d-flex flex-column  p-2"}>
        {edit ? (
          <>
            <div className="d-flex justify-content-between mb-2">
              <h2 className="my-2">Edit Building</h2>
              <button
                className="btn btn-primary my-2 "
                onClick={() => setEdit(false)}
              >
                Back <FontAwesomeIcon icon={faArrowLeft} />
              </button>
            </div>

            <div>
              <CreateEditAssetForm
                asset={asset}
                setAsset={setAsset}
                setEdit={setEdit}
                edit={edit}
                updateAssets={updateAssets}
              />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-end mb-2">
              <button
                className="btn btn-primary my-2 "
                onClick={() => navigate(-1)}
              >
                Back <FontAwesomeIcon icon={faArrowLeft} />{" "}
              </button>
            </div>
            <div className="col ">
              <div className="primaryShadow p-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <h3 className=" my-2">Building Details</h3>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => setEdit(true)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
                <hr />
                <AssetDetails asset={asset} />
              </div>
            </div>
          </>
        )}
      </MainContainer>
    );
  }
}
