//React
import React from "react"
import { useState, useEffect } from "react"
//Fetch
import axios from "axios"
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext"

export default function EnquirySearch({ handleCorrectEnquiry }) {
  const { user } = useAuthContext()
  const [enquiryList, setEnquiryList] = useState([])
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    search: "",
  })

  useEffect(() => {
    const listEnquirys = async () => {
      let pageNum = 1
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/enquiries?pageNumber=${pageNum}&search=${pagination.search}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setEnquiryList(res.data.results)
      } catch (error) {
        console.log(error)
      } finally {
      }
    }

    if (pagination.search.length > 0) {
      listEnquirys()
    } else {
      setEnquiryList([])
    }
  }, [pagination.search, user])

  return (
    <div className="mb-4">
      <h4>Search for existing enquiry</h4>
      <hr />
      {/* <p>*Additional details can be added later.</p> */}
      <input
        placeholder="Type name to search..."
        value={pagination.search}
        onChange={(e) =>
          setPagination((prev) => {
            return { ...prev, search: e.target.value }
          })
        }
        type="text"
        className="form-control mb-2"
      />
      <div>
        {enquiryList.length > 0
          ? enquiryList.map((enquiry) => (
              <div
                key={enquiry.id}
                className="listItem innerCon p-2 hoverMe mb-1"
                onClick={() => handleCorrectEnquiry(enquiry)}
              >
                <h5 className="text-truncate">{enquiry.title}</h5>
                <div>{enquiry.groupName}</div>
              </div>
            ))
          : pagination.search !== "" && (
              <div className="ps-2">No enquiries found.</div>
            )}
        {pagination.search === "" && (
          <div className="ps-1" style={{ fontSize: "14px" }}>
            Enquiries can be found by searching for a title, group name, parish
            or district.
          </div>
        )}
      </div>
    </div>
  )
}
