//React
import React from "react";
import { useState, useEffect } from "react";
//Fetch
import axios from "axios";
//Context

//Bootstrap
import { FloatingLabel } from "react-bootstrap";
import { useAuthContext } from "../../Hooks/useAuthContext";

export default function ContactSearch({ setForm, contacts }) {
  const { user } = useAuthContext();
  const [contactList, setContactList] = useState([]);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    search: "",
  });

  useEffect(() => {
    const listContacts = async () => {
      let pageNum = 1;

      let idsToSkip = contacts.map((contact) => contact.id);

      try {
        const res = await axios.get(
          `https://${
            process.env.REACT_APP_IP
          }/api/contacts?pageNumber=${pageNum}&search=${
            pagination.search
          }${idsToSkip
            .map((id, index) => `&idsToSkip[${index}]=${id}`)
            .join("")}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        );
        setContactList(res.data.results);
      } catch (error) {
        console.log(error);
      } finally {
      }
    };
    if (pagination.search.length > 0) {
      listContacts();
    } else {
      setContactList([]);
    }
  }, [pagination.search, user]);

  const handleSelectContact = (contact) => {
    setForm((prev) => {
      return {
        ...prev,
        contacts: [...prev.contacts, contact],
        contactsToRemove: prev.contactsToRemove.filter(
          (c) => c.id !== contact.id
        ),
      };
    });
    setPagination({ ...pagination, search: "" });
  };

  return (
    <div>
      {/* <p>*Additional details can be added later.</p> */}
      <FloatingLabel label="Start typing to search...">
        <input
          placeholder="Start typing to search..."
          value={pagination.search}
          onChange={(e) =>
            setPagination((prev) => {
              return { ...prev, search: e.target.value };
            })
          }
          type="text"
          className="form-control mb-2"
        />
      </FloatingLabel>
      <div>
        {contactList.length > 0
          ? contactList.map((contact) => (
              <div
                key={contact.id}
                className="listItem p-2 hoverMe innerCon mb-1"
                onClick={() => handleSelectContact(contact)}
              >
                <h5>
                  {contact.firstName} {contact.lastName}
                </h5>
                <div>{contact.contactType}</div>
              </div>
            ))
          : pagination.search !== "" && (
              <div className="ps-2">No contacts found.</div>
            )}
        {pagination.search === "" && (
          <div className="ps-1 mb-3" style={{ fontSize: "14px" }}>
            Contacts can be found by searching for a name, email, parish,
            district, county, type and address.
          </div>
        )}
      </div>
    </div>
  );
}
