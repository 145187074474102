//React
import React from "react"
//Components
import MainContainer from "../../../Components/Containers/MainContainer"
import SideContainer from "../../../Components/Containers/SideContainer"
import AdminList from "../Components/AdminList"

export default function AdminHome() {
  return (
    <div>
      <SideContainer classes={"d-xl-none"}>
        <AdminList />
      </SideContainer>
      <MainContainer
        classes={
          "d-none d-xl-flex justify-content-center align-items-center p-4"
        }
      >
        <div>Select an option from the list.</div>
      </MainContainer>
    </div>
  )
}
