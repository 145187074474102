//React
import React from "react"
import { useEffect, useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { useInView } from "react-cool-inview"

//Fetch
import axios from "axios"

//Context
import { useAuthContext } from "../../Hooks/useAuthContext"
//Icons

//Notifications
import { ToastContainer } from "react-toastify"

//Components
import SideContainer from "../../Components/Containers/SideContainer"
import EventList from "./Components/EventList"
import ViewEvent from "./Pages/ViewEvent"
import CreateEvent from "./Pages/CreateEvent"
import Loading from "../../Components/Loading/Loading"
import EventsHome from "./Pages/EventsHome"

export default function Events() {
  const { user, dispatch } = useAuthContext()
  const navigate = useNavigate()
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  //Pagination

  const [pageNumber, setPageNumber] = useState(1)
  const [count, setCount] = useState(0)
  const [showMore, setShowMore] = useState(false)
  const [pagination, setPagination] = useState({
    search: "",
    eventStart: "",
    eventEnd: "",
  })

  const listEvents = async (page, searching) => {
    let pageNum = 1
    if (page) {
      pageNum = page
    } else {
      pageNum = pageNumber
    }
    try {
      const res = await axios.get(
        `https://${process.env.REACT_APP_IP}/api/events?pageNumber=${pageNum}&search=${pagination.search}&eventStart=${pagination.eventStart}&eventEnd=${pagination.eventEnd}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )

      const count = res.data.count
      const results = res.data.results

      setCount(count)

      if (Number(count) <= 0) {
        setShowMore(false)
        setEvents([])
      } else {
        if (results.length === 0) {
          setShowMore(false)
        } else if (results.length < 15) {
          if (searching) {
            setEvents(results)
          } else {
            setEvents((prev) => {
              return [...prev, ...results]
            })
          }
          setShowMore(false)
        } else {
          if (searching) {
            setEvents(results)
          } else {
            setEvents((prev) => {
              return [...prev, ...results]
            })
          }
          setPageNumber(pageNum + 1)

          setShowMore(true)
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "LOGOUT" })
        navigate("../expired")
      } else {
        setError(error.response.status)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    listEvents(1, true)
    // eslint-disable-next-line
  }, [pagination])

  const { observe } = useInView({
    onEnter: () => {
      listEvents()
    },
  })

  const updateEvents = (updatedEvent) => {
    const updatedEvents = events.map((event) => {
      if (event.id === updatedEvent.id) {
        return updatedEvent
      }
      return event
    })

    setEvents(updatedEvents)
  }

  if (!loading) {
    return error ? (
      <div>Some error..</div>
    ) : (
      <div className="container-xxl">
        <SideContainer classes={"d-none d-xl-block"}>
          <EventList
            events={events}
            setPagination={setPagination}
            pagination={pagination}
            count={count}
          >
            {showMore && <div ref={observe} className="pb-5"></div>}
          </EventList>
        </SideContainer>

        <Routes>
          <Route
            path="/*"
            element={
              <EventsHome
                events={events}
                setPagination={setPagination}
                pagination={pagination}
                showMore={showMore}
                listEvents={listEvents}
                count={count}
              />
            }
          />
          <Route
            path="/view_event/:id"
            element={<ViewEvent updateEvents={updateEvents} />}
          />
          <Route
            path="/create_event/*"
            element={<CreateEvent setEvents={setEvents} />}
          />
        </Routes>
        <ToastContainer />
      </div>
    )
  } else {
    return <Loading />
  }
}
