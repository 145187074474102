//React
import React, { useState } from "react"
//Fetch
import axios from "axios"
//Context

//Bootstrap
import { Form } from "react-bootstrap"
//React Select
import CreatableSelect from "react-select/creatable"
import { useAuthContext } from "../../Hooks/useAuthContext"

export default function LinkedTags({
  setField,
  tags = [],
  deletedTags,
  setDeletedTags,
  tagList,
  setTagList,
  type,
}) {
  const { user } = useAuthContext()
  const [createTagLoading, setCreateTagLoading] = useState(false)

  const handleChange = (value, action) => {
    if (action && action.action === "select-option") {
      const updateDeleted = deletedTags
        .filter((tag) => tag.id === action.option.id)
        .map((tag) => tag)
      setDeletedTags(updateDeleted)
    }

    if (action && action.action === "remove-value") {
      const isExisting = deletedTags.find(
        (tag) => tag.id === action.removedValue.id
      )
      if (!isExisting) {
        setDeletedTags((prev) => {
          return [...prev, action.removedValue.id]
        })
        setField("tags", value)
      }
    }

    setField("tags", value)
  }

  const handleCreateTag = async (inputValue) => {
    setCreateTagLoading(true)

    const newTag = {
      label: inputValue,
      value: inputValue,
      type: type,
    }

    try {
      const res = await axios.post(
        `https://${process.env.REACT_APP_IP}/api/tag`,
        newTag,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )

      setTagList((prev) => {
        return [...prev, res.data]
      })
      setField("tags", [...tags, res.data])
    } catch (error) {
      console.log(error)
    } finally {
      setCreateTagLoading(false)
    }
  }

  return (
    <div className="mb-3">
      <h4 className="ps-2">Tags</h4>
      <hr />
      <div className="ps-2">
        To create a new tag simply type the name of the tag in the box below and
        click the Create "new tag" option.
      </div>
      <div className="d-flex flex-column ">
        <Form.Group className={`mb-1  p-1 col`}>
          {/* <Form.Control
                  value={tagInput}
                  onChange={(e) => setTagInput(e.target.value)}
                /> */}
          <CreatableSelect
            isMulti
            isClearable={false}
            options={tagList}
            value={[...tags]}
            isDisabled={createTagLoading}
            isLoading={createTagLoading}
            onCreateOption={(e) => handleCreateTag(e)}
            onChange={(value, action) => {
              handleChange(value, action)
            }}
            placeholder="Select or create tags..."
          />
        </Form.Group>
      </div>
    </div>
  )
}
