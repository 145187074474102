//React
import React from "react"

//Components
import ListItem from "../../../Components/ListItem"
import EnquiryList from "./EnquiryList"
import EventList from "./EventList"
import InteractionsList from "./InteractionsList"
import { Link } from "react-router-dom"
import InnerConItem from "../../../Components/InnerConItem"
import BuildingItem from "./BuildingItem"

export default function ContactDetails({ contact, setContact }) {
  return (
    <>
      <div className="mb-3 ps-2">
        <ListItem
          label={"Name"}
          value={`${contact.title ? contact.title : ""} ${contact.firstName} ${
            contact.lastName
          }`}
        />
        {!contact.isActive && (
          <strong className="d-flex justify-content-center mt-4 me-5">
            Contact has been removed
          </strong>
        )}
        {contact.isActive && (
          <div>
            {contact.asset ? (
              <div
                className={`d-flex flex-column flex-md-row py-2 px-1  listItem `}
              >
                <div className=" col-md-5 ">Group:</div>
                <div className="col-md-7">
                  {" "}
                  <Link to={`/community_assets/view_asset/${contact.asset.id}`}>
                    {contact.asset.assetName}
                  </Link>{" "}
                </div>
              </div>
            ) : (
              <ListItem label={"Group"} value={contact.groupName} />
            )}

            <ListItem label={"Job Title"} value={contact.jobTitle} />
            <ListItem label={"Contact Type"} value={contact.contactType} />
            <ListItem label={"Parish"} value={contact.parish} />
            <ListItem label={"District"} value={contact.district} />
            <ListItem
              label={"Unitary Authority"}
              value={contact.unitaryAuthority}
            />
            <ListItem
              label={"Locality/Panel"}
              value={contact.locality ? contact.locality : "Unknown"}
            />

            <div
              className={`d-flex flex-column flex-md-row py-2 px-1 listItem `}
            >
              <div className="col-md-5">Address:</div>
              <div className="d-flex flex-column">
                <span>{contact.addressLine1}</span>
                <span>{contact.addressLine2}</span>
                <span>{contact.addressLine3}</span>
                <span>{contact.county}</span>
                <span>{contact.town}</span>
                <span>{contact.postcode}</span>
              </div>
            </div>
            <ListItem
              label={"Email"}
              value={`${contact.primaryEmail ? contact.primaryEmail : ""} ${
                contact.secondaryEmail ? "," + contact.secondaryEmail : ""
              }`}
            />
            <ListItem
              label={"Phone"}
              value={`${contact.landline ? contact.landline : ""} ${
                contact.mobile ? ", " + contact.mobile : ""
              }`}
            />
          </div>
        )}
      </div>
      {contact.isActive && (
        <div>
          <h3 className="my-2">ACT Details</h3>
          <hr />
          <div className="mb-3 ps-2">
            <ListItem label={"ACT Mailings"} value={contact.actMailings} />
            <ListItem
              label={"ACT Staff"}
              value={contact.actStaff ? "Yes" : "No"}
            />
            <ListItem
                          label={"ACT Supporter"}
                          value={contact.actSupporter ? "Yes (Joined: " + (contact.dateSupporterJoined == null ? "Unknown" : new Date(contact.dateSupporterJoined).toLocaleDateString()) + ")" : "No"}
            />
            <ListItem
              label={"ACT Trustee"}
              value={contact.actTrustee ? "Yes" : "No"}
            />

            <ListItem
              label={"Supporter Check"}
              value={contact.supporterCheckApril2020 ? "Yes" : "No"}
            />
            <ListItem
              label={"Letter Sent On"}
              value={
                contact.letterSent
                  ? new Date(contact.letterSent).toLocaleDateString("en-GB")
                  : ""
              }
            />
          </div>
          <h3 className="my-2">Buildings</h3>
          <hr />
          {contact.communityBuildings.length > 0 ? (
            <div
              className={`d-flex flex-column flex-md-row flex-md-wrap gap-3 mb-3  p-2`}
            >
              {contact.communityBuildings.map((building) => (
                <BuildingItem building={building} />
              ))}
            </div>
          ) : (
            <div className="mb-3 text-center ">
              Not a contact for any Community Buildings.
            </div>
          )}
          <EnquiryList enquiries={contact.enquiries} />
          <InteractionsList
            interactions={contact.interactions}
            setContact={setContact}
          />
          <EventList events={contact.events} />
        </div>
      )}
    </>
  )
}
