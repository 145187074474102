//React
import React from "react"
import { useNavigate } from "react-router-dom"
//Icons
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Components
import MainContainer from "../../../Components/Containers/MainContainer"
import CreateEditEnquiryForm from "../Components/CreateEditEnquiryForm"


export default function CreateEnquiry({ setEnquiries, tagList, setTagList }) {
  const navigate = useNavigate()
  
  return (
    <MainContainer classes={"d-flex flex-column p-2"}>
      <div className="d-flex justify-content-between mb-2">
        <h2 className="my-2">Create Enquiry</h2>
        <button className="btn btn-primary my-2 " onClick={() => navigate(-1)}>
          Back <FontAwesomeIcon icon={faArrowLeft} />{" "}
        </button>
      </div>

      <div>
        <CreateEditEnquiryForm
          setEnquiries={setEnquiries}
          tagList={tagList}
          setTagList={setTagList}
        />
      </div>
    </MainContainer>
  )
}
