//React
import React from "react"
//Bootstrap
import { Form } from "react-bootstrap"

export default function EventFilters({ setPagination, pagination }) {
  const setField = (field, value) => {
    setPagination({
      ...pagination,
      [field]: value,
    })
  }

  const handleReset = () => {
    setPagination((prev) => {
      return {
        ...prev,
        eventStart: "",
        eventEnd: "",
      }
    })
  }

  return (
    <div className="primaryShadow p-2">
      {/* Last Interaction Date Range */}
      <Form.Group className="mb-2">
        <label>Event starting</label>
        <div className="d-flex">
          <Form.Group className="col-6 d-flex flex-column p-1">
            <Form.Label>After</Form.Label>
            <Form.Control
              type="date"
              value={pagination.eventStart ?? ""}
              onChange={(e) => setField("eventStart", e.target.value)}
            />
          </Form.Group>
          <Form.Group className="col-6 d-flex flex-column p-1">
            <Form.Label>Before</Form.Label>
            <Form.Control
              type="date"
              value={pagination.eventEnd ?? ""}
              onChange={(e) => setField("eventEnd", e.target.value)}
            />
          </Form.Group>
        </div>
      </Form.Group>
      <div className="d-flex justify-content-end">
        <button className="btn btn-danger" onClick={handleReset}>
          Reset Filters
        </button>
      </div>
    </div>
  )
}
