//Notifications
import { ToastContainer } from "react-toastify"
//Components
import LoginForm from "../Components/Forms/LoginForm"

export default function LandingPage() {
  return (
    <main
      className="justify-content-center row align-content-center px-2 px-md-0"
      style={{ height: "90vh" }}
    >
      <div className="loginForm shadow p-3 col-md-5">
        <h1 className="text-center mb-3">Welcome to ACTion CRM</h1>
        <h4 className="text-center mb-3">Please login</h4>
        <div className="d-flex justify-content-center mb-2"></div>
        <LoginForm />
      </div>
      <ToastContainer />
    </main>
  )
}
