//React
import React from "react"
import { useEffect, useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
//Fetch
import axios from "axios"
//Context
import { useAuthContext } from "../../Hooks/useAuthContext"
//Infinite Scroll
import { useInView } from "react-cool-inview"
//Notifications
import { ToastContainer } from "react-toastify"
//Components
import SideContainer from "../../Components/Containers/SideContainer"
import AssetList from "./Components/AssetList"
import ViewAsset from "./Pages/ViewAsset"
import CreateAsset from "./Pages/CreateAsset"
import Loading from "../../Components/Loading/Loading"
import AssetHome from "./Pages/AssetHome"
import Error from "../../Components/Errors/Error"

export default function CommunityAssets() {
  const { user, dispatch } = useAuthContext()
  const navigate = useNavigate()
  const [assets, setAssets] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  //Pagination
  const [pageNumber, setPageNumber] = useState(false)
  const [count, setCount] = useState(0)
  const [showMore, setShowMore] = useState(false)
  const [pagination, setPagination] = useState({
    search: "",
    district: "",
    parish: "",
    contacts: "",
    h1: "",
    h2: "",
    h3: "",
    renewalRequired: "",
    createdById: "",
    createdStart: "",
    createdEnd: "",
    hasCBDEntry: "",
    locality: "",
  })

  const listAssets = async (page, searching) => {
    let pageNum = 1
    if (page) {
      pageNum = page
    } else {
      pageNum = pageNumber
    }
    try {
      const res = await axios.get(
        `https://${process.env.REACT_APP_IP}/api/communityassets?pageNumber=${pageNum}&search=${pagination.search}&locality=${pagination.locality}&parish=${pagination.parish}&district=${pagination.district}&minCapacity=${pagination.minCapacity}&maxCapacity=${pagination.maxCapacity}&contacts=${pagination.contacts}&h1=${pagination.h1}&h2=${pagination.h2}&h3=${pagination.h3}&renewalRequired=${pagination.renewalRequired}&createdStart=${pagination.createdStart}&createdById=${pagination.createdById.value ?? ""}&createdEnd=${pagination.createdEnd}&hasCBDEntry=${pagination.hasCBDEntry}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )

      const count = res.data.count
      const results = res.data.results

      setCount(count)

      if (Number(count) <= 0) {
        setShowMore(false)
        setAssets([])
      } else {
        if (results.length === 0) {
          setShowMore(false)
        } else if (results.length < 15) {
          if (searching) {
            setAssets(results)
          } else {
            setAssets((prev) => {
              return [...prev, ...results]
            })
          }
          setShowMore(false)
        } else {
          if (searching) {
            setAssets(results)
          } else {
            setAssets((prev) => {
              return [...prev, ...results]
            })
          }
          setPageNumber(pageNum + 1)

          setShowMore(true)
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "LOGOUT" })
        navigate("../expired")
      } else {
        setError(error.response.status)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    listAssets(1, true)
    // eslint-disable-next-line
  }, [pagination])

  const { observe } = useInView({
    onEnter: () => {
      listAssets()
    },
  })

  const updateAssets = (updatedAsset) => {
    const updatedAssets = assets.map((asset) => {
      if (asset.id === updatedAsset.id) {
        return updatedAsset
      }
      return asset
    })

    setAssets(updatedAssets)
  }

  if (!loading) {
    return error ? (
      <Error code={error} />
    ) : (
      <div className="container-xxl">
        <SideContainer classes={"d-none d-xl-block"}>
          <AssetList
            assets={assets}
            setPagination={setPagination}
            pagination={pagination}
            count={count}
          >
            {showMore && <div ref={observe} className="pb-5"></div>}
          </AssetList>
        </SideContainer>

        <Routes>
          <Route
            path="/*"
            element={
              <AssetHome
                assets={assets}
                setPagination={setPagination}
                pagination={pagination}
                showMore={showMore}
                listAssets={listAssets}
                count={count}
              />
            }
          />
          <Route
            path="/view_asset/:id"
            element={<ViewAsset updateAssets={updateAssets} />}
          />
          <Route
            path="/create_asset/*"
            element={<CreateAsset setAssets={setAssets} />}
          />
        </Routes>
        <ToastContainer />
      </div>
    )
  } else {
    return <Loading />
  }
}
