//React
import React from "react"
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "../../../Hooks/useAuthContext"
//Icons
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Styles
import "./UsersList.css"

export default function UsersList({
  users,
  setPagination,
  pagination,
  children,
}) {
  const { user } = useAuthContext()
  const navigate = useNavigate()

  return (
    <div className=" p-2">
      <div className="my-2 d-flex justify-content-between align-items-center">
        <h3 className="text-center">Users</h3>
        <div>
          {user.isAdmin ? (
            <button
              className="btn btn-primary"
              onClick={() => navigate("./create_user")}
            >
              <FontAwesomeIcon icon={faAdd} />
            </button>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="col mb-3">
        <input
          placeholder="Type name to search..."
          value={pagination.search}
          onChange={(e) =>
            setPagination((prev) => {
              return { ...prev, search: e.target.value }
            })
          }
          type="text"
          className="form-control mb-2"
        />
      </div>

      {users.map((user) => (
        <div
          key={user.id}
          className="primaryShadow mb-2 p-2 hoverMe sidebarItem"
          onClick={() => navigate(`./view_user/${user.id}`)}
        >
          <h5 className="mb-2">
            {user.firstName} {user.lastName}
          </h5>
          <div>{user.email}</div>
        </div>
      ))}
      {children}
    </div>
  )
}
