//React
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

//Fetch
import axios from "axios"

//Context
import { useAuthContext } from "../../../Hooks/useAuthContext"

//Calendar
import FullCalendar from "@fullcalendar/react" // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid" // a plugin!

//Styles
import "./EventCalendar.css"

export default function EventCalendar() {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const [events, setEvents] = useState([])

  const [dates, setDates] = useState({})

  useEffect(() => {
    const controller = new AbortController()

    const listEvents = async () => {
      try {
        const res = await axios.get(
          `https://${
            process.env.REACT_APP_IP
          }/api/events/calendar?startDate=${new Date(
            dates.startStr
          ).toISOString()}&endDate=${new Date(dates.endStr).toISOString()}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
            signal: controller.signal,
          }
        )

        setEvents(res.data)
      } catch (error) {
        console.log(error)
        // setError(true)
      }
    }

    if (Object.keys(dates).length > 0) {
      listEvents()
      // console.log("GETTING DATES")
    }

    return () => {
      controller.abort()
    }
  }, [dates, user])

  return (
    <div style={{ height: "93vh" }} className="p-4">
      <FullCalendar
        dayHeaderClassNames={["myHeader"]}
        dayCellClassNames={["myDay"]}
        buttonText={{ today: "Today" }}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        height={"100%"}
        events={events}
        eventDisplay="block"
        displayEventTime={false}
        eventClassNames={["myEvent"]}
        eventClick={(ev) => {
          navigate(`./view_event/${ev.event._def.publicId}`)
        }}
        datesSet={(args) => setDates(args)}
      />
    </div>
  )
}
