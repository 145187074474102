import { Form } from "react-bootstrap"

export default function FloatingInitialSource({ setField, classes, form }) {
  const initialSources = [
    "ACT Website",
    "CVHN",
    "CVHN Facebook",
    "Email",
    "Event",
    "Meeting",
    "Meeting/Workshop",
    "Other",
    "Phone",
    "Post",
    "Telephone Call",
    "Twitter",
  ]

  return (
    <Form.Group className={`mb-1 p-1 ${classes}`}>
      <div className="form-floating">
        <Form.Select
          value={form.initialSource ?? ""}
          onChange={(e) => setField("initialSource", e.target.value)}
        >
          <option value="">Select source...</option>
          {initialSources.map((source, index) => (
            <option key={index} value={source}>
              {source}
            </option>
          ))}
        </Form.Select>
        <label>
          Initial Source <span className="text-danger">*</span>
        </label>
      </div>
    </Form.Group>
  )
}
