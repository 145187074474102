//React
import React from "react";
import { useState, useEffect } from "react";
//Fetch
import axios from "axios";
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext";
//Icons
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Notifications
import { toast } from "react-toastify";
//Bootstrap
import { FloatingLabel, Form } from "react-bootstrap";
//Components
import FormControl from "../../../Components/Forms/FormControl";


export default function CreateEditTimesheetCodeForm({
  timesheetCode,
  setTimesheetCodes,
  timesheetCodes,
  setShowForm,
  setSelectedTimesheetCode,
  type
}) {
  const { user } = useAuthContext();
  const [form, setForm] = useState({type: type});
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(timesheetCode).length > 0) {
      setForm({
        name: timesheetCode.name,
        code: timesheetCode.code,
        description: timesheetCode.description,
        type: timesheetCode.type
      });
    }
  }, [timesheetCode]);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const handleSubmit = async (e) => {
    setSubmitting(true);
    e.preventDefault();
    if (Object.keys(timesheetCode).length > 0) {
      //Edit
      try {
        const res = await axios.patch(
          `https://${process.env.REACT_APP_IP}/api/timesheetcodes/${timesheetCode.id}`,
          form,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        );
        toast.success("Timesheet code updated.");
        setShowForm(false);

        const updatedTimesheetCodes = timesheetCodes.map((timesheetcode) => {
          if (timesheetcode.id === res.data.id) {
            return res.data;
          }
          return timesheetcode;
        });

        setTimesheetCodes(updatedTimesheetCodes);

        setSelectedTimesheetCode({});
      } catch (err) {
        console.log(err.response);
      } finally {
        setSubmitting(false);
      }
    } else {
      //create
      try {
        const res = await axios.post(
          `https://${process.env.REACT_APP_IP}/api/timesheetcodes`,
          form,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        );
        toast.success("Timesheet code created.");
        setForm({});
        setShowForm(false);
        setTimesheetCodes((prev) => {
          return [...prev, res.data];
        });
      } catch (err) {
        console.log(err.response);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="mb-3">
        <h4 className="ps-1">
          {Object.keys(timesheetCode).length > 0 ? "Edit" : "Create"} Timesheet
          Code.
        </h4>


        <div className="d-flex">
            <Form.Group className="mb-1 p-1 col-12">
                <FloatingLabel label={"Type"}>
                    <Form.Select value={form.type} onChange={(e) => setField("type", e.target.value)}>
                        <option disabled value="">
                            Select...
                        </option>
                              <option value="TimesheetCode" selected>Timesheet Code</option>
                              <option value="CostCode">Cost Code</option>
                              <option value="NominalCode">Nominal Code</option>
                              <option value="DepartmentCode">Department Code</option>
                    </Form.Select>
                </FloatingLabel>
            </Form.Group>
        </div>

        <div className="d-flex">

            <FormControl
                value={form.code}
                label={"Code"}
                field={"code"}
                setField={setField}
                classes={"col-3"}
                required
            />

          <FormControl
            value={form.name}
            label={"Name"}
            field={"name"}
            setField={setField}
            classes={"col-9"}
            required
          />

          
        </div>

        <div className="d-flex">
          <Form.Group className={`mb-1 p-1 col-12`}>
            <Form.Control
              as="textarea"
              rows={5}
              value={form.description ?? ""}
              placeholder="Description..."
              onChange={(e) => setField("description", e.target.value)}
              style={{ minHeight: "120px" }}
            />
          </Form.Group>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button type="submit" className="btn btn-primary" disabled={submitting}>
          {!submitting ? (
            "Submit"
          ) : (
            <div>
              Please wait <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          )}
        </button>
      </div>
    </Form>
  );
}
