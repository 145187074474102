//React
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { useAuthContext } from "../../../Hooks/useAuthContext"

export default function InteractionsList({ interactions, setContact }) {
  const { user, dispatch } = useAuthContext()
  const navigate = useNavigate()
  const sortedInteractions = interactions.sort(function (a, b) {
    if (a.dateAndTime < b.dateAndTime) {
      return -1
    }
    if (a.dateAndTime > b.dateAndTime) {
      return 1
    }
    return 0
  })

  const handleDeleteInteraction = async (id) => {
    try {
      const res = await axios.delete(
        `https://${process.env.REACT_APP_IP}/api/interaction/${id}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      console.log(res.data)
      setContact((prev) => {
        return {
          ...prev,
          interactions: prev.interactions.filter(
            (interaction) => interaction.id !== id
          ),
        }
      })
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "LOGOUT" })
        navigate("../../expired")
      } else {
        // setError(error.response.status)
      }
    }
  }
  return (
    <div className="mb-3">
      <h3 className=" mb-3">Interactions</h3>
      <hr />
      <div>
        {sortedInteractions && sortedInteractions.length > 0 ? (
          sortedInteractions.map((interaction) => (
            <div className="ps-2 mb-2 innerCon  p-2" key={interaction.id}>
              <h5 className="text-decoration-underline">
                <Link
                  to={`/users/view_user/${interaction.handledBy.id}`}
                >{`${interaction.handledBy.firstName} ${interaction.handledBy.lastName}`}</Link>{" "}
                {`(${new Date(interaction.dateAndTime).getDate()}/${
                  new Date(interaction.dateAndTime).getMonth() + 1
                }/${new Date(interaction.dateAndTime).getFullYear()}) - ${
                  interaction.type
                }`}
              </h5>

              {interaction.callDuration && (
                <div className={`d-flex flex-column flex-md-row py-2 px-1  `}>
                  <div>Call Duration:</div>
                  <div className="ms-2">{interaction.callDuration} hrs</div>
                </div>
              )}
              <div className={`d-flex flex-column flex-md-row py-2 px-1`}>
                <div>{interaction.description}</div>
              </div>
              <div
                className={`d-flex flex-column flex-md-row py-2 px-1  listItem`}
              >
                <div className=" col-md-5 ">From Enquiry:</div>
                <div>
                  <Link
                    to={`/enquiries/view_enquiry/${interaction.enquiry.id}`}
                  >
                    {interaction.enquiry.title
                      ? interaction.enquiry.title
                      : "Un-Named Enquiry"}
                  </Link>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                {" "}
                <button
                  className="btn btn-danger"
                  onClick={() => handleDeleteInteraction(interaction.id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className=" text-center">No interactions found.</div>
        )}
      </div>
    </div>
  )
}
