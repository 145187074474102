//React
import React from "react";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
//Fetch
import axios from "axios";
//Context
import { useAuthContext } from "../../Hooks/useAuthContext";
//Infinite Scroll
import { useInView } from "react-cool-inview";
//Notifications
import { ToastContainer } from "react-toastify";
//Components
import SideContainer from "../../Components/Containers/SideContainer";
import ContactList from "./Components/ContactList";
import Home from "./Pages/Home";
import ViewContact from "./Pages/ViewContact";
import CreateContact from "./Pages/CreateContact";
import Loading from "../../Components/Loading/Loading";
import Error from "../../Components/Errors/Error";

export default function Contacts() {
  const { user, dispatch } = useAuthContext();
  const navigate = useNavigate();
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(0);
  const [tagList, setTagList] = useState([]);

  //Pagination
  const [showMore, setShowMore] = useState(false);
  const [pagination, setPagination] = useState({
    search: "",
    district: "",
    parish: "",
    contactType: "",
    createdStart: "",
    createdEnd: "",
    lastInteractionStart: "",
    lastInteractionEnd: "",
    tags: [],
    excludeAttendees: false,
    createdById: "",
    locality: "",
    actSupporter: "",
  });

  const listContacts = async (page, searching) => {
    let pageNum = 1;
    if (page) {
      pageNum = page;
    } else {
      pageNum = pageNumber;
    }

    //check the pagination.search and remove any commas if present
    const searchStr = pagination.search.replace(/,/g, "");

    try {
      const res = await axios.get(
        `https://${process.env.REACT_APP_IP}/api/contacts?pageNumber=${pageNum}&search=${searchStr}&isCsv=false&excludeAttendees=${pagination.excludeAttendees}&locality=${pagination.locality}&actSupporter=${pagination.actSupporter}&parish=${pagination.parish}&district=${pagination.district}&contactType=${pagination.contactType}&createdStart=${pagination.createdStart}&createdById=${pagination.createdById}&createdEnd=${pagination.createdEnd}&lastInteractionStart=${pagination.lastInteractionStart}&lastInteractionEnd=${pagination.lastInteractionEnd}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      );

      const count = res.data.count;
      const results = res.data.results;

      setCount(count);

      if (Number(count) <= 0) {
        setShowMore(false);
        setContacts([]);
      } else {
        if (results.length === 0) {
          setShowMore(false);
        } else if (results.length < 15) {
          if (searching) {
            setContacts(results);
          } else {
            setContacts((prev) => {
              return [...prev, ...results];
            });
          }
          setShowMore(false);
        } else {
          if (searching) {
            setContacts(results);
          } else {
            setContacts((prev) => {
              return [...prev, ...results];
            });
          }
          setPageNumber(pageNum + 1);

          setShowMore(true);
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "LOGOUT" });
        navigate("../expired");
      } else {
        setError(error.response.status);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listContacts(1, true);
    // eslint-disable-next-line
  }, [pagination]);

  const { observe } = useInView({
    onEnter: () => {
      listContacts();
    },
  });

  const updateContacts = (updatedContact, task = "update") => {
    let updatedContacts = [];

    switch (task) {
      case "update":
        updatedContacts = contacts.map((contact) => {
          if (contact.id === updatedContact.id) {
            return updatedContact;
          }
          return contact;
        });
        break;
      case "reactivate":
        updatedContacts = [...contacts, updatedContact];
        break;
      case "delete":
        updatedContacts = contacts.filter((contact) => {
          return contact.id !== updatedContact.id;
        });
        break;
      default:
        updatedContacts = contacts.map((contact) => {
          if (contact.id === updatedContact.id) {
            return updatedContact;
          }
          return contact;
        });
        break;
    }

    setContacts(updatedContacts);
  };

  if (!loading) {
    return error ? (
      <Error code={error} />
    ) : (
      <div className="container-xxl">
        <SideContainer classes={"d-none d-xl-block"}>
          <ContactList
            contacts={contacts}
            setPagination={setPagination}
            pagination={pagination}
            count={count}
            tagList={tagList}
          >
            {showMore && <div ref={observe} className="pb-5"></div>}
          </ContactList>
        </SideContainer>

        <Routes>
          <Route
            path="/*"
            element={
              <Home
                contacts={contacts}
                setPagination={setPagination}
                pagination={pagination}
                showMore={showMore}
                listContacts={listContacts}
                count={count}
                tagList={tagList}
              />
            }
          />
          <Route
            path="/view_contact/:id"
            element={
              <ViewContact
                updateContacts={updateContacts}
                tagList={tagList}
                setTagList={setTagList}
              />
            }
          />
          <Route
            path="/create_contact/*"
            element={
              <CreateContact
                setContacts={setContacts}
                tagList={tagList}
                setTagList={setTagList}
              />
            }
          />
        </Routes>
        <ToastContainer />
      </div>
    );
  } else {
    return <Loading />;
  }
}
