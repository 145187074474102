//react
import React from "react"
import { useNavigate } from "react-router-dom"
//Icons
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Components
import MainContainer from "../../../Components/Containers/MainContainer"
import CreateEditContactForm from "../Components/CreateEditContactForm"

export default function CreateContact({ setContacts, tagList, setTagList }) {
  const navigate = useNavigate()
  return (
    <MainContainer classes={"d-flex flex-column  p-2"}>
      <div className="d-flex justify-content-between mb-2">
        <h2 className="my-2">Create Contact</h2>
        <button className="btn btn-primary my-2 " onClick={() => navigate(-1)}>
          Back <FontAwesomeIcon icon={faArrowLeft} />{" "}
        </button>
      </div>

      <div>
        <CreateEditContactForm
          setContacts={setContacts}
          tagList={tagList}
          setTagList={setTagList}
        />
      </div>
    </MainContainer>
  )
}
