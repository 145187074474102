//React
import React from "react"
import { useNavigate } from "react-router-dom"
//Icons
import { faCalendar, faHouse, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//k = key
export default function ContactItem({ contact }) {
  const navigate = useNavigate()
  console.log(contact)
  return (
    <div
      key={contact.id}
      className="hoverMe innerCon p-2 d-flex flex-md-column align-items-center justify-content-between"
      onClick={() => navigate(`/contacts/view_contact/${contact.id}`)}
    >
      <div className="mb-md-2"></div>
      <div className="d-flex flex-column justify-content-center align-items-center mb-md-2">
        <h5 className="d-flex">
          {contact.firstName} {contact.lastName}
        </h5>
        <div className="d-flex">{contact.assetType}</div>
        <div className="d-flex">{contact.primaryEmail}</div>
        <div className="d-flex">{contact.mobile}</div>
      </div>

      <div>
        <button className="btn btn-primary">View</button>
      </div>
    </div>
  )
}
