//React
import React from "react"
import { useNavigate } from "react-router-dom"
//Icons
import { faCalendar, faHouse, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//k = key
export default function InnerConItem({ k, value, url, type }) {
  const navigate = useNavigate()
  return (
    <div
      key={k}
      className="hoverMe innerCon p-2 d-flex flex-md-column align-items-center justify-content-between"
      onClick={() => navigate(`${url}`)}
    >
      <div className="mb-md-2">
        {type === "venue" && <FontAwesomeIcon icon={faHouse} size="lg" />}
        {type === "person" && <FontAwesomeIcon icon={faUser} size="lg" />}
        {type === "event" && <FontAwesomeIcon icon={faCalendar} size="lg" />}
      </div>
      <div className="d-flex justify-content-center align-items-center mb-md-2">
        <div className="d-flex">{value}</div>
      </div>

      <div>
        <button className="btn btn-primary">View</button>
      </div>
    </div>
  )
}
