//React
import React from "react"
import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
//Fetch
import axios from "axios"
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext"
//Icons
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Components
import MainContainer from "../../../Components/Containers/MainContainer"
import ConfirmDialog from "../../../Components/ConfirmDialog"
import EnquiryDetails from "../Components/EnquiryDetails"
import CreateEditEnquiryForm from "../Components/CreateEditEnquiryForm"
import Error from "../../../Components/Errors/Error"

export default function ViewEnquiry({ updateEnquiries, tagList, setTagList }) {
  const params = useParams()
  const navigate = useNavigate()
  const { user, dispatch } = useAuthContext()
  const [enquiry, setEnquiry] = useState({})
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  const [edit, setEdit] = useState(false)

  useEffect(() => {
    const getUser = async () => {
        try {
            
            const res = await axios.get(
              `https://${process.env.REACT_APP_IP}/api/enquiry/${params.id}`,
              {
                headers: { Authorization: `bearer ${user.token}` },
              }
            )
            setError(false)
            console.log("get completed")
            console.log(res.data)
            setEnquiry(res.data)
      } catch (error) {
        if (error.response.status === 401) {
          dispatch({ type: "LOGOUT" })
          navigate("../../expired")
        } else {
          setError(error.response.status)
        }
      } finally {
        setLoading(false)
      }
    }

    getUser()
  }, [params.id, dispatch, navigate, user])

    const handleDelete = async (e) => {
          setConfirmDelete(true);
    }
    const handleConfirm = async (e) => {
      try {

        const res = await axios.delete(
            `https://${process.env.REACT_APP_IP}/api/enquiry/${params.id}`,
            {
                headers: { Authorization: `bearer ${user.token}` },
            }
        )
        setConfirmDelete(false);
        navigate(0) 

      } catch (error) {
          if (error.response.status === 401) {
              dispatch({ type: "LOGOUT" })
              navigate("../../expired")
          } else {
              setError(error.response.status)
          }
      } finally {
          setLoading(false)
      }
  }



  if (!loading) {
    return error ? (
      <MainContainer>
        <Error item={"Enquiry"} code={error} />
      </MainContainer>
    ) : (
      
      <MainContainer classes={"d-flex flex-column p-2"}>

        <ConfirmDialog 
          isVisible={confirmDelete} 
          question="Are you sure you want to delete this enquiry?"
          handleConfirm={handleConfirm}
          handleCancel={function(){setConfirmDelete(false)}}
        ></ConfirmDialog>

        {edit ? (
          <>
            <div className="d-flex justify-content-between mb-2">
              <h2 className="my-2">Edit Enquiry</h2>
              <button
                className="btn btn-primary my-2 "
                onClick={() => setEdit(false)}
              >
                Back <FontAwesomeIcon icon={faArrowLeft} />
              </button>
            </div>

            <div>
              <CreateEditEnquiryForm
                enquiry={enquiry}
                setEnquiry={setEnquiry}
                setEdit={setEdit}
                updateEnquiries={updateEnquiries}
                tagList={tagList}
                setTagList={setTagList}
              />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-end mb-2">
              <button
                className="btn btn-primary my-2 "
                onClick={() => navigate(-1)}
              >
                Back <FontAwesomeIcon icon={faArrowLeft} />{" "}
                </button>
                
            </div>
            <div className="col">
              <div className="primaryShadow p-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <h3 className=" my-2">Enquiry Details</h3>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => setEdit(true)}
                      style={{ marginRight: 20 }}
                    >
                      Edit
                    </button>
                                        
                    <button
                        className="btn btn-danger my-2 "                         
                        onClick={() => handleDelete()}
                    >
                        Delete <FontAwesomeIcon icon={faTrash} />{" "}
                        </button>
                    </div>
                </div>
                <hr />
                <EnquiryDetails enquiry={enquiry} setEnquiry={setEnquiry} />
              </div>
            </div>
          </>
        )}
      </MainContainer>
    )
  }
}
