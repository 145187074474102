//React
import React from "react";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
//Infinite Scroll
import { useInView } from "react-cool-inview";
//Fetch
import axios from "axios";
//Context
import { useAuthContext } from "../../Hooks/useAuthContext";
//Notifications
import { ToastContainer } from "react-toastify";
//Components
import SideContainer from "../../Components/Containers/SideContainer";
import EnquiryHome from "./Pages/EnquiryHome";
import EnquiryList from "./Components/EnquiryList";
import ViewEnquiry from "./Pages/ViewEnquiry";
import CreateEnquiry from "./Pages/CreateEnquiry";
import Loading from "../../Components/Loading/Loading";
import Error from "../../Components/Errors/Error";

export default function Contacts() {
  const { user, dispatch } = useAuthContext();
  const navigate = useNavigate();
  const [enquiries, setEnquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(0);
  const [tagList, setTagList] = useState([]);

  //Pagination
  const [showMore, setShowMore] = useState(false);
  const [pagination, setPagination] = useState({
    search: "",
    district: "",
    parish: "",
    groupType: "",
    recievedStart: "",
    recievedEnd: "",
    state: "Open",
    tags: [],
    queriedBy: "",
    leadForFuture: "",
    timesheetCodeId: "",
  });

  useEffect(() => {
    const getTags = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/tags?tagType=ENQUIRY`,

          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        );

        setTagList(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getTags();
  }, [user]);

  const listEnquiries = async (page, searching) => {
    let pageNum = 1;
    if (page) {
      pageNum = page;
    } else {
      pageNum = pageNumber;
    }

    try {
      const res = await axios.get(
        `https://${process.env.REACT_APP_IP}/api/enquiries?pageNumber=${pageNum}&search=${pagination.search}&queriedBy=${pagination.queriedBy.value ?? ""}&timesheetCodeId=${pagination.timesheetCodeId.value ?? ""}&parish=${pagination.parish}&district=${pagination.district}&groupType=${pagination.groupType}&recievedStart=${pagination.recievedStart}&recievedEnd=${pagination.recievedEnd}&state=${pagination.state}&leadForFuture=${pagination.leadForFuture.value ?? ""}${pagination.tags.map((tag, index) => `&tags[${index}]=${tag.value}`).join("")}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      );

      const count = res.data.count;
      const results = res.data.results;

      setCount(count);

      if (Number(count) <= 0) {
        setShowMore(false);
        setEnquiries([]);
      } else {
        if (results.length === 0) {
          setShowMore(false);
        } else if (results.length < 15) {
          if (searching) {
            setEnquiries(results);
          } else {
            setEnquiries((prev) => {
              return [...prev, ...results];
            });
          }
          setShowMore(false);
        } else {
          if (searching) {
            setEnquiries(results);
          } else {
            setEnquiries((prev) => {
              return [...prev, ...results];
            });
          }
          setPageNumber(pageNum + 1);

          setShowMore(true);
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "LOGOUT" });
        navigate("../expired");
      } else {
        setError(error.response.status);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listEnquiries(1, true);
    // eslint-disable-next-line
  }, [pagination]);

  const { observe } = useInView({
    onEnter: () => {
      listEnquiries();
    },
  });

  const updateEnquiries = (updatedContact) => {
    const updatedContacts = enquiries.map((contact) => {
      if (contact.id === updatedContact.id) {
        return updatedContact;
      }
      return contact;
    });

    setEnquiries(updatedContacts);
  };

  if (!loading) {
    return error ? (
      <Error code={error} />
    ) : (
      <div className="container-xxl">
        <SideContainer classes={"d-none d-xl-block"}>
          <EnquiryList
            enquiries={enquiries}
            setPagination={setPagination}
            pagination={pagination}
            count={count}
            tagList={tagList}
          >
            {showMore && <div ref={observe} className="pb-5"></div>}
          </EnquiryList>
        </SideContainer>

        <Routes>
          <Route
            path="/*"
            element={
              <EnquiryHome
                enquiries={enquiries}
                setPagination={setPagination}
                pagination={pagination}
                listEnquiries={listEnquiries}
                showMore={showMore}
                count={count}
                tagList={tagList}
              />
            }
          />
          <Route
            path="/view_enquiry/:id"
            element={
              <ViewEnquiry
                updateEnquiries={updateEnquiries}
                tagList={tagList}
                setTagList={setTagList}
              />
            }
          />
          <Route
            path="/create_enquiry/*"
            element={
              <CreateEnquiry
                setEnquiries={setEnquiries}
                tagList={tagList}
                setTagList={setTagList}
              />
            }
          />
        </Routes>
        <ToastContainer />
      </div>
    );
  } else {
    return <Loading />;
  }
}
