//React
import React from "react"
import { Link } from "react-router-dom"

//Components
import ListItem from "../../../Components/ListItem"

export default function EventDetails({ event }) {
  return (
    <>
      <div className="ps-2 ">
        <ListItem label={"Description"} value={event.description} />
        <ListItem
          label={"Starts"}
          value={new Date(event.start).toUTCString()}
        />
        <ListItem label={"Ends"} value={new Date(event.end).toUTCString()} />
        <ListItem
          label={"Tickets Sold"}
          value={`${event.attendees.length}/${event.ticketQuantity}`}
        />
        <ListItem
          label={"Created"}
          value={new Date(event.start).toLocaleDateString("en-GB")}
        />
        <ListItem
          label={"Last Updated"}
          value={new Date(event.start).toLocaleDateString("en-GB")}
        />
        <div className={`d-flex flex-column flex-md-row py-2 px-1  listItem`}>
          <div className=" col-md-5 ">View Event:</div>
          <div>
            <a href={event.url} target="_blank" rel="noreferrer">
              {event.url}
            </a>
          </div>
        </div>
        <div className={`d-flex flex-column flex-md-row py-2 px-1  listItem`}>
          <div className=" col-md-5 ">Created By:</div>
          <div>
            <Link
              to={`/users/view_user/${event.createdBy.id}`}
            >{`${event.createdBy.firstName} ${event.createdBy.lastName}`}</Link>
          </div>
        </div>
        <ListItem label={"Location"} value={event.location} />
      </div>
    </>
  )
}
