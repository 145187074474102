//React
import { React, useState, useEffect } from "react"

import { useNavigate } from "react-router-dom"
//Components

import { FloatingLabel, Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"

// Styles
import "./ExpensesTable.css"




export default function ExpensesTable({entries, setEntries, nominalCodes, projectCodes, departmentCodes, costCodes, expectedTotal }) {

    const [totalExpenses, setTotalExpenses] = useState(0);


    const handleChange = (index, event) => {
        const values = [...entries];
        values[index][event.target.name] = event.target.value;
        setEntries(values);

        // Check if it's the last row and any field is filled
        if (index === entries.length - 1 && (event.target.value !== '')) {
            setEntries([...entries, { nominalCode: '', projectCode: '', departmentCode: '', costCode: '', pounds: 0, pence: 0 }]);
        }
    };

    const handleRemoveEntry = (index) => {
        const values = [...entries];
        values.splice(index, 1);
        setEntries(values);
    };

    function pad(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }

    useEffect(() => {
        var countExpenses = 0;
        entries.forEach((entry) => {
            countExpenses += parseFloat(entry.pounds + "." + pad(entry.pence,2));
        });

        setTotalExpenses(countExpenses);

    }, [entries])

    return (
        <div style={{ marginBottom: 10 }}>
            <div className="row">
                <div className="col-2"><p>Nominal</p></div>
                <div className="col-2"><p>Dept No</p></div>
                <div className="col-2"><p>Project</p></div>
                <div className="col-2"><p>Costs Code</p></div>
                <div className="col-1 text-end"><p>£</p></div>
                <div className="col-1 text-end"><p>P</p></div>
                <div className="col-2 text-end"></div>
            </div>

            {entries.map((entry, index) => (
                <div key={index} className="entry row">
                    <div className="col-2">
                        <select
                            value={entry.nominalCode}
                            name="nominalCode"
                            onChange={(event) => handleChange(index, event)}
                            className="form-control">
                            <option value="">Select code</option>

                            {nominalCodes.map((code) => (
                                <option key={code.code} value={code.code}>
                                    {code.code} - {code.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-2">
                        <select
                            value={entry.departmentCode}
                            name="departmentCode"
                            onChange={(event) => handleChange(index, event)}
                            className="form-control">
                            <option value="">Select code</option>

                            {departmentCodes.map((code) => (
                                <option key={code.code} value={code.code}>
                                    {code.code} - {code.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-2">
                        <select
                            value={entry.projectCode}
                            name="projectCode"
                            onChange={(event) => handleChange(index, event)}
                            className="form-control">
                            <option value="">Select code</option>

                            {projectCodes.map((code) => (
                                <option key={code.code} value={code.code}>
                                    {code.code} - {code.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-2">
                        <select
                            value={entry.costCode}
                            name="costCode"
                            onChange={(event) => handleChange(index, event)}
                            className="form-control">
                            <option value="">Select code</option>

                            {costCodes.map((code) => (
                                <option key={code.code} value={code.code}>
                                    {code.code} - {code.name}
                                </option>
                            ))}
                        </select>
                    </div>


                    <div className="col-1">
                        <input
                            type="number"
                            name="pounds"
                            placeholder="£"
                            value={entry.pounds}
                            onChange={(event) => handleChange(index, event)}
                            className="form-control"
                        />
                    </div>
                    <div className="col-1">
                        <input
                            type="number"
                            name="pence"
                            placeholder="P"
                            value={entry.pence}
                            onChange={(event) => handleChange(index, event)}
                            className="form-control"
                        />
                    </div>
                    <div className="col-1">
                        <button type="button" className="form-control" onClick={() => handleRemoveEntry(index)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                </div>
            ))}

            <div className="row">
                <div className="col-6 text-end"><p>Total</p></div>
                <div className="col-2 text-end"><p>{totalExpenses.toFixed(2).split('.')[0]}</p></div>
                <div className="col-2 text-end"><p>{totalExpenses.toFixed(2).split('.')[1]}</p></div>
            </div>
            <div className="row">
                <div className={totalExpenses == expectedTotal ? 'match col-6 text-end' : 'mismatch col-6 text-end' }><p>Expected</p></div>
                <div className={totalExpenses == expectedTotal ? 'match col-2 text-end' : 'mismatch col-2 text-end'}><p>{expectedTotal.split('.')[0]}</p></div>
                <div className={totalExpenses == expectedTotal ? 'match col-2 text-end' : 'mismatch col-2 text-end'}><p>{expectedTotal.split('.')[1]}</p></div>
            </div>
        </div>
    )
}
