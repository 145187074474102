//React
import { React, useState, useEffect } from "react"

import { useNavigate } from "react-router-dom"
//Components

import { FloatingLabel, Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"

// Styles
import  "./Timesheet.css"




export default function TimesheetDay({ day, entries, setEntries, timesheetCodes, date }) {
    
    const [totalHours, setTotalHours] = useState(0);
    const [totalMiles, setTotalMiles] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState(0);
    

    const handleChange = (index, event) => {
        const values = [...entries];
        values[index][event.target.name] = event.target.value;
        setEntries(values);

        // Check if it's the last row and any field is filled
        if (index === entries.length - 1 && (event.target.value !== '')) {
            setEntries([...entries, { date: date.toISOString().split('T')[0], timesheetCode: '', task: '', hours: 0, miles: 0, expenses: 0 }]);
        }
    };

    const handleRemoveEntry = (index) => {
        const values = [...entries];
        values.splice(index, 1);
        setEntries(values);
    };

    useEffect(() => {

        var countHours = 0;
        var countMiles = 0;
        var countExpenses = 0;
        entries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        
        setTotalHours(countHours);
        setTotalMiles(countMiles);
        setTotalExpenses(countExpenses);

    }, [entries])

    return (
        <div style={{ marginBottom: 10} }>
            <p style={{ border: "1px solid #ccc", background: "#eee" }}>{day} ({date.toLocaleDateString("en-GB")})</p>

            <div className="row">
                <div className="col-6"><p>Task / Activity</p></div>
                <div className="col-2"><p>Timesheet Code</p></div>
                <div className="col-1"><p>Time (Hours)</p></div>
                <div className="col-1"><p>Travel (miles)</p></div>
                <div className="col-1"><p>Expenses (£)</p></div>
                <div className="col-1"></div>
            </div>


                {entries.map((entry, index) => (
                    <div key={index} className="entry row">
                        <div className="col-6">
                            {entry.interactionId ? (<p>{entry.enquiryId ? <a href={"/enquiries/view_enquiry/" + entry.enquiryId}>{entry.task}</a> : <p>{entry.task}</p>}</p>) : (
                            <input
                                type="text"
                                name="task"
                                placeholder="Task / Activity"
                                value={entry.task}
                                onChange={(event) => handleChange(index, event)}
                                className="form-control"
                                />)}
                        </div>
                        <div className="col-2">
                            {entry.interactionId ? (<p>{entry.timesheetCode}</p>) : (
                                <select
                                    value={entry.timesheetCode}
                                    name="timesheetCode"
                                    onChange={(event) => handleChange(index, event)}
                                    className="form-control">
                                    <option value="">Select code</option>

                                    {timesheetCodes.map((code) => (
                                        <option key={code.code} value={code.code}>
                                            {code.code} - {code.name}
                                        </option>
                                    ))}
                                </select>)}


                        </div>
                        <div className="col-1">
                            {entry.interactionId ? (<p>{entry.hours}</p>) : (
                                <input
                                    type="number"
                                    name="hours"
                                    placeholder="Hours"
                                    value={entry.hours}
                                    onChange={(event) => handleChange(index, event)}
                                    className="form-control"
                                />)}
                        </div>
                        <div className="col-1">
                            <input
                                type="number"
                                name="miles"
                                placeholder="Travel (mi)"
                                value={entry.miles}
                                onChange={(event) => handleChange(index, event)}
                                className="form-control"
                                />
                        </div>
                        <div className="col-1">
                            <input
                                type="number"
                                name="expenses"
                                placeholder="Expenses (mi)"
                                value={entry.expenses}
                                onChange={(event) => handleChange(index, event)}
                                className="form-control"
                                />
                        </div>
                        <div className="col-1">



                            <button type="button" className="form-control" onClick={() => handleRemoveEntry(index)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </div>
                    </div>
                ))}
               
            <div className="row">
                <div className="col-6"><p></p></div>
                <div className="col-2 text-end"><p>Total</p></div>
                <div className="col-1"><p>{totalHours} Hours</p></div>
                <div className="col-1"><p>{totalMiles} Miles</p></div>
                <div className="col-1"><p>£{totalExpenses.toFixed(2)}</p></div>
                <div className="col-1"></div>
            </div>
        </div>
    )
}
