//React
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
//Fetch
import axios from "axios"
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext"
//Icons
import { faAdd, faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Notifications
import { toast } from "react-toastify"
//Components
import MainContainer from "../../../Components/Containers/MainContainer"
import Loading from "../../../Components/Loading/Loading"
import CreateEditExternalRequestForm from "../Components/CreateEditExternalRequestForm"

export default function ExternalRequests() {
  const navigate = useNavigate()
  const { user, dispatch } = useAuthContext()

  const [externalRequests, setExternalRequests] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [showForm, setShowForm] = useState(false)

  useEffect(() => {
    const getExternalRequests = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/externalrequests`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )

        setExternalRequests(res.data)
      } catch (error) {
        if (error.response.status === 401) {
          dispatch({ type: "LOGOUT" })
          navigate("../../expired")
        } else {
          setError(error.response.status)
        }
      } finally {
        setLoading(false)
      }
    }

    getExternalRequests()
  }, [user, dispatch, navigate])

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `https://${process.env.REACT_APP_IP}/api/externalrequest/${id}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )

      setExternalRequests((prev) => {
        return prev.filter((request) => {
          return Number(request.id) !== Number(id)
        })
      })

      toast.success("Request deleted.")
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "LOGOUT" })
        navigate("../../expired")
      } else {
        setError(error.response.status)
      }
    }
  }
  const handleNewGuid = async (id) => {
    try {
      const res = await axios.get(
        `https://${process.env.REACT_APP_IP}/api/externalrequest/newguid/${id}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )

      const updatedRequests = externalRequests.map((request) => {
        if (request.id === res.data.id) {
          return res.data
        }
        return request
      })

      setExternalRequests(updatedRequests)

      toast.success("New GUID created.")
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "LOGOUT" })
        navigate("../../expired")
      } else {
        setError(error.response.status)
      }
    }
  }

  if (!loading) {
    return error ? (
      <MainContainer>Some error...</MainContainer>
    ) : (
      <MainContainer>
        <div className="d-flex justify-content-end mb-2">
          <button
            className="btn btn-primary my-2 "
            onClick={() => navigate(-1)}
          >
            Back <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        </div>
        <div className=" primaryShadow p-3">
          <div className="d-flex justify-content-between">
            <h3 className=" mb-3">External Requests</h3>

            <div>
              {!showForm ? (
                <button
                  className="btn btn-primary"
                  onClick={() => setShowForm(true)}
                >
                  <FontAwesomeIcon icon={faAdd} />
                </button>
              ) : (
                <button
                  className="btn btn-danger"
                  onClick={() => setShowForm(false)}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
          <hr />
          {!showForm && (
            <div className="col ">
              <div className="">
                {externalRequests.length > 0 ? (
                  externalRequests.map((request) => (
                    <div key={request.id} className="listItem p-3">
                      <div
                        className={`d-flex flex-column flex-md-row py-2 px-1 `}
                      >
                        <div className=" col-md-5 ">Name:</div>
                        <div className="col-md-7">{request.name}</div>
                      </div>
                      <div
                        className={`d-flex flex-column flex-md-row py-2 px-1 mb-2`}
                      >
                        <div className=" col-md-5 ">GUID:</div>
                        <div className="col-md-7">{request.guid}</div>
                      </div>
                      <div className="d-flex justify-content-end ">
                        <button
                          className="btn btn-danger  me-2"
                          onClick={() => handleNewGuid(request.id)}
                        >
                          Regenerate GUID
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleDelete(request.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No external requests created yet.</div>
                )}
              </div>
            </div>
          )}
          {showForm && (
            <CreateEditExternalRequestForm
              setExternalRequests={setExternalRequests}
              setShowForm={setShowForm}
            />
          )}
        </div>
      </MainContainer>
    )
  } else {
    return (
      <MainContainer>
        <Loading />
      </MainContainer>
    )
  }
}
