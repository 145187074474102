//React
import React from "react"
import { useState, useEffect } from "react"
//Fetch
import axios from "axios"
//Context
import { useAuthContext } from "../../Hooks/useAuthContext"
//Bootstrap
import { FloatingLabel } from "react-bootstrap"

export default function AssetSearch({ setForm, idsToSkip = [] }) {
  const { user } = useAuthContext()
  const [assetList, setAssetList] = useState([])
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    search: "",
  })

  useEffect(() => {
    const listAssets = async () => {
      let pageNum = 1
      try {
        const res = await axios.get(
          `https://${
            process.env.REACT_APP_IP
          }/api/communityassets?pageNumber=${pageNum}&search=${
            pagination.search
          }${idsToSkip
            .map((id, index) => `&idsToSkip[${index}]=${id}`)
            .join("")}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setAssetList(res.data.results)
      } catch (error) {
        console.log(error)
      } finally {
      }
    }
    if (pagination.search.length > 0) {
      listAssets()
    } else {
      setAssetList([])
    }
  }, [pagination.search, user])

  const handleSelect = (asset) => {
      setForm((prev) => {
          return {
              ...prev,
              communityBuildings: [...prev.communityBuildings, asset],
              communityBuildingsToRemove: prev.communityBuildingsToRemove.filter(
                  (c) => c.id !== asset.id
              ),
          };
      });
      setPagination({ ...pagination, search: "" });
  }

  return (
    <div>
      {/* <p>*Additional details can be added later.</p> */}
      <FloatingLabel label="Start typing to search...">
        <input
          placeholder="Start typing to search..."
          value={pagination.search}
          onChange={(e) =>
            setPagination((prev) => {
              return { ...prev, search: e.target.value }
            })
          }
          type="text"
          className="form-control mb-2"
        />
      </FloatingLabel>
      <div>
        {assetList.length > 0
          ? assetList.map((asset) => (
              <div
                key={asset.id}
                className="listItem p-2 hoverMe innerCon mb-1"
                onClick={() => handleSelect(asset)}
              >
                <h5>{asset.assetName}</h5>
                <div>{asset.assetType}</div>
              </div>
            ))
          : pagination.search !== "" && (
              <div className="ps-2">No assets found.</div>
            )}
        {pagination.search === "" && (
          <div className="ps-1 mb-3" style={{ fontSize: "14px" }}>
            Buildings can be found by searching for a name, location, parish,
            district, type, charity number and designation.
          </div>
        )}
      </div>
    </div>
  )
}
