//React
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

//Icons
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//Components
import ExportCsv from "../../../Components/ExportCsv"
import ContactFilters from "./ContactFilters"

export default function ContactList({
  contacts,
  setPagination,
  pagination,
  children,
  count,
  tagList,
}) {
  const [showFilters, setShowFilters] = useState(false)
  const navigate = useNavigate()

  return (
    <div className=" p-2">
      <div className="my-2 d-flex justify-content-between align-items-center">
        <h2 className="text-center">Contacts</h2>
        <button
          className="btn btn-primary"
          onClick={() => navigate("./create_contact")}
        >
          <FontAwesomeIcon icon={faAdd} />
        </button>
      </div>

      <div className="col mb-3">
        <input
          placeholder="Type to search..."
          value={pagination.search}
          onChange={(e) =>
            setPagination((prev) => {
              return { ...prev, search: e.target.value }
            })
          }
          type="text"
          className="form-control mb-2"
        />
        <div className="d-flex col mb-2 ">
          <button
            className={`btn ${showFilters ? "btn-danger" : "btn-primary"}`}
            onClick={() => setShowFilters(!showFilters)}
          >
            {!showFilters ? "Show" : "Hide"} Filters
          </button>
        </div>
        {showFilters && (
          <ContactFilters
            pagination={pagination}
            setPagination={setPagination}
            tagList={tagList}
          />
        )}
      </div>

      {contacts.length > 0 ? (
        <div>
          <div className="d-flex justify-content-between  mb-2">
            <div className="d-flex align-items-end ">{`Showing ${contacts.length}/${count}`}</div>
            {/* <ExportContactsCsv pagination={pagination} count={count} /> */}
            <ExportCsv
              filename={"Contacts"}
              count={count}
              url={`https://${
                process.env.REACT_APP_IP
              }/api/contacts?pageSize=${count}
              &pageNumber=1&search=${pagination.search}&isCsv=true
              &parish=${pagination.parish}&district=${
                pagination.district
              }&contactType=${pagination.contactType}&createdStart=${
                pagination.createdStart
              }&createdEnd=${pagination.createdEnd}&lastInteractionStart=${
                pagination.lastInteractionStart
              }&lastInteractionEnd=${pagination.lastInteractionEnd}
            ${pagination.tags
              .map((tag, index) => `&tags[${index}]=${tag.value}`)
              .join("")}`}
            />
          </div>
          {contacts.map((user) => (
            <div
              key={user.id}
              className="primaryShadow mb-2 p-2 hoverMe sidebarItem"
              onClick={() => navigate(`./view_contact/${user.id}`)}
            >
              <h5 className="mb-2">
                {user.lastName}, {user.firstName} 
              </h5>
              <div>{user.contactType}</div>
            </div>
          ))}
        </div>
      ) : (
        <div>No contacts found...</div>
      )}
      {children}
    </div>
  )
}
