//React
import React from "react"
import { useState, useEffect } from "react"
//Fetch
import axios from "axios"
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext"

export default function ContactSearch({ handleCorrectContact }) {
  const { user } = useAuthContext()
  const [contactList, setContactList] = useState([])
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    search: "",
  })

  useEffect(() => {
    const listContacts = async () => {
      let pageNum = 1
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/contacts?pageNumber=${pageNum}&search=${pagination.search}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setContactList(res.data.results)
      } catch (error) {
        console.log(error)
      } finally {
      }
    }
    if (pagination.search.length > 0) {
      listContacts()
    } else {
      setContactList([])
    }
  }, [pagination.search, user])

  return (
    <div className="mb-4">
      <h4>Search for existing contact</h4>
      <hr />
      {/* <p>*Additional details can be added later.</p> */}
      <input
        placeholder="Start typing to search..."
        value={pagination.search}
        onChange={(e) =>
          setPagination((prev) => {
            return { ...prev, search: e.target.value }
          })
        }
        type="text"
        className="form-control mb-2"
      />
      <div>
        {contactList.length > 0
          ? contactList.map((contact) => (
              <div
                key={contact.id}
                className="listItem p-2 hoverMe innerCon mb-1"
                onClick={() => handleCorrectContact(contact)}
              >
                <h5>
                  {contact.firstName} {contact.lastName}
                </h5>
                <div>{contact.contactType}</div>
              </div>
            ))
          : pagination.search !== "" && (
              <div className="ps-2">No contacts found.</div>
            )}
        {pagination.search === "" && (
          <div className="ps-1" style={{ fontSize: "14px" }}>
            Contacts can be found by searching for a name, email, parish,
            district, county, type and address.
          </div>
        )}
      </div>
    </div>
  )
}
