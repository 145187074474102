//React
import React from "react"
import { useNavigate } from "react-router-dom"
//Infinite Scroll
import { useInView } from "react-cool-inview"
//Icons
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Components
import MainContainer from "../../../Components/Containers/MainContainer"
import SideContainer from "../../../Components/Containers/SideContainer"
import AssetList from "../Components/AssetList"

export default function AssetHome({
  assets,
  setPagination,
  pagination,
  showMore,
  listAssets,
  count,
}) {
  const navigate = useNavigate()

  const { observe } = useInView({
    onEnter: () => {
      listAssets()
    },
  })
  return (
    <div>
      <SideContainer classes={"d-xl-none"}>
        <AssetList
          assets={assets}
          setPagination={setPagination}
          pagination={pagination}
          count={count}
        >
          {showMore && <div ref={observe} className="pb-5"></div>}
        </AssetList>
      </SideContainer>
      <MainContainer
        classes={
          "d-none d-xl-flex justify-content-center align-items-center p-4"
        }
      >
        <div>
          Select an asset or click
          <button
            className="btn btn-primary mx-2"
            onClick={() => navigate("./create_asset")}
          >
            <FontAwesomeIcon icon={faAdd} />
          </button>
          to create a new asset
        </div>
      </MainContainer>
    </div>
  )
}
