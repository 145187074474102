//React
import React, { useEffect } from "react"
import { useState } from "react"

//Context
import { useAuthContext } from "../../../Hooks/useAuthContext"

//Notifications
import { toast } from "react-toastify"

//Fetch
import axios from "axios"

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

//Components
import Spinner from "../../../Components/Loading/Spinner"
import EnquirySearch from "./EnquirySearch"
import QuickEnquiryForm from "./QuickEnquiryForm"
import ListItem from "../../../Components/ListItem"

export default function InteractionEnquiryDetails({
  form,
  params,
  setForm,
  updateInteractions,
}) {
  const { user } = useAuthContext()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [userList, setUserList] = useState([])
  const [enquiryComplete, setEnquiryComplete] = useState(false)

  useEffect(() => {
    const listUsers = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/users?pageSize=500`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setUserList(res.data)
      } catch (error) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    listUsers()
  }, [user])

  const handleCorrectEnquiry = (enquiry) => {
    console.log(enquiry)
    setEnquiryComplete(true)
    setForm({
      ...form,
      enquiry: enquiry,
      enquiryId: enquiry.id,
    })
  }

  const handleSubmit = async (data) => {
    setSubmitting(true)
    try {
      await axios.post(
        `https://${process.env.REACT_APP_IP}/api/interaction/accept`,
        data,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      toast.success("Success.")
      updateInteractions(params.id)
    } catch (error) {
      toast.error(
        "Oops that didnt work. Please try again. If the issue persists contact the administrator."
      )
    } finally {
      setSubmitting(false)
    }
  }

  if (!loading) {
    return error ? (
      <div>Some error..</div>
    ) : (
      <div className="primaryShadow">
        {!enquiryComplete && (
          <div className="p-3">
            <EnquirySearch handleCorrectEnquiry={handleCorrectEnquiry} />
            <QuickEnquiryForm
              userList={userList}
              form={form}
              setForm={setForm}
              setEnquiryComplete={setEnquiryComplete}
            />
          </div>
        )}

        {enquiryComplete && (
          <div className="p-3">
            <h3 className="ps-1 my-2">Enquiry Details</h3>
            <hr />
            <div className="mb-3">
              <ListItem label={"Title"} value={`${form.enquiry.title} `} />

              <ListItem
                label={"Parish"}
                value={form.enquiry.parish ? form.enquiry.parish : ""}
              />

              <ListItem
                label={"Query Date"}
                value={
                  form.enquiry.dateOfQuery
                    ? new Date(form.enquiry.dateOfQuery).toLocaleDateString(
                        "en-GB"
                      )
                    : ""
                }
              />

              <ListItem
                label={"Nature of enquiry"}
                value={
                  form.enquiry.natureOfQuery ? form.enquiry.natureOfQuery : ""
                }
              />
            </div>
            <div className="d-flex justify-content-end">
              <button
                disabled={submitting}
                type="button"
                className="btn btn-primary"
                onClick={() => handleSubmit(form)}
              >
                {!submitting ? (
                  "Submit"
                ) : (
                  <div>
                    Please wait <FontAwesomeIcon icon={faSpinner} spin />
                  </div>
                )}
              </button>
            </div>
            {/* <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary me-2"
                onClick={() => handleSubmit(form)}
              >
                Submit
              </button>
            </div> */}
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div>
        <Spinner text={"Loading..."} />
      </div>
    )
  }
}
