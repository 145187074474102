import axios from "axios"
import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { useAuthContext } from "../../Hooks/useAuthContext"

export default function FloatingUserSelect({ form, setForm, classes }) {
  const { user } = useAuthContext()
  const [users, setUsers] = useState([])

  useEffect(() => {
    const listUsers = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/users?pageSize=500`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        console.log(res.data)
        setUsers(res.data)
      } catch (error) {
        console.log(error)
      }
    }

    listUsers()
  }, [user])

  const handleSelectUser = (e) => {
    const selectedUser = users.find((user) => user.id === e.target.value)

    setForm((prev) => ({ ...prev, userId: e.target.value, user: selectedUser }))
  }

  return (
    <Form.Group className={`mb-1 ${classes} p-1`}>
      <div className="form-floating">
        <Form.Select
          value={form.userId ?? ""}
          required
          onChange={(e) => handleSelectUser(e)}
        >
          <option disabled value="">
            Select user
          </option>
          {users.map((user) => (
            <option key={user.id} value={user.id}>
              {user.lastName}, {user.firstName}
            </option>
          ))}
        </Form.Select>
        <label>
          Staff Member<span className="text-danger">*</span>
        </label>
      </div>
    </Form.Group>
  )
}
