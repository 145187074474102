import React from "react"
import { Form } from "react-bootstrap"

export default function FormCheck({
  label,
  value = false,
  setField,
  field,
  required = false,
  classes,
}) {
  return (
    <Form.Group className={`d-flex align-items-center mb-2 ${classes} p-1`}>
      <Form.Label className="ps-2 me-3 mt-2 col-11 col-md-10">
        {label}
      </Form.Label>
      <Form.Check
        onChange={(e) => setField(field, e.target.checked)}
        checked={value ? true : false}
        required={required}
      />
    </Form.Group>
  )
}
