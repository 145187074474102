import React from "react"

//Bootstrap
import { Form } from "react-bootstrap"

//Components
import FormCheck from "../../../Components/Forms/FormCheck"
import DistrictSelect from "../../../Components/SelectDropdowns/DistrictSelect"
import ParishSelect from "../../../Components/SelectDropdowns/ParishSelect"
import UserSelect from "../../../Components/SelectDropdowns/UserSelect"
import LocalitySelect from "../../../Components/SelectDropdowns/LocalitySelect"

export default function ContactFilters({ setPagination, pagination, tagList }) {
  const setField = (field, value) => {
    setPagination({
      ...pagination,
      [field]: value,
    })
  }

  const handleReset = () => {
    setPagination((prev) => {
      return {
        ...prev,
        district: "",
        parish: "",
        contactType: "",
        createdStart: "",
        createdEnd: "",
        lastInteractionStart: "",
        lastInteractionEnd: "",
        tags: [],
        excludeAttendees: false,
        createdById: "",
        locality: "",
        actSupporter: "",
      }
    })
  }

  return (
    <div className="primaryShadow p-2">
      {/* District */}
      <DistrictSelect
        value={pagination.district}
        setField={setField}
        field={"district"}
      />
      <ParishSelect
        value={pagination.parish}
        setField={setField}
        field={"parish"}
      />
      <LocalitySelect
        value={pagination.locality}
        setField={setField}
        field={"locality"}
      />
      <Form.Group className="mb-1  col">
        <Form.Label>ACT Supporter</Form.Label>
        <Form.Select
          value={pagination.actSupporter ?? ""}
          required
          onChange={(e) => setField("actSupporter", e.target.value)}
        >
          <option value="">All </option>
          <option value="true">Yes</option>
          <option value="false">No</option>
         
        </Form.Select>
      </Form.Group>

      {/* Group Type */}
      <Form.Group className="mb-1  col">
        <Form.Label>Contact type</Form.Label>
        <Form.Select
          value={pagination.contactType ?? ""}
          required
          onChange={(e) => setField("contactType", e.target.value)}
        >
          <option value="">All types</option>
          <option value="ACT Trustee">ACT Trustee</option>
          <option value="Attendee">Attendee</option>
          <option value="Community Asset/Building Group">
            Community Asset/Building Group
          </option>
          <option value="Community Planning Group">
            Community Planning Group
          </option>
          <option value="Individual">Individual</option>
          <option value="Local Authority">Local Authority</option>
          <option value="Strategic Partner">Strategic Partner</option>
          <option value="Other">Other</option>
        </Form.Select>
        <FormCheck
          label={"Exclude Event Signups?"}
          setField={setField}
          field={"excludeAttendees"}
          value={pagination.excludeAttendees ?? ""}
        />
      </Form.Group>
      {/* Created By */}
      <Form.Group className="mb-2">
        <label>Created By</label>
        <UserSelect
          value={pagination.createdById}
          setField={setField}
          field={"createdById"}
        />
      </Form.Group>
      {/* Created Date Range */}
      <Form.Group className="mb-2">
        <label>Created</label>
        <div className="d-flex">
          <Form.Group className="col-6 d-flex flex-column p-1">
            <Form.Label>Start</Form.Label>
            <Form.Control
              type="date"
              value={pagination.createdStart ?? ""}
              onChange={(e) => setField("createdStart", e.target.value)}
            />
          </Form.Group>
          <Form.Group className="col-6 d-flex flex-column p-1">
            <Form.Label>End</Form.Label>
            <Form.Control
              type="date"
              value={pagination.createdEnd ?? ""}
              onChange={(e) => setField("createdEnd", e.target.value)}
            />
          </Form.Group>
        </div>
      </Form.Group>
      {/* Last Interaction Date Range */}
      <Form.Group className="mb-2">
        <label>Last Interaction</label>
        <div className="d-flex">
          <Form.Group className="col-6 d-flex flex-column p-1">
            <Form.Label>Start</Form.Label>
            <Form.Control
              type="date"
              value={pagination.lastInteractionStart ?? ""}
              onChange={(e) => setField("lastInteractionStart", e.target.value)}
            />
          </Form.Group>
          <Form.Group className="col-6 d-flex flex-column p-1">
            <Form.Label>End</Form.Label>
            <Form.Control
              type="date"
              value={pagination.lastInteractionEnd ?? ""}
              onChange={(e) => setField("lastInteractionEnd", e.target.value)}
            />
          </Form.Group>
        </div>
      </Form.Group>
      <div className="d-flex justify-content-end">
        <button className="btn btn-danger" onClick={handleReset}>
          Reset Filters
        </button>
      </div>
    </div>
  )
}
