//React
import React from "react"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
//Fetch
import axios from "axios"
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext"
//Icons
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Notifications
import { toast } from "react-toastify"
//Components
import FormControl from "../../../Components/Forms/FormControl"
//Bootstrap
import { FloatingLabel, Form } from "react-bootstrap"
import FloatingInteractionTypeSelect from "../../../Components/SelectDropdowns/FloatingInteractionTypeSelect"
import FloatingUserSelect from "../../../Components/SelectDropdowns/FloatingUserSelect"

export default function CreateEditInteractionForm({
  interaction,
  setInteractions,
  setInteraction,
  updateInteractions,
  setEdit,
}) {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const [form, setForm] = useState({ userId: user.id })
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (interaction) {
      setForm(interaction)
    }
  }, [interaction])

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })
  }

  const handleSubmit = async (e) => {
    setSubmitting(true)
    e.preventDefault()
    if (interaction) {
      //Edit
      try {
        const res = await axios.post(
          `https://${process.env.REACT_APP_IP}/api/interaction/${form.id}`,
          form,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        toast.success("Interaction updated.")
        setEdit(false)
        setInteraction(res.data)
        updateInteractions(res.data)
        navigate(`../view_interaction/${res.data.id}`)
      } catch (err) {
        console.log(err)
        toast.error(
          "Oops that didnt work. Please try again. If the issue persists contact the administrator."
        )
      } finally {
        setSubmitting(false)
      }
    } else {
      //create
      try {
        const res = await axios.post(
          `https://${process.env.REACT_APP_IP}/api/interaction`,
          form,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        toast.success("Interaction created.")
        setForm({ isAdmin: false })

        setInteractions((prev) => {
          return [...prev, res.data]
        })
        navigate(-1)
      } catch (err) {
        console.log(err)
        toast.error(
          "Oops that didnt work. Please try again. If the issue persists contact the administrator."
        )
      } finally {
        setSubmitting(false)
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit} className="primaryShadow p-2 p-md-3">
      <div className="mb-3">
        <h4 className="ps-2">Interaction Details</h4>
        <hr />
        <div className="d-flex flex-column flex-md-row ">
          <FormControl
            value={form.dateAndTime ? form.dateAndTime : ""}
            type={"datetime-local"}
            label={"Interaction Date and Time"}
            field={"dateAndTime"}
            setField={setField}
            classes={"col-md-6"}
            required
          />
          <FormControl
            value={form.contactName ? form.contactName : ""}
            label={"Contact Name"}
            field={"contactName"}
            setField={setField}
            classes={"col-md-6"}
            required
          />
        </div>
        <div className="d-flex flex-column flex-md-row ">
          <FloatingInteractionTypeSelect
            value={form.type}
            field={"type"}
            setField={setField}
            classes={"col"}
            required
          />

          {!form.type ? (
            <Form.Group className={`mb-1 col  p-1`}>
              <FloatingLabel label="Please select a type">
                <Form.Control disabled placeholder="Please select a type.." />
              </FloatingLabel>
            </Form.Group>
          ) : (
            <>
              {" "}
              {form.type === "Email" && (
                <>
                  <FormControl
                    value={form.phone}
                    label={"Contact Phone Number"}
                    field={"phone"}
                    setField={setField}
                    classes={"col"}
                  />
                  <FormControl
                    value={form.email}
                    type="email"
                    label={"Contact Email"}
                    field={"email"}
                    setField={setField}
                    classes={"col"}
                    required={true}
                  />
                </>
              )}
              {form.type === "Phone" && (
                <>
                  <FormControl
                    value={form.phone}
                    label={"Contact Phone Number"}
                    field={"phone"}
                    setField={setField}
                    classes={"col"}
                    required={true}
                  />
                  <FormControl
                    value={form.email}
                    type="email"
                    label={"Contact Email"}
                    field={"email"}
                    setField={setField}
                    classes={"col"}
                  />
                </>
              )}
              {form.type !== "Phone" && form.type !== "Email" && (
                <>
                  <FormControl
                    value={form.phone}
                    label={"Contact Phone Number"}
                    field={"phone"}
                    setField={setField}
                    classes={"col"}
                  />
                  <FormControl
                    value={form.email}
                    type="email"
                    label={"Contact Email"}
                    field={"email"}
                    setField={setField}
                    classes={"col"}
                  />
                </>
              )}
            </>
          )}
        </div>

        <div>
          <Form.Group className={`mb-1  p-1`}>
            <div className="form-floating">
              <Form.Control
                as="textarea"
                rows={5}
                value={form.description ?? ""}
                placeholder="Client name"
                onChange={(e) => setField("description", e.target.value)}
                style={{ minHeight: "120px" }}
                required
              />
              <label>
                Description <span className="text-danger">*</span>
              </label>
            </div>
          </Form.Group>
        </div>
        <div>
          <FloatingUserSelect setForm={setForm} form={form} />
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button disabled={submitting} type="submit" className="btn btn-primary">
          {!submitting ? (
            "Submit"
          ) : (
            <div>
              Please wait <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          )}
        </button>
      </div>
    </Form>
  )
}
