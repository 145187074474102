//React
import React from "react"
import { useInView } from "react-cool-inview"
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "../../../Hooks/useAuthContext"
//Icons
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Components
import MainContainer from "../../../Components/Containers/MainContainer"
import SideContainer from "../../../Components/Containers/SideContainer"
import UsersList from "../Components/UsersList"
//Styles
import "./Home.css"

export default function Home({
  users,
  setPagination,
  pagination,
  listUsers,
  showMore = true,
}) {
  const navigate = useNavigate()
  const { user } = useAuthContext()
  const { observe } = useInView({
    onEnter: () => {
      listUsers()
    },
  })

  return (
    <div>
      <SideContainer classes={"d-xl-none"}>
        <UsersList
          users={users}
          setPagination={setPagination}
          pagination={pagination}
        >
          {showMore && <div ref={observe} className="pb-5"></div>}
        </UsersList>
      </SideContainer>
      <MainContainer
        classes={
          "d-none d-xl-flex justify-content-center align-items-center p-4"
        }
      >
        {user.isAdmin ? (
          <div>
            Select a user or click
            <button
              className="btn btn-primary mx-2"
              onClick={() => navigate("./create_user")}
            >
              <FontAwesomeIcon icon={faAdd} />
            </button>
            to create a new user.
          </div>
        ) : (
          <div>Select a user to view thier details.</div>
        )}
      </MainContainer>
    </div>
  )
}
