//React
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
//Context
import { useAuthContext } from "../../Hooks/useAuthContext";
//Bootstrap
import { Nav, Navbar, Container } from "react-bootstrap";
//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
//Styles
import "./TopNav.css";

export default function TopNav({ theme, setTheme }) {
  const { user, dispatch } = useAuthContext();
  const [expanded, setExpanded] = useState(false);

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      sticky="top"
      expanded={expanded}
      className="topNav no-print"
      variant="dark"
    >
      <Container className="container-fluid">
        <NavLink to="/home" className="navbar-brand">
          ACTionCRM
        </NavLink>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          {user && (
            <>
              <Nav className="me-auto">
                {/* <NavLink
                  className="nav-link"
                  to="/interactions"
                  onClick={() => setExpanded(false)}
                >
                  Interactions
                </NavLink> */}
                <NavLink
                  className="nav-link"
                  to="/enquiries"
                  onClick={() => setExpanded(false)}
                >
                  Enquiries
                </NavLink>
                <NavLink
                  className="nav-link"
                  to="/contacts"
                  onClick={() => setExpanded(false)}
                >
                  Contacts
                </NavLink>
                <NavLink
                  className="nav-link"
                  to="/events"
                  onClick={() => setExpanded(false)}
                >
                  Events
                </NavLink>

                <NavLink
                  className="nav-link"
                  to="/community_assets"
                  onClick={() => setExpanded(false)}
                >
                  Community Buildings
                </NavLink>

                <NavLink
                    className="nav-link"
                    to="/timesheets"
                    onClick={() => setExpanded(false)}
                >
                    Timesheets
                </NavLink>


                {/* Dont forget to remove this  */}
                {/* <NavLink
                  className="nav-link"
                  to="/Branding"
                  onClick={() => setExpanded(false)}
                >
                  Branding
                </NavLink> */}
              </Nav>
              <Nav>
                <NavLink
                  className="nav-link"
                  to="/users"
                  onClick={() => setExpanded(false)}
                >
                  Users
                </NavLink>
                {user.isAdmin && (
                  <NavLink
                    className="nav-link"
                    to="/admin"
                    onClick={() => setExpanded(false)}
                  >
                    Admin
                  </NavLink>
                )}

                <Nav.Link
                  onClick={() => {
                    dispatch({ type: "LOGOUT" });
                    // navigate("../")
                  }}
                >
                  Log Out
                </Nav.Link>

                <div
                  className="d-flex align-items-center px-2 hoverMe"
                  onClick={switchTheme}
                >
                  <FontAwesomeIcon icon={faLightbulb} />
                  {/* {theme !== "light" ? "Light" : "Dark"} Mode */}
                </div>
              </Nav>
            </>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
