//React
import React from "react"
//Components
import LoginForm from "../../Components/Forms/LoginForm"

export default function SessionExpired() {
  return (
    <main
      className="justify-content-center row align-content-center px-2 px-md-0"
      style={{ height: "90vh" }}
    >
      <div className="loginForm shadow p-3 col-md-5">
        <h1 className="text-center mb-3">Session Expired</h1>
        <h4 className="text-center mb-3">Please login</h4>
        <LoginForm />
        {/* <div className="d-flex justify-content-center mb-2">
          <button className="btn btn-primary" onClick={() => navigate("/")}>
            Login
          </button>
        </div> */}
      </div>
    </main>
  )
}
