//React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//Icons
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import ExportCsv from "../../../Components/ExportCsv";
import EnquiryFilters from "./EnquiryFilters";

export default function EnquiryList({
  enquiries,
  setPagination,
  pagination,
  children,
  count,
  tagList,
}) {
  // const { user, dispatch } = useAuthContext()

  const [showFilters, setShowFilters] = useState(false);
  const navigate = useNavigate();

  return (
    <div className=" p-2">
      <div className="my-2 d-flex justify-content-between align-items-center">
        <h3 className="text-center">Enquiries</h3>
        <button
          className="btn btn-primary"
          onClick={() => navigate("./create_enquiry")}
        >
          <FontAwesomeIcon icon={faAdd} />
        </button>
      </div>

      <div className="col mb-2">
        <input
          placeholder="Type name to search..."
          value={pagination.search}
          onChange={(e) =>
            setPagination((prev) => {
              return { ...prev, search: e.target.value };
            })
          }
          type="text"
          className="form-control mb-2"
        />
        <div className="d-flex col mb-2">
          <button
            className={`btn ${showFilters ? "btn-danger" : "btn-primary"}`}
            onClick={() => setShowFilters(!showFilters)}
          >
            {/* <FontAwesomeIcon icon={faFilter} /> */}
            {!showFilters ? "Show" : "Hide"} Filters
          </button>
        </div>
        {showFilters && (
          <EnquiryFilters
            pagination={pagination}
            setPagination={setPagination}
            tagList={tagList}
          />
        )}
      </div>

      {enquiries.length > 0 ? (
        <div>
          <div className="d-flex justify-content-between mb-2">
            <div className="d-flex align-items-end">{`Showing ${enquiries.length}/${count}`}</div>
            <ExportCsv
              filename={"Enquiries"}
              count={count}
              url={`https://${
                process.env.REACT_APP_IP
              }/api/enquiries?pageSize=${count}&isCsv=true&pageNumber=1&search=${pagination.search}&queriedBy=${pagination.queriedBy.value ?? ""}&parish=${pagination.parish}&district=${pagination.district}&groupType=${pagination.groupType}&recievedStart=${pagination.recievedStart}&recievedEnd=${pagination.recievedEnd}&leadForFuture=${pagination.leadForFuture.value ?? ""}${pagination.tags
                .map((tag, index) => `&tags[${index}]=${tag.value}`)
                .join("")}`}
            />
          </div>
          {enquiries.map((enquiry) => (
            <div
              key={enquiry.id}
              className="primaryShadow mb-2 p-2 hoverMe sidebarItem"
              onClick={() => navigate(`./view_enquiry/${enquiry.id}`)}
            >
              <h5 className="mb-2 text-truncate">{enquiry.title}</h5>
              <div className="mb-1">{enquiry.groupName}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className="p-2">No enquiries found...</div>
      )}
      {children}
    </div>
  );
}
