//React
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useAuthContext } from "../../../Hooks/useAuthContext"
import { useNavigate } from "react-router-dom"

//Fetch
import axios from "axios"

import FormControl from "../../../Components/Forms/FormControl";

//Bootstrap
import Form from "react-bootstrap/Form"
import FloatingLabel from "react-bootstrap/FloatingLabel"
import InputGroup from 'react-bootstrap/InputGroup';

//Helper
import { validateForm } from "../../../Helpers/FormValidation"

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

export default function CreateUserForm({ setUsers }) {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const [form, setForm] = useState({ isAdmin: false })
  const [formErrors, setFormErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)

  const postRegister = async (data) => {
    setSubmitting(true)
    try {
      const res = await axios.post(
        `https://${process.env.REACT_APP_IP}/api/user`,
        data,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      toast.success("User created.")
      setForm({ isAdmin: false })

      setUsers((prev) => {
        return [...prev, res.data]
      })

      navigate(`../view_user/${res.data.id}`)
    } catch (err) {
      console.log(err)
      if (err.response.data === "User Exists") {
        setFormErrors((prev) => {
          return { ...prev, email: "Email already exists" }
        })
      } else {
        toast.error(
          "Oops that didnt work. Please try again. If the issue persists contact the administrator."
        )
      }
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    const findFormErrors = () => {
      const newErrors = validateForm(form)

      setFormErrors(newErrors)
    }
    findFormErrors()
  }, [form])

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const { email, password, password2, firstName, lastName } = formErrors

    if (!email && !password && !password2 && !firstName && !lastName) {
      const user = {
        firstName: form.firstName,
        lastName: form.lastName,
        initials: form.initials,
        email: form.email,
        password: form.password1,
        isAdmin: form.isAdmin,
        targetHours: form.targetHours,
        holidayAllowance: form.holidayAllowance,
        holidayPeriodStartDate: form.holidayPeriodStartDate
      }

      postRegister(user)
    }
  }

  return (
      <Form onSubmit={handleSubmit}>
      <h3>Personal Details</h3>
          <div className="row">
              <div className="col-5">
              <Form.Group controlId="validationCustom01">
              <FloatingLabel label="First Name" className="mb-3">
                  <Form.Control
                      value={form.firstName ?? ""}
                      required
                      type="text"
                      placeholder="Enter Name"
                      isInvalid={formErrors.firstName}
                      isValid={formErrors.firstName === ""}
                      onChange={(e) => setField("firstName", e.target.value)}
                          />
                          <Form.Control.Feedback className="text-danger" type="invalid">
                              {formErrors.firstName}
                          </Form.Control.Feedback>
                      </FloatingLabel>
                  </Form.Group>
              </div>
              <div className="col-5">
                  <Form.Group controlId="validationCustom01">
                      <FloatingLabel label="Last Name" className="mb-3">
                          <Form.Control
                              value={form.lastName ?? ""}
                              required
                              type="text"
                              placeholder="Enter Name"
                              isInvalid={formErrors.lastName}
                              isValid={formErrors.lastName === ""}
                              onChange={(e) => setField("lastName", e.target.value)}
                          />
                          <Form.Control.Feedback className="text-danger" type="invalid">
                              {formErrors.lastName}
                          </Form.Control.Feedback>
                      </FloatingLabel>
                  </Form.Group>
              </div>
              <div className="col-2">
                  <Form.Group controlId="validationCustom01">
                      <FloatingLabel label="Initials" className="mb-3">
                          <Form.Control
                              value={form.initials ?? ""}
                              required
                              type="text"
                              placeholder="Enter Name"
                              isInvalid={formErrors.initials}
                              isValid={formErrors.initials === ""}
                              onChange={(e) => setField("initials", e.target.value)}
                          />
                          <Form.Control.Feedback className="text-danger" type="invalid">
                              {formErrors.initials}
                          </Form.Control.Feedback>
                      </FloatingLabel>
                  </Form.Group>
              </div>
          </div>
          <div className="row">
              <Form.Group>
                  <FloatingLabel label="Email address" className="mb-3">
                      <Form.Control
                          value={form.email ?? ""}
                          required
                          type="email"
                          placeholder="name@example.com"
                          isInvalid={formErrors.email}
                          isValid={formErrors.email === ""}
                          onChange={(e) => setField("email", e.target.value)}
                      />
                      <Form.Control.Feedback className="text-danger" type="invalid">
                          {formErrors.email}
                      </Form.Control.Feedback>
                  </FloatingLabel>
              </Form.Group>
          </div>
          <div className="row">
              <div className="col-6">
                  <Form.Group>
                      <FloatingLabel label="Password" className="mb-3">
                          <Form.Control
                              value={form.password1 ?? ""}
                              required
                              type="password"
                              placeholder="Password"
                              isInvalid={formErrors.password1}
                              isValid={formErrors.password1 === ""}
                              onChange={(e) => setField("password1", e.target.value)}
                          />
                          <Form.Control.Feedback className="text-danger" type="invalid">
                              {formErrors.password1}
                          </Form.Control.Feedback>
                      </FloatingLabel>
                  </Form.Group>
              </div>
              <div className="col-6">
                  <Form.Group>
                      <FloatingLabel label="Re-Type Password" className="mb-3">
                          <Form.Control
                              value={form.password2 ?? ""}
                              required
                              type="password"
                              placeholder="Re-Type Password"
                              isInvalid={formErrors.password2}
                              isValid={formErrors.password2 === ""}
                              onChange={(e) => setField("password2", e.target.value)}
                          />
                          <Form.Control.Feedback className="text-danger" type="invalid">
                              {formErrors.password2}
                          </Form.Control.Feedback>
                      </FloatingLabel>
                  </Form.Group>
              </div>
          </div>

          {user.isAdmin && (
          <div className="row">
              <div className="col-6">
                  <Form.Group className="d-flex ">
                      <Form.Label className="me-3">Is Admin</Form.Label>
                      <Form.Check onChange={(e) => setField("isAdmin", e.target.checked)} />
                  </Form.Group>
              </div>
          </div>
          )}


          <h4 style={{ marginTop: 20 } }>Timesheets</h4>

          <div className="row">
              <div className="col-6">
                  <InputGroup>
                      <FloatingLabel label="Target Hours" className="mb-3">
                          <Form.Control
                              value={form.targetHours ?? ""}
                              required
                              type="text"
                              placeholder="Hours"
                              isInvalid={formErrors.targetHours}
                              isValid={formErrors.targetHours === ""}
                              onChange={(e) => setField("targetHours", e.target.value)}
                          />
                          <Form.Control.Feedback className="text-danger" type="invalid">
                              {formErrors.targetHours}
                          </Form.Control.Feedback>
                      </FloatingLabel>
                      <InputGroup.Text id="basic-addon1" className="mb-3">Hours</InputGroup.Text>
                  </InputGroup>
              </div>
          </div>
          <div className="row">
              <div className="col-6">
                  <InputGroup>
                      <FloatingLabel label="Holiday Allowance" className="mb-3">
                          <Form.Control
                              value={form.holidayAllowance ?? ""}
                              required
                              type="text"
                              placeholder="Hours"
                              isInvalid={formErrors.holidayAllowance}
                              isValid={formErrors.holidayAllowance === ""}
                              onChange={(e) => setField("holidayAllowance", e.target.value)}
                          />
                          <Form.Control.Feedback className="text-danger" type="invalid">
                              {formErrors.holidayAllowance}
                          </Form.Control.Feedback>
                      </FloatingLabel>
                      <InputGroup.Text id="basic-addon1" className="mb-3">Hours</InputGroup.Text>
                  </InputGroup>
              </div>
          </div>
          <div className="row">
              <div className="col-4">

                      <FloatingLabel label="Holiday Period Start Date" className="mb-3">
                      <FormControl
                          value={form.holidayPeriodStartDate ? form.holidayPeriodStartDate.split("T")[0] : ""}
                          type={"date"}
                          label={"Date Joined"}
                          field={"holidayPeriodStartDate"}
                          setField={setField}
                          classes={"col-md-6"}
                      />
                          <Form.Control.Feedback className="text-danger" type="invalid">
                              {formErrors.holidayPeriodStartDate}
                          </Form.Control.Feedback>
                      </FloatingLabel>
  
              </div>
          </div>





      <div className="d-flex justify-content-end">
        <button disabled={submitting} type="submit" className="btn btn-primary">
          {!submitting ? (
            "Submit"
          ) : (
            <div>
              Please wait <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          )}
        </button>
      </div>
    </Form>
  )
}
