//React
import React from "react"
//Styles
import "./ConfirmDialog.css"

export default function ConfirmDialog({ isVisible, question, handleConfirm, handleCancel }) {
  return (
    <>
    {isVisible && (
      <>
      <div class="popupContainer"></div>
        <div class="alert">
          <div className={`row`}>
            <div className="col-md-12 ">
              {question}
            </div>
          </div>
          <div className={`row`}>
            <div className="col-md-12 ">

              <button onClick={handleConfirm} className="btn btn-danger" >Ok</button>
              <button onClick={handleCancel} className="btn btn-primary">Cancel</button>
            </div>
          </div>
        </div>
      </>
    )}
    </>
  )
}
