//React
import React from "react";
import { Link } from "react-router-dom";
//Components
import ListItem from "../../../Components/ListItem";
import InteractionsListAndForm from "./InteractionsListAndForm";
//Styles
import "./EnquiryDetails.css";

export default function EnquiryDetails({ enquiry, setEnquiry }) {
  console.log("enquiry", enquiry);
  return (
    <div className="enquiryDetails">
      <div className="ps-2 mb-3">
        <ListItem label={"Title"} value={enquiry.title} />
        <ListItem label={"Group Name"} value={enquiry.groupName} />
        <ListItem label={"Group Type"} value={enquiry.groupType} />
        <ListItem label={"District"} value={enquiry.district} />
        <ListItem label={"Parish"} value={enquiry.parish} />
        <ListItem
          label={"Unitary Authority"}
          value={enquiry.unitaryAuthority}
        />
        <ListItem
          label={"Timesheet Code"}
          value={enquiry.timesheetCode ? enquiry.timesheetCode.code : ""}
        />
        <ListItem
          label={"State"}
          value={<span className="innerCon p-1 me-1 ">{enquiry.state}</span>}
        />
        <ListItem
          label={"Tags"}
          value={enquiry.tags.map((tag) => (
            <span key={tag.id} className="innerCon p-1 me-1 ">
              {tag.value}
            </span>
          ))}
        />
      </div>

      <h3 className=" mb-3">Query Details</h3>
      <hr />
      <div className="ps-2 mb-3">
        <ListItem
          label={"Query Date"}
          value={
            enquiry.dateOfQuery
              ? new Date(enquiry.dateOfQuery).toLocaleDateString("en-GB")
              : ""
          }
        />
        <div className={`d-flex flex-column flex-md-row py-2 px-1  listItem`}>
          <div className=" col-md-5 ">Query Taken By:</div>
          <div>
            {enquiry.queryTakenBy ? (
              <Link
                to={`/users/view_user/${enquiry.queryTakenBy.id}`}
              >{`${enquiry.queryTakenBy.firstName} ${enquiry.queryTakenBy.lastName}`}</Link>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={`d-flex flex-column flex-md-row py-2 px-1  listItem`}>
          <div className=" col-md-5 ">Contacts:</div>
          {enquiry.contacts &&
            enquiry.contacts.map((contact) => (
              <div key={contact.id}>
                <div style={{ marginRight: 10 }}>
                  <Link to={`/contacts/view_contact/${contact.id}`}>
                    {`${contact.firstName ?? ""} ${contact.lastName ?? ""}`}
                  </Link>
                </div>
              </div>
            ))}
        </div>
        <div className={`d-flex flex-column flex-md-row py-2 px-1  listItem`}>
                  <div className=" col-md-5 ">Community Building:</div>
          <div class="col-md-7">
                  {enquiry.communityBuildings &&
                      enquiry.communityBuildings.map((building) => (
                          <div key={building.id} style={{ width: "100%" }}>
                              
                                  <Link to={`/community_assets/view_asset/${building.id}`}>
                                      {building.assetName}
                                  </Link>
                              
                          </div>
                      ))}
                  </div>
        </div>
        <ListItem label={"Initial Source"} value={enquiry.initialSource} />
        <div className={`d-flex flex-column flex-md-row py-2 px-1  listItem`}>
          <div className=" col-md-5 ">Lead For Future:</div>
          <div>
            {enquiry.leadForOngoing ? (
              <Link
                to={`/users/view_user/${enquiry.leadForOngoing.id}`}
              >{`${enquiry.leadForOngoing.firstName} ${enquiry.leadForOngoing.lastName}`}</Link>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className={`d-flex flex-column flex-md-row py-2 px-1  listItem`}>
          <div className=" col-md-5 ">Action Completed By:</div>
          <div>
            {enquiry.actionCompletedBy ? (
              <Link
                to={`/users/view_user/${enquiry.actionCompletedBy.id}`}
              >{`${enquiry.actionCompletedBy.firstName} ${enquiry.actionCompletedBy.lastName}`}</Link>
            ) : (
              ""
            )}
          </div>
        </div>
        <ListItem
          label={"Action Completed On"}
          value={
            enquiry.dateActionCompleted
              ? new Date(enquiry.dateActionCompleted).toLocaleDateString(
                  "en-GB"
                )
              : ""
          }
        />
        <ListItem
          label={"Last Contact"}
          value={
            enquiry.dateOfLastContact
              ? new Date(enquiry.dateOfLastContact).toLocaleDateString("en-GB")
              : ""
          }
        />
      </div>

      <div className="mb-5">
        <h3 className="">Nature Of Query</h3>
        <hr />
        <div className="ms-3">{enquiry.natureOfQuery}</div>
      </div>

      {enquiry.interactions && (
        <InteractionsListAndForm
          interactions={enquiry.interactions}
          enquiryId={enquiry.id}
          setEnquiry={setEnquiry}
        />
      )}
    </div>
  );
}
