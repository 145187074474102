import React from "react"
import { Form } from "react-bootstrap"

export default function FloatingLocalitySelect({
  value,
  setField,
  field,
  required = false,
  classes,
}) {
  return (
    <Form.Group className={`mb-1 ${classes} p-1`}>
      <div className="form-floating">
        <Form.Select
          value={value ?? ""}
          required={required}
          onChange={(e) => setField(field, e.target.value)}
        >
          <option value="Unknown">Unknown</option>
          <option value="Border, Fellside and North Carlisle">
            Border, Fellside and North Carlisle
          </option>
          <option value="Carlisle West">Carlisle West</option>
          <option value="Eden">Eden</option>
          <option value="Fells and Solway">Fells and Solway</option>
          <option value="Furness">Furness</option>
          <option value="Lakes to Sea">Lakes to Sea</option>
          <option value="Petteril">Petteril</option>
          <option value="South Cumberland">South Cumberland</option>
          <option value="South Lakeland">South Lakeland</option>
          <option value="Whitehaven and Coastal">Whitehaven and Coastal</option>
          <option value="Workington Together">Workington Together</option>
        </Form.Select>
        <label>
          Locality/Panel{required && <span className="text-danger">*</span>}
        </label>
      </div>
    </Form.Group>
  )
}
