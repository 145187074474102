export function validateForm(form) {
  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  )

  const emailRegex = new RegExp(".+@.+.[A-Za-z]+$")
  const nameRegex = /^[\w\d\s-']+$/
  const numberRegex = /^\d+$/
  const postCodeRegex = /^[\w\s]+$/
  const addressRegex = /^[\w\s-',]+$/

  const {
    name,
    firstName,
    lastName,
    email,
    password1,
    password2,
    address,
    postCode,
    primaryEmail,
    telephone,
  } = form

  const newErrors = {}

  // name errors
  if (name !== undefined) {
    if (name.length < 3) {
      newErrors.name = "Name is too short"
    } else if (!nameRegex.test(name)) {
      newErrors.name = "Please enter a valid name"
    } else {
      newErrors.name = ""
    }
  }

  // name errors
  if (firstName !== undefined) {
    if (firstName.length < 3) {
      newErrors.firstName = "First name is too short"
    } else if (!nameRegex.test(firstName)) {
      newErrors.firstName = "Please enter a valid name"
    } else {
      newErrors.firstName = ""
    }
  }

  // name errors
  if (lastName !== undefined) {
    if (lastName.length < 3) {
      newErrors.lastName = "First name is too short"
    } else if (!nameRegex.test(lastName)) {
      newErrors.lastName = "Please enter a valid name"
    } else {
      newErrors.lastName = ""
    }
  }

  // email errors
  if (email !== undefined) {
    if (email.length < 3) {
      newErrors.email = "Email is too short"
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Please enter a valid email"
    } else {
      newErrors.email = ""
    }
  }

  if (primaryEmail !== undefined) {
    if (primaryEmail.length < 3) {
      newErrors.primaryEmail = "Email is too short"
    } else if (!emailRegex.test(primaryEmail)) {
      newErrors.primaryEmail = "Please enter a valid email"
    } else {
      newErrors.primaryEmail = ""
    }
  }

  if (password1 !== undefined) {
    if (!strongRegex.test(password1)) {
      newErrors.password1 =
        "Password must be min 8 in length and contain atleast 1 uppercase, lowercase, number and special character (eg $tr0ngPa55w0rd!). "
    } else {
      newErrors.password1 = ""
    }
  }

  if (password2 !== undefined) {
    if (password2 !== password1) {
      newErrors.password2 = "Passwords do not match"
    } else {
      newErrors.password2 = ""
    }
  }

  // address errors
  if (address !== undefined) {
    if (address.length < 3) {
      newErrors.address = "address is too short"
    } else if (!addressRegex.test(address)) {
      newErrors.address = "Please enter a valid address"
    } else {
      newErrors.address = ""
    }
  }

  // address errors
  if (postCode !== undefined) {
    if (postCode.length < 6) {
      newErrors.postCode = "postcode is too short"
    } else if (!postCodeRegex.test(postCode)) {
      newErrors.postCode = "Please enter a valid postcode"
    } else {
      newErrors.postCode = ""
    }
  }

  // Telephone errors
  if (telephone) {
    if (telephone.length < 6) {
      newErrors.telephone = "telephone is too short"
    } else if (!numberRegex.test(telephone)) {
      newErrors.telephone = "Please enter a valid telephone"
    } else {
      newErrors.telephone = ""
    }
  }
  return newErrors
}
