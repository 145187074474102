//React
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
//Fetch
import axios from "axios"
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext"
//Icons
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Notifications
import { toast } from "react-toastify"

export default function DeleteAllUnused({ setTags }) {
  const { user, dispatch } = useAuthContext()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const handleDeleteUnused = async () => {
    setLoading(true)
    try {
      const res = await axios.delete(
        `https://${process.env.REACT_APP_IP}/api/tags`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )

      setTags(res.data)

      toast.success("Tags deleted.")
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "LOGOUT" })
        navigate("../../expired")
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <button
      className="btn btn-danger"
      onClick={handleDeleteUnused}
      disabled={loading}
    >
      {!loading ? (
        "Delete All Unused"
      ) : (
        <div>
          Please wait <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      )}
    </button>
  )
}
