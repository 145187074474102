import React from "react"
import { Form } from "react-bootstrap"

export default function FormControl({
  value,
  type = "text",
  label,
  setField,
  field,
  required = false,
  classes,
}) {
  return (
    <Form.Group className={`mb-1 ${classes} p-1`}>
      <div className="form-floating">
        <input
          required={required}
          placeholder="Enter Name"
          type={type}
          className="form-control"
          value={value ?? ""}
          onChange={(e) => setField(field, e.target.value)}
        />
        <label>
          {label} {required && <span className="text-danger">*</span>}
        </label>
      </div>
    </Form.Group>
  )
}
