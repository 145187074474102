//React
import { React, useState, useEffect } from "react"

import { useNavigate } from "react-router-dom"
//Components

import { FloatingLabel, Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"

// Styles
import "./Timesheet.css"




export default function ExpensesTable({entries, setEntries, nominalCodes, projectCodes, costCodes, departmentCodes, expectedTotal }) {

    const [totalExpenses, setTotalExpenses] = useState(0);
    console.log("expenses: ", entries)

    const handleChange = (index, event) => {
        const values = [...entries];
        values[index][event.target.name] = event.target.value;
        setEntries(values);

        // Check if it's the last row and any field is filled
        if (index === entries.length - 1 && (event.target.value !== '')) {
            setEntries([...entries, { nominalCode: '', projectCode: '', pounds: 0, pence: 0 }]);
        }
    };

    const handleRemoveEntry = (index) => {
        const values = [...entries];
        values.splice(index, 1);
        setEntries(values);
    };

    function pad(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }

    useEffect(() => {
        var countExpenses = 0;
        entries.forEach((entry) => {
            countExpenses += parseFloat(entry.pounds + "." + pad(entry.pence,2));
        });

        setTotalExpenses(countExpenses);


    }, [entries])

    return (
        <div style={{ marginBottom: 10 }}>
            <div className="row">
                <div className="col-2"><p>Nominal</p></div>
                <div className="col-2"><p>Dept No</p></div>
                <div className="col-2"><p>Project</p></div>
                <div className="col-2"><p>Costs Code</p></div>
                <div className="col-2 text-end"><p>£</p></div>
                <div className="col-2 text-end"><p>P</p></div>
            </div>

            {entries.map((entry, index) => (
                <div key={index} className="entry row">
                    <div className="col-2">{entry.nominalCode}</div>
                    <div className="col-2">{entry.departmentCode}</div>
                    <div className="col-2">{entry.projectCode}</div>
                    <div className="col-2">{entry.costCode}</div>
                    <div className="col-2 text-end">{entry.pounds}</div>
                    <div className="col-2 text-end">{pad(entry.pence,2)}</div>
                   
                </div>
            ))}

            <div className="row">
                <div className="col-8 text-end"><p><strong>Total</strong></p></div>
                <div className="col-2 text-end"><p><strong>{totalExpenses.toFixed(2).split('.')[0]}</strong></p></div>
                <div className="col-2 text-end"><p><strong>{totalExpenses.toFixed(2).split('.')[1]}</strong></p></div>
            </div>
            <div className="row">
                <div className={totalExpenses == expectedTotal ? 'match col-8 text-end' : 'mismatch col-8 text-end'}><p>Expected</p></div>
                <div className={totalExpenses == expectedTotal ? 'match col-2 text-end' : 'mismatch col-2 text-end'}><p>{expectedTotal.split('.')[0]}</p></div>
                <div className={totalExpenses == expectedTotal ? 'match col-2 text-end' : 'mismatch col-2 text-end'}><p>{expectedTotal.split('.')[1]}</p></div>
            </div>
        </div>
    )
}
