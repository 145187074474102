import React from "react";
import { Form } from "react-bootstrap";

export default function FloatingGroupTypeSelect({
  value,
  setField,
  field,
    classes,
  required
}) {
  return (
    <Form.Group className={`mb-1 ${classes} p-1`}>
      <div className="form-floating">
        <Form.Select
          value={value ?? ""}
          required
          onChange={(e) => setField(field, e.target.value)}
        >
          <option disabled value="">
            Select group type
          </option>
          <option value="Business">Business</option>
          <option value="CEP">CEP</option>
          <option value="Church">Church</option>
          <option value="Church Hall">Church Hall</option>
          <option value="CLP">CLP</option>
          <option value="Community Asset">Community Asset</option>
          <option value="Community Building">Community Building</option>
          <option value="Covid19">Covid19</option>
          <option value="Good Neighbours">Good Neighbours</option>
          <option value="Individual">Individual</option>
          <option value="Local Authority">Local Authority</option>
          <option value="NP">NP</option>
          <option value="Parish Council">Parish Council</option>
          <option value="Village Hall">Village Hall</option>
          <option value="Other">Other</option>
        </Form.Select>
              <label>Group Type {required && <span className="text-danger">*</span>}</label>
      </div>
    </Form.Group>
  );
}
