//React
import React from "react"
//Styles
import "./ListItem.css"

export default function ListItem({ label, value }) {
  return (
    <div className={`d-flex flex-column flex-md-row py-2 px-1  listItem `}>
      <div className=" col-md-5 ">{label}:</div>
      <div className="col-md-7">{value}</div>
    </div>
  )
}
