//React
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useAuthContext } from "../../../Hooks/useAuthContext"

//Fetch
import axios from "axios"

import FormControl from "../../../Components/Forms/FormControl";

//Bootstrap
import Form from "react-bootstrap/Form"
import FloatingLabel from "react-bootstrap/FloatingLabel"
import InputGroup from 'react-bootstrap/InputGroup';

//Helper
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
//Icons
import { validateForm } from "../../../Helpers/FormValidation"

export default function EditUserForm({
  userDetails,
  setUserDetails,
  setEdit,
  updateUsers,
}) {
  const { user } = useAuthContext()

  const [formErrors, setFormErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)

  const updateUser = (data) => {
    setSubmitting(true)
    axios
      .post(
        `https://${process.env.REACT_APP_IP}/api/user/${userDetails.id}`,
        data,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success("User updated")
          setUserDetails(res.data)
          updateUsers(res.data)
          setEdit(false)
        }
      })
      .catch((err) => {
        console.log(err)
        if (err.response.data === "User Exists") {
          setFormErrors((prev) => {
            return { ...prev, email: "Email already exists" }
          })
        } else {
          toast.error(
            "Oops that didnt work. Please try again. If the issue persists contact the administrator."
          )
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  useEffect(() => {
    const findFormErrors = () => {
      const newErrors = validateForm(userDetails)

      setFormErrors(newErrors)
    }
    findFormErrors()
  }, [userDetails])

  const setField = (field, value) => {
    setUserDetails({
      ...userDetails,
      [field]: value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const user = {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      initials: userDetails.initials,
      email: userDetails.email,
        isAdmin: userDetails.isAdmin,
        targetHours: userDetails.targetHours,
        holidayAllowance: userDetails.holidayAllowance,
      holidayPeriodStartDate: userDetails.holidayPeriodStartDate
    }

    updateUser(user)
  }

  return (
    <Form onSubmit={handleSubmit} className="primaryShadow p-2 p-md-3">
      
          <h3>Personal Details</h3>
          <div className="row">
              <div className="col-5">
                  <Form.Group controlId="validationCustom01">
                      <FloatingLabel label="First Name" className="mb-3">
                          <Form.Control
                              value={userDetails.firstName ?? ""}
                              required
                              type="text"
                              placeholder="Enter Name"
                              isInvalid={formErrors.firstName}
                              isValid={formErrors.firstName === ""}
                              onChange={(e) => setField("firstName", e.target.value)}
                          />
                          <Form.Control.Feedback className="text-danger" type="invalid">
                              {formErrors.firstName}
                          </Form.Control.Feedback>
                      </FloatingLabel>
                  </Form.Group>
              </div>
              <div className="col-5">
                  <Form.Group controlId="validationCustom01">
                      <FloatingLabel label="Last Name" className="mb-3">
                          <Form.Control
                              value={userDetails.lastName ?? ""}
                              required
                              type="text"
                              placeholder="Enter Name"
                              isInvalid={formErrors.lastName}
                              isValid={formErrors.lastName === ""}
                              onChange={(e) => setField("lastName", e.target.value)}
                          />
                          <Form.Control.Feedback className="text-danger" type="invalid">
                              {formErrors.lastName}
                          </Form.Control.Feedback>
                      </FloatingLabel>
                  </Form.Group>
              </div>
              <div className="col-2">
                  <Form.Group controlId="validationCustom01">
                      <FloatingLabel label="Initials" className="mb-3">
                          <Form.Control
                              value={userDetails.initials ?? ""}
                              required
                              type="text"
                              placeholder="Enter Name"
                              isInvalid={formErrors.initials}
                              isValid={formErrors.initials === ""}
                              onChange={(e) => setField("initials", e.target.value)}
                          />
                          <Form.Control.Feedback className="text-danger" type="invalid">
                              {formErrors.initials}
                          </Form.Control.Feedback>
                      </FloatingLabel>
                  </Form.Group>
              </div>
          </div>
          <div className="row">
              <Form.Group>
                  <FloatingLabel label="Email address" className="mb-3">
                      <Form.Control
                          value={userDetails.email ?? ""}
                          required
                          type="email"
                          placeholder="name@example.com"
                          isInvalid={formErrors.email}
                          isValid={formErrors.email === ""}
                          onChange={(e) => setField("email", e.target.value)}
                      />
                      <Form.Control.Feedback className="text-danger" type="invalid">
                          {formErrors.email}
                      </Form.Control.Feedback>
                  </FloatingLabel>
              </Form.Group>
          </div>
          

          {user.isAdmin && (
              <div className="row">
                  <div className="col-6">
                      <Form.Group className="d-flex ">
                          <Form.Label className="me-3">Is Admin</Form.Label>
                          <Form.Check onChange={(e) => setField("isAdmin", e.target.checked)} />
                      </Form.Group>
                  </div>
              </div>
          )}


          <h4 style={{ marginTop: 20 }}>Timesheets</h4>

          <div className="row">
              <div className="col-6">
                  <InputGroup>
                      <FloatingLabel label="Target Hours" className="mb-3">
                          <Form.Control
                              value={userDetails.targetHours ?? ""}
                              required
                              type="text"
                              placeholder="Hours"
                              isInvalid={formErrors.targetHours}
                              isValid={formErrors.targetHours === ""}
                              onChange={(e) => setField("targetHours", e.target.value)}
                          />
                          <Form.Control.Feedback className="text-danger" type="invalid">
                              {formErrors.targetHours}
                          </Form.Control.Feedback>
                      </FloatingLabel>
                      <InputGroup.Text id="basic-addon1" className="mb-3">Hours</InputGroup.Text>
                  </InputGroup>
              </div>
        </div>
          <div className="row">

              <div className="col-6">
                  <InputGroup>
                      <FloatingLabel label="Holiday Allowance" className="mb-3">
                          <Form.Control
                              value={userDetails.holidayAllowance ?? ""}
                              required
                              type="text"
                              placeholder="Hours"
                              isInvalid={formErrors.holidayAllowance}
                              isValid={formErrors.holidayAllowance === ""}
                              onChange={(e) => setField("holidayAllowance", e.target.value)}
                          />
                          <Form.Control.Feedback className="text-danger" type="invalid">
                              {formErrors.holidayAllowance}
                          </Form.Control.Feedback>
                      </FloatingLabel>
                      <InputGroup.Text id="basic-addon1" className="mb-3">Hours</InputGroup.Text>
                  </InputGroup>
              </div>
          </div>
          <div className="row">
              <div className="col-4">

                  
                      <FormControl
                          value={userDetails.holidayPeriodStartDate ? userDetails.holidayPeriodStartDate.split("T")[0] : ""}
                          type={"date"}
                          label={"Holiday Period Start Date"}
                          field={"holidayPeriodStartDate"}
                          setField={setField}
                          classes={"col-md-12"}
                      />
                      <Form.Control.Feedback className="text-danger" type="invalid">
                          {formErrors.holidayPeriodStartDate}
                      </Form.Control.Feedback>
                  

              </div>
          </div>








      <div className="d-flex justify-content-end">
        <button disabled={submitting} type="submit" className="btn btn-primary">
          {!submitting ? (
            "Submit"
          ) : (
            <div>
              Please wait <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          )}
        </button>
      </div>
    </Form>
  )
}
