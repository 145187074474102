//React
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//Fetch
import axios from "axios";
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext";
//Icons
import {
  faAdd,
  faArrowLeft,
    faSpinner,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Notifications
import { toast } from "react-toastify";
//Components
import MainContainer from "../../../Components/Containers/MainContainer";
import Loading from "../../../Components/Loading/Loading";
import CreateEditTimesheetCodeForm from "../Components/CreateEditTimesheetCodeForm";
import { FloatingLabel, Form } from "react-bootstrap";

export default function ManageTimesheetCodes() {
    const navigate = useNavigate();
    const params = useParams();
  const { user, dispatch } = useAuthContext();

  const [timesheetCodes, setTimesheetCodes] = useState([]);
  const [selectedTimesheetCode, setSelectedTimesheetCode] = useState({});

  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deactivateLoading, setDeactivateLoading] = useState(false);
  const [error, setError] = useState(false);
  const [type, setType] = useState("TimesheetCode");
  const [showInactiveCodes, setShowInactiveCodes] = useState(false);

  useEffect(() => {
    const listTimesheetCodes = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/timesheetcodes/${type}?active=${!showInactiveCodes}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        );

        setTimesheetCodes(res.data);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch({ type: "LOGOUT" });
          navigate("../../expired");
        } else {
          setError(error.response.status);
        }
      } finally {
        setLoading(false);
      }
    };

    listTimesheetCodes();
  }, [dispatch, user, navigate, type, showInactiveCodes]);



  const handleStatusUpdate = async (id, activate) => {
    setDeactivateLoading(true);
    try {
      const res = await axios.post(
        `https://${process.env.REACT_APP_IP}/api/timesheetcodes/status/${id}?active=${activate}`,
        {},
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      );

      var timesheetcodes = timesheetCodes.filter((timesheetCode) => {
        return Number(timesheetCode.id) !== Number(id);
      });

      setTimesheetCodes([...timesheetcodes, res.data]);

      toast.success("Timesheet code status updated.");
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "LOGOUT" });
        navigate("../../expired");
      } else {
        setError(error.response.status);
      }
    } finally {
      setDeactivateLoading(false);
    }
  };

  const handleEditTimesheetCode = (timesheetCode) => {
    setSelectedTimesheetCode(timesheetCode);
    setShowForm(true);
  };

  if (!loading) {
    return error ? (
      <MainContainer>Some error...</MainContainer>
    ) : (
      <MainContainer>
        <div className="d-flex justify-content-end mb-2">
          <button
            className="btn btn-primary my-2 "
            onClick={() => navigate(-1)}
          >
            Back <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        </div>
        <div className="primaryShadow p-3">
            <div className=" row justify-content-between">
                <div className="col-6">
                    <h3 className="mb-3">Timesheet / Expense Codes</h3><br/>
                </div>
                <div className="col-6 text-end">
                  {!showForm ? (
                    <button className="btn btn-primary" onClick={() => setShowForm(true)}>
                      <FontAwesomeIcon icon={faAdd} />
                    </button>
                  ) : (
                        <button className="btn btn-danger" onClick={() => {
                            setShowForm(false);
                            setSelectedTimesheetCode({});
                        }}>Cancel</button>
                  )}
               </div>
            </div>
         
                <div className="row">
                    <div className="col-12">
                            {!showForm && (
                            <div>
                            <FloatingLabel label={"Type"}>
                                <Form.Select value={type} onChange={(e) => setType(e.target.value)}>
                                    <option disabled value="">
                                        Select...
                                    </option>
                                    <option value="TimesheetCode" selected>Timesheet Code</option>
                                    <option value="CostCode">Cost Code</option>
                                    <option value="NominalCode">Nominal Code</option>
                                    <option value="DepartmentCode">Department Code</option>
                                </Form.Select>
                                </FloatingLabel>

                                <label>
                                        <input type="checkbox" onClick={(e) => { console.log("clicked", e.target.checked); setShowInactiveCodes(e.target.checked) }} />
                                    Show Inactive Codes
                                </label>
                            </div>
                        )}
                    </div>
                </div>
                    <div>
                    </div>
          <hr />
          {!showForm && (
            <div className="col ">
              <div>
                {timesheetCodes.length > 0 ? (
                  <div>
                    {timesheetCodes.map((timesheetCode) => (
                        <div key={timesheetCode.id} className="innerCon p-3 mb-2" style={{ backgroundColor: timesheetCode.active ? "" : "indianred" }}>
                            <div className={`d-flex flex-md-row justify-content-between  px-1 `} >
                          <div className=" d-flex align-items-center ">
                            {timesheetCode.code} - {timesheetCode.name}
                          </div>

                          <div className="d-flex justify-content-end ">
                            <button
                              className="btn btn-primary  me-2"
                              onClick={() =>
                                handleEditTimesheetCode(timesheetCode)
                              }
                            >
                              Edit
                            </button>
                            <button
                              className={
                                timesheetCode.active
                                  ? "btn btn-danger"
                                  : "btn btn-success"
                              }
                              onClick={() =>
                                handleStatusUpdate(
                                  timesheetCode.id,
                                  !timesheetCode.active
                                )
                              }
                              disabled={deactivateLoading}
                            >
                              {!deactivateLoading ? (
                                timesheetCode.active ? (
                                  <FontAwesomeIcon icon={faTrash} />
                                ) : (
                                  "Re-Activate"
                                )
                              ) : (
                                <div>
                                  Please wait
                                  <FontAwesomeIcon icon={faSpinner} spin />
                                </div>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>No timesheet codes created yet.</div>
                )}
              </div>
            </div>
          )}
          {showForm && (
            <CreateEditTimesheetCodeForm
                timesheetCode={selectedTimesheetCode}
                setTimesheetCodes={setTimesheetCodes}
                timesheetCodes={timesheetCodes}
                setShowForm={setShowForm}
                setSelectedTimesheetCode={setSelectedTimesheetCode}
                type={type}
            />
          )}
        </div>
      </MainContainer>
    );
  } else {
    return (
      <MainContainer>
        <Loading />
      </MainContainer>
    );
  }
}
