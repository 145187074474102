//React
import React from "react"
//Components
import InnerConItem from "../../../Components/InnerConItem"

export default function EventList({ events }) {
  return (
    <>
      <div className="mb-3">
        <h3>Events</h3>
        <hr />
        <div>
          {events.length > 0 ? (
            <div
              className={`d-flex flex-column flex-md-row flex-md-wrap gap-3  p-2`}
            >
              {events.map((event) => (
                <InnerConItem
                  type={"event"}
                  key={event.id}
                  value={event.title}
                  url={`../../events/view_event/${event.id}`}
                />
              ))}
            </div>
          ) : (
            <div className=" text-center">No events found.</div>
          )}
        </div>
      </div>
    </>
  )
}
