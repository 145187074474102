
import axios from "axios";
//React
import { React, useState, useEffect } from "react"
import { toast } from "react-toastify"
import { useInView } from "react-cool-inview"
import { useAuthContext } from "../../../Hooks/useAuthContext"
import { useNavigate, useParams } from "react-router-dom";
//Icons
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

//Components
import MainContainer from "../../../Components/Containers/MainContainer"
import SideContainer from "../../../Components/Containers/SideContainer"

//Components
import FormCheck from "../../../Components/Forms/FormCheck"
import FormControl from "../../../Components/Forms/FormControl"
//Bootstrap




//Styles
import "./Dashboard.css"
import TimesheetDay from "../Components/TimesheetDay"
import ExpensesTable from "../Components/ExpensesTable"


export default function Dashboard({ setRefresh }) {

    const params = useParams();
    const navigate = useNavigate()
    const { user, dispatch } = useAuthContext();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState();
    const [hoursDifference, setHoursDifference] = useState(0);

    
    const [weekCommencing, setWeekCommencing] = useState(startOfWeek(new Date()));
    const [weekCommencing2, setWeekCommencing2] = useState(startOfWeek(new Date()).toISOString("en-GB").split('T')[0]);



    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    const [mondayEntries, setMondayEntries] = useState([{ date: weekCommencing.addDays(0).toISOString().split('T')[0], task: '', timesheetCode: '', hours: 0, miles: 0, expenses: 0 }]);
    const [tuesdayEntries, setTuesdayEntries] = useState([{ date: weekCommencing.addDays(1).toISOString().split('T')[0], task: '', timesheetCode: '', hours: 0, miles: 0, expenses: 0 }]);
    const [wednesdayEntries, setWednesdayEntries] = useState([{ date: weekCommencing.addDays(2).toISOString().split('T')[0], task: '', timesheetCode: '', hours: 0, miles: 0, expenses: 0 }]);
    const [thursdayEntries, setThursdayEntries] = useState([{ date: weekCommencing.addDays(3).toISOString().split('T')[0], task: '', timesheetCode: '', hours: 0, miles: 0, expenses: 0 }]);
    const [fridayEntries, setFridayEntries] = useState([{ date: weekCommencing.addDays(4).toISOString().split('T')[0], task: '', timesheetCode: '', hours: 0, miles: 0, expenses: 0 }]);
    const [weekendEntries, setWeekendEntries] = useState([{ date: weekCommencing.addDays(5).toISOString().split('T')[0], task: '', timesheetCode: '', hours: 0, miles: 0, expenses: 0 }]);

    const [expensesEntries, setExpensesEntries] = useState([{ nominalCode: '', projectCode: '', costCode: '', departmentCode: '', pounds: 0, pence: 0 }]);
    


    const [timesheetCodes, setTimesheetCodes] = useState([]);
    const [nominalCodes, setNominalCodes] = useState([]);
    const [departmentCodes, setDepartmentCodes] = useState([]);
    const [costCodes, setCostCodes] = useState([]);
   


    const [submitting, setSubmitting] = useState(false);


    const [totalHours, setTotalHours] = useState(0);
    const [totalMiles, setTotalMiles] = useState(0);
    const [totalMileageExpenses, setMileageExpenses] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [totalByTimesheetCode, setTotalByTimesheetCode] = useState([]);


    const [timesheetFoundId, setTimesheetFoundId] = useState();

    const handleChangeDate = (e) => {
        console.log(e.target.value)
        var date = new Date(e.target.value)
        console.log(date)
        if (!isNaN(date) && date.getFullYear() > 2020) {
            console.log("date valid")
            setWeekCommencing(date);

        }
        else {
            console.log("invalid date")
        }
       console.log(e.target.value)
    };

    // Calculate hours/miles/expenses total
    useEffect(() => {

        var countHours = 0;
        var countMiles = 0;
        var countExpenses = 0;

        mondayEntries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        tuesdayEntries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        wednesdayEntries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        thursdayEntries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        fridayEntries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        weekendEntries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        setTotalHours(countHours);
        setTotalMiles(countMiles);
        setMileageExpenses(countMiles * 0.45);
        setTotalExpenses(countExpenses);

    }, [mondayEntries, tuesdayEntries, wednesdayEntries, thursdayEntries, fridayEntries, weekendEntries])


    // Generate timesheet code summary table
    useEffect(() => {
        let timesheetCodes = [];

        mondayEntries.map((entry) => {
            if (entry.timesheetCode != "") {
                var existing = timesheetCodes.find((x) => x.code == entry.timesheetCode);
                if (existing != undefined)
                    existing.hours += parseFloat(entry.hours);
                else
                    timesheetCodes.push({ code: entry.timesheetCode, hours: parseFloat(entry.hours) });
            }
        });
        tuesdayEntries.map((entry) => {
            if (entry.timesheetCode != "") {
                var existing = timesheetCodes.find((x) => x.code == entry.timesheetCode);
                if (existing != undefined)
                    existing.hours += parseFloat(entry.hours);
                else
                    timesheetCodes.push({ code: entry.timesheetCode, hours: parseFloat(entry.hours) });
            }
        });
        wednesdayEntries.map((entry) => {
            if (entry.timesheetCode != "") {
                var existing = timesheetCodes.find((x) => x.code == entry.timesheetCode);
                if (existing != undefined)
                    existing.hours += parseFloat(entry.hours);
                else
                    timesheetCodes.push({ code: entry.timesheetCode, hours: parseFloat(entry.hours) });
            }
        });
        thursdayEntries.map((entry) => {
            if (entry.timesheetCode != "") {
                var existing = timesheetCodes.find((x) => x.code == entry.timesheetCode);
                if (existing != undefined)
                    existing.hours += parseFloat(entry.hours);
                else
                    timesheetCodes.push({ code: entry.timesheetCode, hours: parseFloat(entry.hours) });
            }
        });
        fridayEntries.map((entry) => {
            if (entry.timesheetCode != "") {
                var existing = timesheetCodes.find((x) => x.code == entry.timesheetCode);
                if (existing != undefined)
                    existing.hours += parseFloat(entry.hours);
                else
                    timesheetCodes.push({ code: entry.timesheetCode, hours: parseFloat(entry.hours) });
            }
        });
        weekendEntries.map((entry) => {
            if (entry.timesheetCode != "") {
                var existing = timesheetCodes.find((x) => x.code == entry.timesheetCode);
                if (existing != undefined)
                    existing.hours += parseFloat(entry.hours);
                else
                    timesheetCodes.push({ code: entry.timesheetCode, hours: parseFloat(entry.hours) });
            }
        });

        setTotalByTimesheetCode(timesheetCodes)
    }, [mondayEntries, tuesdayEntries, wednesdayEntries, thursdayEntries, fridayEntries, weekendEntries])

    // check if a timesheet with this end date already exists
    useEffect(() => {
        setTimesheetFoundId();
        axios.get(`https://${process.env.REACT_APP_IP}/api/timesheets/getbydate?weekCommencing=${weekCommencing.toISOString().split("T")[0]}`,
            {
                headers: { Authorization: `bearer ${user.token}` },
            }
        )
        .then((response) => {
            console.log("timesheet id:", response.data.id)
            setTimesheetFoundId(response.data.id)
            console.log("timesheet found")
                
        }).catch(() => {
            setTimesheetFoundId();
            console.log("timesheet NOT found")
        });

        // clear the entries - weekcommencing has changed
        setMondayEntries([{ date: weekCommencing.addDays(0).toISOString().split('T')[0], task: '', timesheetCode: '', hours: 0, miles: 0, expenses: 0 }]);
        setTuesdayEntries([{ date: weekCommencing.addDays(1).toISOString().split('T')[0], task: '', timesheetCode: '', hours: 0, miles: 0, expenses: 0 }]);
        setWednesdayEntries([{ date: weekCommencing.addDays(2).toISOString().split('T')[0], task: '', timesheetCode: '', hours: 0, miles: 0, expenses: 0 }]);
        setThursdayEntries([{ date: weekCommencing.addDays(3).toISOString().split('T')[0], task: '', timesheetCode: '', hours: 0, miles: 0, expenses: 0 }]);
        setFridayEntries([{ date: weekCommencing.addDays(4).toISOString().split('T')[0], task: '', timesheetCode: '', hours: 0, miles: 0, expenses: 0 }]);
        setWeekendEntries([{ date: weekCommencing.addDays(5).toISOString().split('T')[0], task: '', timesheetCode: '', hours: 0, miles: 0, expenses: 0 }]);


        getInteractions();

    }, [weekCommencing])


    // load all the codes
    // Get any interactions
    // get the users hours used so far
    useEffect(() => {
        
        axios.get(`https://${process.env.REACT_APP_IP}/api/timesheetcodes/CostCode?active=true`,
            {
                headers: { Authorization: `bearer ${user.token}` },
            }
        )
            .then((response) => {
                setCostCodes(response.data)
            });
        axios.get(`https://${process.env.REACT_APP_IP}/api/timesheetcodes/NominalCode?active=true`,
            {
                headers: { Authorization: `bearer ${user.token}` },
            }
        )
        .then((response) => {
            setNominalCodes(response.data)
        });
        axios.get(`https://${process.env.REACT_APP_IP}/api/timesheetcodes/DepartmentCode?active=true`,
            {
                headers: { Authorization: `bearer ${user.token}` },
            }
        )
            .then((response) => {
                setDepartmentCodes(response.data)
            });
        axios.get(`https://${process.env.REACT_APP_IP}/api/timesheetcodes/TimesheetCode?active=true`,
            {
                headers: { Authorization: `bearer ${user.token}` },
            }
        )
        .then((response) => {
            setTimesheetCodes(response.data)
        });


        axios.get(`https://${process.env.REACT_APP_IP}/api/user/${user.id}`,
            {
                headers: { Authorization: `bearer ${user.token}` },
            }
        )
        .then((response) => {
            setCurrentUser(response.data)
        });

        axios.get(`https://${process.env.REACT_APP_IP}/api/timesheets/stats`,
            {
                headers: { Authorization: `bearer ${user.token}` },
            }
        )
            .then((response) => {
                setHoursDifference(response.data.hoursDifference)
            });


    }, []);


    function getInteractions() {
        axios.get(`https://${process.env.REACT_APP_IP}/api/interactions?handledById=${user.id}&startDate=${weekCommencing.toISOString().split("T")[0]}&endDate=${weekCommencing.addDays(6).toISOString().split("T")[0]}&accepted=true&pageSize=100`,
            {
                headers: { Authorization: `bearer ${user.token}` },
            }
        )
            .then((response) => {
                
                const monday = weekCommencing.toISOString().split("T")[0];
                const tuesday = weekCommencing.addDays(1).toISOString().split("T")[0];
                const wednesday = weekCommencing.addDays(2).toISOString().split("T")[0];
                const thursday = weekCommencing.addDays(3).toISOString().split("T")[0];
                const friday = weekCommencing.addDays(4).toISOString().split("T")[0];
                const saturday = weekCommencing.addDays(5).toISOString().split("T")[0];
                const sunday = weekCommencing.addDays(6).toISOString().split("T")[0];


                response.data.interactions.map((interaction) => {
                    console.log("INTERACTION: ", interaction)

                    switch (interaction.dateAndTime.split("T")[0]) {
                        case monday:
                            console.log("monday entry found")
                            setMondayEntries(prevEntries => [...prevEntries, {
                                date: monday, task: interaction.title, timesheetCode: interaction.timesheetCode, hours: interaction.timeSpent, miles: 0, expenses: 0, interactionId: interaction.id, enquiryId: interaction.enquiry.id }]);
                            break;
                        case tuesday:
                            setTuesdayEntries(prevEntries => [...prevEntries, { date: tuesday, task: interaction.title, timesheetCode: interaction.timesheetCode, hours: interaction.timeSpent, miles: 0, expenses: 0, interactionId: interaction.id, enquiryId: interaction.enquiry.id }]);
                            break;
                        case wednesday:
                            setWednesdayEntries(prevEntries => [...prevEntries, { date: wednesday, task: interaction.title, timesheetCode: interaction.timesheetCode, hours: interaction.timeSpent, miles: 0, expenses: 0, interactionId: interaction.id, enquiryId: interaction.enquiry.id }]);
                            break;
                        case thursday:
                            setThursdayEntries(prevEntries => [...prevEntries, { date: thursday, task: interaction.title, timesheetCode: interaction.timesheetCode, hours: interaction.timeSpent, miles: 0, expenses: 0, interactionId: interaction.id, enquiryId: interaction.enquiry.id }]);
                            break;
                        case friday:
                            setFridayEntries(prevEntries => [...prevEntries, { date: friday, task: interaction.title, timesheetCode: interaction.timesheetCode, hours: interaction.timeSpent, miles: 0, expenses: 0, interactionId: interaction.id, enquiryId: interaction.enquiry.id }]);
                            break;
                        case saturday:
                            setWeekendEntries(prevEntries => [...prevEntries, { date: saturday, task: interaction.title, timesheetCode: interaction.timesheetCode, hours: interaction.timeSpent, miles: 0, expenses: 0, interactionId: interaction.id, enquiryId: interaction.enquiry.id }]);
                            break;
                        case sunday:
                            setWeekendEntries(prevEntries => [...prevEntries, { date: sunday, task: interaction.title, timesheetCode: interaction.timesheetCode, hours: interaction.timeSpent, miles: 0, expenses: 0, interactionId: interaction.id, enquiryId: interaction.enquiry.id }]);
                            break;
                    }
                });
                console.log("Monday entries: ", mondayEntries)
            });
    }


    const handleSubmit = (event) => {
        event.preventDefault();

        axios
            .post(`https://${process.env.REACT_APP_IP}/api/timesheets`,
                {
                    WeekCommencing: weekCommencing.toISOString().split('T')[0],
                    Monday: mondayEntries,
                    Tuesday: tuesdayEntries,
                    Wednesday: wednesdayEntries,
                    Thursday: thursdayEntries,
                    Friday: fridayEntries,
                    Weekend: weekendEntries,
                    Expenses: expensesEntries
                },
                {
                    headers: { Authorization: `bearer ${user.token}` },
                }
                
            )
            .then((response) => {
                toast.success("Timesheet saved.")
               console.log(response)
                navigate(`../view/${response.data.id}`)

                setRefresh(true);
            }).catch((error) => {
                toast.error(JSON.stringify(error.response.data.errors));
                return Promise.reject(error)
            }
            );
    };

    function startOfWeek(date) {
        // Calculate the difference between the date's day of the month and its day of the week
        var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

        // Set the date to the start of the week by setting it to the calculated difference
        return new Date(date.setDate(diff));
    }

    


    

    return (
        <div>
            <SideContainer classes={"d-xl-none"}>

            </SideContainer>
            <MainContainer classes={"d-flex flex-column  p-2"}>

                <div className="row">
                    <div className="col-10">
                        <h4 className="my-2">Create Timesheet - Week Commencing {weekCommencing.toLocaleDateString("en-GB")}</h4>
                    </div>
                    <div className="col-2">
                        <button
                            className="btn btn-primary "
                            onClick={() => navigate('/timesheets')}>Back <FontAwesomeIcon icon={faArrowLeft} />{" "}
                        </button>
                    </div>
                </div>
               
                   
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-10">
                            <input
                                type="date"
                                name="weekCommencing2"
                                placeholder="Week Commencing"
                                defaultValue={weekCommencing2}
                                onChange={handleChangeDate}
                            />
                        </div>
                        <div className="col-2">
                            <button type="submit" disabled={timesheetFoundId > 0} className="btn btn-primary my-2" style={{ marginRight: 20 }}>Save</button>
                        </div>
                    </div>


                    



                   

                    {timesheetFoundId > 0  ? (
                        <div class="row">
                            <div className="alert-warning" style={{marginTop: 40} }>
                                <strong>Timesheet Found</strong>
                                <p>A timesheet with week commencing {weekCommencing.toLocaleString().split(",")[0]} already exists. Please edit the existing timesheet.</p></div>
                                <button className="btn btn-primary" onClick={() => navigate(`/timesheets/edit/${timesheetFoundId}`)}>Edit Existing Timesheet</button>
                        </div>
                    ) : (
                        <>
                        <TimesheetDay day="Monday" entries={mondayEntries} setEntries={setMondayEntries} timesheetCodes={timesheetCodes} date={weekCommencing.addDays(0)} />
                        <TimesheetDay day="Tuesday" entries={tuesdayEntries} setEntries={setTuesdayEntries} timesheetCodes={timesheetCodes} date={weekCommencing.addDays(1)} />
                        <TimesheetDay day="Wednesday" entries={wednesdayEntries} setEntries={setWednesdayEntries} timesheetCodes={timesheetCodes} date={weekCommencing.addDays(2)} />
                        <TimesheetDay day="Thursday" entries={thursdayEntries} setEntries={setThursdayEntries} timesheetCodes={timesheetCodes} date={weekCommencing.addDays(3)} />
                        <TimesheetDay day="Friday" entries={fridayEntries} setEntries={setFridayEntries} timesheetCodes={timesheetCodes} date={weekCommencing.addDays(4)} />
                        <TimesheetDay day="Saturday / Sunday" entries={weekendEntries} setEntries={setWeekendEntries} timesheetCodes={timesheetCodes} date={weekCommencing.addDays(5)} />
                    

                        <div className="row">
                            <div className="col-6"><p><strong>Weekly Target: {currentUser && currentUser.targetHours}</strong></p></div>
                            <div className="col-2 text-end"><p><strong>Total</strong></p></div>
                            <div className={currentUser && totalHours >= currentUser.targetHours ? "match col-1 text-end" : "mismatch col-1 text-end"}><p><strong>{totalHours} Hrs</strong></p></div>
                            <div className="col-1 text-end"><p><strong>{totalMiles} Miles</strong></p></div>
                            <div className="col-1 text-end"><p><strong>£{totalExpenses.toFixed(2)}</strong></p></div>
                            <div className="col-1"></div>
                        </div>
                        <div className="row">
                            <div className="col-7"><p><strong>Hours Over/Under This Period: {hoursDifference} Hrs</strong></p></div>
                            <div className="col-3 text-end"><p><strong>Mileage @ £0.45p per mile</strong></p></div>
                            <div className="col-1 text-end"><p><strong>£{totalMileageExpenses.toFixed(2)}</strong></p></div>
                            <div className="col-1"></div>
                        </div>
                        <div className="row">
                            <div className="col-7"><p></p></div>
                            <div className="col-3 text-end"><p><strong>Total Expenses</strong></p></div>
                            <div className="col-1 text-end"><p><strong>£{totalExpenses.toFixed(2)}</strong></p></div>
                            <div className="col-1"></div>
                        </div>
                        <div className="row">
                            <div className="col-7"><p></p></div>
                            <div className="col-3 text-end"><p><strong>Total Mileage & Expenses</strong></p></div>
                            <div className="col-1 text-end"><p><strong>£{(totalMileageExpenses + totalExpenses).toFixed(2)}</strong></p></div>
                            <div className="col-1"></div>
                        </div>

                                <div className="row">
                                    <div className="col-12">
                                        {/* Expenses Table */}
                                        <h4>Expenses</h4>
                                        <ExpensesTable
                                            entries={expensesEntries}
                                            setEntries={setExpensesEntries}
                                            nominalCodes={nominalCodes}
                                            departmentCodes={departmentCodes}
                                            projectCodes={timesheetCodes}
                                            costCodes={costCodes}
                                            expectedTotal={(totalExpenses + totalMileageExpenses).toFixed(2)} />
                                    </div>
                                </div>

                        <div className="row" style={{ marginTop: 20 }}>
                            <div className="col-6">
                                <h4>Timesheet Codes</h4>

                                {/* Timesheet Code Summary */}
                                <div className="row">
                                    <div className="col-6">Timesheet Code</div>
                                    <div className="col-6">Hours</div>
                                </div>
                                {totalByTimesheetCode.map((entry, index) => (
                                    <div key={index} className="row">
                                        <div className="col-6">{entry.code}</div>
                                        <div className="col-6">{entry.hours}</div>
                                    </div>
                                ))}
                            </div>

                            

                        </div>
                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary text-end">Save</button>
                                </div>
                        </>
                    )}
                </form>
                


            </MainContainer>
        </div>
    )
}
