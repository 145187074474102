//React
import React, { useState } from "react"

//Bootstrap
import { FloatingLabel, Form } from "react-bootstrap"

//Components
import FloatingParishSelect from "../../../Components/SelectDropdowns/FloatingParishSelect"
import FormControl from "../../../Components/Forms/FormControl"
import FloatingUnitarySelect from "../../../Components/SelectDropdowns/FloatingUnitarySelect"
import FloatingInitialSource from "../../../Components/SelectDropdowns/FloatingInitialSource"

export default function QuickEnquiryForm({
  userList,
  form,
  setForm,
  setEnquiryComplete,
}) {
  const [enquiryForm, setEnquiryForm] = useState({})

  const setField = (field, value) => {
    setEnquiryForm({
      ...enquiryForm,
      [field]: value,
    })
  }

  const handleNext = () => {
    setEnquiryComplete(true)
    setForm({
      ...form,
      enquiry: enquiryForm,
    })
  }

  return (
    <Form onSubmit={handleNext}>
      <h4 className="ps-2">Or create new enquiry</h4>
      {/* <p>*Additional details can be added later.</p> */}
      <hr />

      <div className="d-md-flex">
        <FormControl
          value={enquiryForm.title}
          label={"Title"}
          field={"title"}
          setField={setField}
          classes={"col"}
          required
        />
      </div>

      <div className="d-md-flex">
        <FormControl
          value={enquiryForm.groupName}
          label={"Group Name"}
          field={"groupName"}
          setField={setField}
          classes={"col-md-6"}
          required
        />
        <FormControl
          value={enquiryForm.groupType}
          label={"Group Type"}
          field={"groupType"}
          setField={setField}
          classes={"col-md-6"}
        />
      </div>
      <div className="d-md-flex">
        <FloatingParishSelect
          value={enquiryForm.parish}
          field={"parish"}
          setField={setField}
          classes={"col-md-6"}
        />
        <FloatingUnitarySelect
          value={enquiryForm.unitaryAuthority}
          field={"unitaryAuthority"}
          setField={setField}
          classes={"col-md-6"}
        />
      </div>
      <div className="d-md-flex">
        <FormControl
          value={
            enquiryForm.dateOfQuery ? enquiryForm.dateOfQuery.split("T")[0] : ""
          }
          type={"date"}
          label={"Query Received"}
          field={"dateOfQuery"}
          setField={setField}
          classes={"col-md-3"}
          required
        />
        <Form.Group className="mb-1 p-1 col-md-3">
          <div className="form-floating">
            <Form.Select
              value={enquiryForm.queryTakenById ?? ""}
              required
              onChange={(e) => setField("queryTakenById", e.target.value)}
            >
              <option disabled value="">
                Select user...
              </option>
              {userList.map((u) => (
                <option key={u.id} value={u.id}>
                  {u.firstName} {u.lastName}
                </option>
              ))}
            </Form.Select>
            <label>
              Query Taken by <span className="text-danger">*</span>
            </label>
          </div>
        </Form.Group>

        <FloatingInitialSource
          classes={"col-md-3"}
          setField={setField}
          form={enquiryForm}
        />

        <Form.Group className="mb-1 p-1 col-md-3">
          <div className="form-floating">
            <Form.Select
              value={enquiryForm.leadForOngoingId ?? ""}
              onChange={(e) => setField("leadForOngoingId", e.target.value)}
            >
              <option disabled value="">
                Select user...
              </option>
              {userList.map((u) => (
                <option key={u.id} value={u.id}>
                  {u.firstName} {u.lastName}
                </option>
              ))}
            </Form.Select>
            <label>
              Lead For Future <span className="text-danger">*</span>
            </label>
          </div>
        </Form.Group>
      </div>
      <div>
        <Form.Group className={`mb-1  p-1`}>
          <div className="form-floating">
            <Form.Control
              as="textarea"
              rows={5}
              value={enquiryForm.natureOfQuery ?? ""}
              placeholder="Client name"
              onChange={(e) => setField("natureOfQuery", e.target.value)}
              style={{ minHeight: "120px" }}
              required
            />

            <label>
              Nature Of Query <span className="text-danger">*</span>
            </label>
          </div>
        </Form.Group>
      </div>
      <div className="d-flex justify-content-end">
        <button className="btn btn-primary me-2" type="submit">
          Next
        </button>
      </div>
    </Form>
  )
}
