//React
import { React, useState, useEffect } from "react"
import { useInView } from "react-cool-inview"
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "../../../Hooks/useAuthContext"
//Icons
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Components
import MainContainer from "../../../Components/Containers/MainContainer"
import SideContainer from "../../../Components/Containers/SideContainer"
import axios from "axios";

//Styles
import "./Dashboard.css"

export default function Dashboard({
    
}) {
    const navigate = useNavigate()
    const { user } = useAuthContext()
    const { observe } = useInView({
        onEnter: () => {
            
        },
    })

    const [timesheetStats, setTimesheetStats] = useState([]); 
    const [loaded, setLoaded] = useState(false);
    const [totalHours, setTotalHours] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState(0);

    useEffect(() => {
        axios.get(
            `https://${process.env.REACT_APP_IP}/api/timesheets/stats`,
            {
                headers: { Authorization: `bearer ${user.token}` },
            }
        ).then((response) => {
            console.log("Response: ", response.data)
            setTimesheetStats(response.data);
            setLoaded(true);

            let total = 0;
            response.data.timesheetCodeTotals.map((entry) => {
                total += entry.hours
            });

            setTotalHours(total);

            total = 0;
            response.data.expensesTotals.map((entry) => {
                total += entry.cost
            });

            setTotalExpenses(total);
        });

    }, [])

   


    return (
        <div>

            <MainContainer classes={""}>

                    {loaded ? (
                    <div>
                        <h2>Period: {new Date(timesheetStats.periodStartDate).toLocaleDateString("en-GB")}</h2>
                        <div className="row">
                            {/* Hours (This Month) */ }
                            <div className="col-6">
                                <div className="row mb-3">
                                    <div className="col-12 text-center"><h3>Hours (This Period)</h3></div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-4">
                                        <h4>Target</h4>
                                        <h2 className="innerCon  primary text-center">{timesheetStats.targetHours}</h2>
                                    </div>
                                    <div className="col-4">
                                        <h4>Recorded</h4>
                                        <h2 className={timesheetStats.hoursRecorded >= timesheetStats.targetHours ? "innerCon text-center success" : "innerCon text-center fail" }  >{timesheetStats.hoursRecorded}</h2>

                                    </div>
                                    <div className="col-4">
                                        <h4>Difference</h4>
                                        <h2 className={timesheetStats.hoursDifference >= 0 ? "innerCon  text-center success" : "innerCon  text-center fail"}  >{timesheetStats.hoursDifference}</h2>

                                    </div>
                                </div>
                            </div>

                            {/* Holidays (This Period) */}
                            <div className="col-6 ">
                                <div className="row mb-3">
                                    <div className="col-12 text-center"><h3>Holidays (This Period)</h3></div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-4">
                                        <h4>Allowance</h4>
                                        <h2 className="innerCon primary text-center">{timesheetStats.holidayAllowance}</h2>
                                    </div>
                                    <div className="col-4">
                                        <h4>Used</h4>
                                        <h2 className="innerCon primary text-center">{timesheetStats.holidaysUsed}</h2>
                                    </div>
                                    <div className="col-4">
                                        <h4>Remaining</h4>
                                        <h2 className={timesheetStats.holidaysRemaining > 0 ? "innerCon  text-center primary" : "innerCon  text-center fail"} >{timesheetStats.holidaysRemaining}</h2>

                                    </div>
                                </div>
                            </div>
                        </div>







                        <div className="row mt-4">
                            {/* Total Hours */}
                            <div className="col-6">
                                <div className="row ">
                                    <h3>Total Hours (Current Month)</h3>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-8">
                                                Timesheet Code
                                            </div>
                                            <div className="col-1">
                                                Hours
                                            </div>
                                        </div>
                                           

                                        {timesheetStats.timesheetCodeTotals.length > 0 ? timesheetStats.timesheetCodeTotals.map((entry) => (
                                                <div className="row">
                                                    <div className="col-8">
                                                        {entry.timesheetCode}
                                                    </div>
                                                    <div className="col-1">
                                                        {entry.hours}
                                                    </div>
                                                </div>
                                            )
                                        ) : (<div>No Timesheets Found</div>)}

                                        <div className="row" style={{borderTop: "1px solid #000"} }>
                                            <div className="col-8">
                                                <strong>Total</strong>
                                            </div>
                                            <div className="col-1">
                                                <strong>{totalHours}</strong>
                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>
                            </div>
                            {/* Total Expenses */}
                            <div className="col-6">
                                <div className="row">
                                    <h3>Total Expenses (Current Month)</h3>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-2">
                                                Nominal
                                            </div>
                                            <div className="col-2">
                                                Dept No
                                            </div>
                                            <div className="col-2">
                                                Project
                                            </div>
                                            <div className="col-2">
                                                Costs Code
                                            </div>
                                            <div className="col-2">
                                                £
                                            </div>
                                        </div>

                                        {timesheetStats.expensesTotals.length > 0 ? timesheetStats.expensesTotals.map((entry) => (
                                            <div className="row">
                                                <div className="col-2">
                                                    {entry.nominalCode}
                                                </div>
                                                <div className="col-2">
                                                    {entry.departmentCode}
                                                </div>
                                                <div className="col-2">
                                                    {entry.projectCode}
                                                </div>
                                                <div className="col-2">
                                                    {entry.costCode}
                                                </div>
                                                <div className="col-2">
                                                    {entry.cost}
                                                </div>
                                            </div>
                                        )
                                        ) : (<div>No Expenses Found</div>)}


                                        <div className="row" style={{ borderTop: "1px solid #000" }}>
                                            <div className="col-8">
                                                <strong>Total</strong>
                                            </div>
                                            <div className="col-1">
                                                <strong>{totalExpenses}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    ) : (<div>Loading</div>)}
            </MainContainer>
        </div>
    )
}
