//React
import React, { useEffect, useRef, useState } from "react";
//Fetch
import axios from "axios";
//Context
import { useAuthContext } from "../Hooks/useAuthContext";
//Icons
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Notifications
import { toast } from "react-toastify";
//Components
import { CSVLink } from "react-csv";

export default function ExportCsv({ count, url, filename }) {
  const { user } = useAuthContext();
  const [csvData, setCsvData] = useState({ results: [], headers: [] });
  const [csvLoading, setCsvLoading] = useState(false);

  const csvLink = useRef();

  const exportToCSV = async () => {
    setCsvLoading(true);
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `bearer ${user.token}` },
      });
      setCsvData(res.data);
    } catch (error) {
      toast.error(
        "Oops that didnt work. Please try again. If the issue persists contact the administrator."
      );
    }
  };

  useEffect(() => {
    if (csvData.results.length > 0) {
      csvLink.current.link.click();
    }
    setCsvLoading(false);
  }, [csvData]);

  return (
    <div>
      <button
        disabled={csvLoading}
        className="btn btn-primary"
        onClick={() => exportToCSV()}
      >
        {csvLoading ? (
          <div>
            Generating CSV{" "}
            <FontAwesomeIcon className="ms-1" icon={faSpinner} spin />
          </div>
        ) : (
          `Export ${count}`
        )}
      </button>

      <CSVLink
        data={csvData.results}
        headers={[...csvData.headers]}
        className="d-none"
        filename={`${filename}.csv`}
        ref={csvLink}
      ></CSVLink>
    </div>
  );
}
