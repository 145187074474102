//React
import React from "react"
import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

//Fetch
import axios from "axios"

//Context
import { useAuthContext } from "../../../Hooks/useAuthContext"

//Icons
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//Components
import MainContainer from "../../../Components/Containers/MainContainer"
import EventDetails from "../Components/EventDetails"
import CreateEditEventForm from "../Components/CreateEditEventForm"
import InnerConItem from "../../../Components/InnerConItem"
import Error from "../../../Components/Errors/Error"

export default function ViewEvent({ updateEvents }) {
  const params = useParams()
  const navigate = useNavigate()
  const { user, dispatch } = useAuthContext()
  const [event, setEvent] = useState({})
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  const [edit, setEdit] = useState(false)

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/event/${params.id}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setEvent(res.data)
      } catch (error) {
        if (error.response.status === 401) {
          dispatch({ type: "LOGOUT" })
          navigate("../../expired")
        } else {
          setError(error.response.status)
        }
      } finally {
        setLoading(false)
      }
    }

    getUser()
  }, [params.id, dispatch, navigate, user])

  if (!loading) {
    return error ? (
      <MainContainer>
        <Error item={"Event"} code={error} />
      </MainContainer>
    ) : (
      <MainContainer classes={"d-flex flex-column  p-2"}>
        {edit ? (
          <>
            <div className="d-flex justify-content-between mb-2">
              <h2 className="my-2">Edit Event</h2>
              <button
                className="btn btn-primary my-2 "
                onClick={() => setEdit(false)}
              >
                Back <FontAwesomeIcon icon={faArrowLeft} />
              </button>
            </div>

            <div>
              <CreateEditEventForm
                event={event}
                setEvent={setEvent}
                setEdit={setEdit}
                updateEvents={updateEvents}
              />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-end mb-2">
              <button
                className="btn btn-primary my-2 "
                onClick={() => navigate(-1)}
              >
                Back <FontAwesomeIcon icon={faArrowLeft} />{" "}
              </button>
            </div>
            <div className="col ">
              <div className="primaryShadow p-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <h3 className=" my-2">{event.title}</h3>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => setEdit(true)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
                <hr />
                <div className="mb-3">
                  <EventDetails event={event} />
                </div>

                <h4>Attendees</h4>
                <hr />
                <div>
                  {event.attendees.length > 0 ? (
                    <div
                      className={`d-flex flex-column flex-md-row flex-md-wrap gap-3  p-2`}
                    >
                      {event.attendees.map((attendee) => (
                        <InnerConItem
                          type={"person"}
                          key={attendee.id}
                          value={attendee.name}
                          url={`../../contacts/view_contact/${attendee.contactId}`}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="mb-3 text-center ">No attendees yet.</div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </MainContainer>
    )
  }
}
