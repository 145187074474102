import React from "react"
import { FloatingLabel, Form } from "react-bootstrap"
import DistrictList from "../../DistrictList.json"

export default function FloatingDistrictSelect({
  value,
  setField,
  field,
  required = false,
  classes,
}) {
  return (
    <Form.Group className={`mb-1 ${classes} p-1`}>
          <div className="form-floating">
        <Form.Select
          value={value ?? ""}
          required={required}
          onChange={(e) => setField(field, e.target.value)}
        >
          <option value="">Select district</option>
          <option value="All">All</option>
          {DistrictList.districts.map((district) => (
            <option key={district.name} value={district.name}>
              {district.name}
            </option>
          ))}
        </Form.Select>
        <label>
            District{required && <span className="text-danger">*</span>}
        </label>
      </div>
    </Form.Group>
  )
}
