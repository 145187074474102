import { toast } from "react-toastify"
import axios from "axios"
import FloatingDistrictSelect from "../../../Components/SelectDropdowns/FloatingDistrictSelect"
import FloatingLocalitySelect from "../../../Components/SelectDropdowns/FloatingLocalitySelect"
import FloatingParishSelect from "../../../Components/SelectDropdowns/FloatingParishSelect"
import FloatingUnitarySelect from "../../../Components/SelectDropdowns/FloatingUnitarySelect"
//Bootstrap
import { FloatingLabel, Form } from "react-bootstrap"
import { useAuthContext } from "../../../Hooks/useAuthContext"
import { useState } from "react"
import FormControl from "../../../Components/Forms/FormControl"

import FormCheck from "../../../Components/Forms/FormCheck"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

export default function AssetAddContact({ setAddContact, setBuilding }) {
  //user context
  const { user } = useAuthContext()
  const [form, setForm] = useState({
    subscribedToNewsletter: true,
    groupName: "",
    locality: "Unknown",
    actMailings: "Unknown",
    buildings: [],
    deletedBuildings: [],
  })
  //submitting state
  const [submitting, setSubmitting] = useState(false)

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })
  }

  const handleSubmit = async () => {
    setSubmitting(true)

    if (
      !form.firstName ||
      !form.lastName ||
      !form.contactType ||
      !form.actMailings
    ) {
      toast.error("Please fill in all * required fields.")
      setSubmitting(false)
      return
    }

    //create
    try {
      const res = await axios.post(
        `https://${process.env.REACT_APP_IP}/api/contact`,
        form,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      toast.success("Contact created.")

      setAddContact(false)

      setBuilding((prev) => {
        return {
          ...prev,
          contacts: [...prev.contacts, res.data],
          contactsToRemove: prev.contactsToRemove.filter(
            (c) => c.id !== res.data.id
          ),
        }
      })
    } catch (err) {
      console.log(err)
      toast.error(
        "Oops that didn't work. Please try again. If the issue persists contact the administrator."
      )
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <div className="primaryShadow p-2 ">
      <div className="mb-3">
        <h4 className="ps-2">Personal Details</h4>
        <hr />
        <div className="d-flex flex-column flex-md-row  ">
          <Form.Group className="mb-1 p-1 col">
            <FloatingLabel label={"Title"}>
              <Form.Select
                value={form.title ?? ""}
                onChange={(e) => setField("title", e.target.value)}
              >
                <option disabled value="">
                  Select...
                </option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Ms">Ms</option>
                <option value="Miss">Miss</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>

          <FormControl
            value={form.firstName}
            label={"First Name"}
            field={"firstName"}
            setField={setField}
            classes={"col-md-5"}
            required
          />
          <FormControl
            value={form.lastName}
            label={"Last Name"}
            field={"lastName"}
            setField={setField}
            classes={"col-md-5"}
            required
          />
        </div>
        <div className="d-flex flex-column flex-md-row ">
          <FloatingLocalitySelect
            value={form.locality}
            label={"Locality/Panel"}
            field={"locality"}
            setField={setField}
            classes={"col-md-4"}
          />
          <FloatingUnitarySelect
            value={form.unitaryAuthority}
            field={"unitaryAuthority"}
            setField={setField}
            classes={"col-md-4"}
            required
          />

          <Form.Group className="mb-1 p-1 col-md-4">
            <div className="form-floating">
              <Form.Select
                value={form.contactType ?? ""}
                onChange={(e) => setField("contactType", e.target.value)}
                required
              >
                <option disabled value="">
                  Select type...
                </option>
                <option value="ACT Trustee">ACT Trustee</option>
                <option value="Attendee">Attendee</option>
                <option value="Community Asset/Building Group">
                  Community Asset/Building Group
                </option>
                <option value="Community Planning Group">
                  Community Planning Group
                </option>
                <option value="Individual">Individual</option>
                <option value="Local Authority">Local Authority</option>
                <option value="Parish Council">Parish Council</option>
                <option value="Strategic Partner">Strategic Partner</option>
                <option value="Other">Other</option>
              </Form.Select>
              <label>
                Contact Type <span className="text-danger">*</span>
              </label>
            </div>
          </Form.Group>
        </div>

        <div className="d-flex flex-column flex-md-row ">
          <FormControl
            value={form.groupName ?? ""}
            label={"Group/Organization Name"}
            field={"groupName"}
            setField={setField}
            classes={"col"}
          />
          <FormControl
            value={form.jobTitle ?? ""}
            label={"Job Title"}
            field={"jobTitle"}
            setField={setField}
            classes={"col-md-5"}
          />
        </div>

        <div className="d-flex flex-column flex-md-row ">
          <FloatingParishSelect
            value={form.parish}
            field={"parish"}
            setField={setField}
            classes={"col-md-4"}
          />
          <FloatingDistrictSelect
            value={form.district}
            field={"district"}
            setField={setField}
            classes={"col-md-4"}
          />
        </div>
      </div>
      <div className="mb-3">
        <h4 className="ps-2">Address</h4>
        <hr />
        <div className="d-flex flex-column flex-md-row  ">
          <FormControl
            value={form.addressLine1}
            label={"Address Line 1"}
            field={"addressLine1"}
            setField={setField}
            classes={"col-md-4"}
          />
          <FormControl
            value={form.addressLine2}
            label={"Address Line 2"}
            field={"addressLine2"}
            setField={setField}
            classes={"col-md-4"}
          />
          <FormControl
            value={form.addressLine3}
            label={"Address Line 3"}
            field={"addressLine3"}
            setField={setField}
            classes={"col-md-4"}
          />
        </div>
        <div className="d-flex flex-column flex-md-row ">
          <FormControl
            value={form.county}
            label={"County"}
            field={"county"}
            setField={setField}
            classes={"col-md-4"}
          />
          <FormControl
            value={form.town}
            label={"Town"}
            field={"town"}
            setField={setField}
            classes={"col-md-4"}
          />
          <FormControl
            value={form.postcode}
            label={"Postcode"}
            field={"postcode"}
            setField={setField}
            classes={"col-md-4"}
          />
        </div>
      </div>
      <div className="mb-3">
        <h4 className="ps-2">Contact Info</h4>
        <hr />
        <div className="d-flex flex-column flex-md-row ">
          <FormControl
            value={form.primaryEmail}
            label={"Primary Email"}
            field={"primaryEmail"}
            type={"email"}
            setField={setField}
            classes={"col-md-4"}
          />
          <FormControl
            value={form.secondaryEmail}
            label={"Secondary Email"}
            field={"secondaryEmail"}
            type={"email"}
            setField={setField}
            classes={"col-md-4"}
          />
        </div>
        <div className="d-flex flex-column flex-md-row ">
          <FormControl
            value={form.landline}
            label={"Landline"}
            field={"landline"}
            type={"tel"}
            setField={setField}
            classes={"col-md-4"}
          />
          <FormControl
            value={form.mobile}
            label={"Mobile"}
            field={"mobile"}
            type={"tel"}
            setField={setField}
            classes={"col-md-4"}
          />
        </div>
      </div>

      <div className="d-flex flex-column flex-md-row mb-2">
        <div className="col">
          <h4 className="ps-1">ACT Mailings</h4>
          <hr />
          <Form.Group className="mb-1 p-1 col-md-6">
            <div className="form-floating">
              <Form.Select
                value={form.actMailings ?? ""}
                onChange={(e) => setField("actMailings", e.target.value)}
                required
              >
                <option disabled value="">
                  Select type...
                </option>
                <option value="Unknown">Unknown</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Group Mailings">Group Mailings</option>
              </Form.Select>
              <label>
                ACT Mailings <span className="text-danger">*</span>
              </label>
            </div>
          </Form.Group>

          <FormCheck
            value={form.actStaff}
            label={"ACT Staff"}
            field={"actStaff"}
            setField={setField}
            classes={"col-md-6"}
          />
          <FormCheck
            value={form.actSupporter}
            label={"ACT Supporter"}
            field={"actSupporter"}
            setField={setField}
            classes={"col-md-6"}
          />
          {form.actSupporter && (
            <FormControl
              value={form.dateRecieved ? form.dateRecieved.split("T")[0] : ""}
              type={"date"}
              label={"Date Joined"}
              field={"dateRecieved"}
              setField={setField}
              classes={"col-md-6"}
            />
          )}
          <FormCheck
            value={form.actTrustee}
            label={"ACT Trustee"}
            field={"actTrustee"}
            setField={setField}
            classes={"col-md-6"}
          />

          <FormCheck
            value={form.supporterCheckApril2020}
            label={"Supporter Check"}
            field={"supporterCheckApril2020"}
            setField={setField}
            classes={"col-md-6"}
          />
          <FormControl
            value={form.letterSent ? form.letterSent.split("T")[0] : ""}
            type={"date"}
            label={"Letter Sent"}
            field={"letterSent"}
            setField={setField}
          />
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button
          disabled={submitting}
          type="button"
          onClick={() => handleSubmit()}
          className="btn btn-primary"
        >
          {!submitting ? (
            "Create Contact"
          ) : (
            <div>
              Please wait <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          )}
        </button>
      </div>
    </div>
  )
}
