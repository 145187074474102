//React
import React from "react"
//Components
import ListItem from "../../../Components/ListItem"

export default function InteractionDetails({ interaction }) {
  return (
    <>
      <h3 className=" my-2">Interaction Details</h3>
      <hr />
      <div className="ps-2 mb-3">
        <ListItem
          label={"Recieved"}
          value={new Date(interaction.dateAndTime).toUTCString()}
        />
        {interaction.email && (
          <ListItem
            label={"From"}
            value={`${
              interaction.contactName ? interaction.contactName + " - " : ""
            } ${interaction.email}`}
          />
        )}
        {interaction.user && (
          <ListItem
            label={"For"}
            value={`${interaction.user.firstName} ${interaction.user.lastName}`}
          />
        )}
        {interaction.phone && (
          <ListItem
            label={"From"}
            value={`${
              interaction.contactName ? interaction.contactName + " - " : ""
            } ${interaction.phone}`}
          />
        )}
        {interaction.callDuration && (
          <ListItem
            label={"Call Duration"}
            value={`${interaction.callDuration}`}
          />
        )}
        <ListItem label={"Description"} value={interaction.description} />
      </div>
    </>
  )
}
