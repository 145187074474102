//React
import React from "react"
import { useNavigate } from "react-router-dom"
//Styles
import "./EnquiryStatus.css"

export default function EnquiryStatus({
  openEnquiries = 0,
  enquiriesLastMonth = 0,
}) {
  const navigate = useNavigate()
  return (
    <div className="p-3 enquiryStatus">
      <h4 className="text-center ">Enquiries</h4>
      <hr />
      <div>
        <h5 className=" text-center">There were</h5>
        <div className="d-flex flex-column justify-content-center align-items-center py-3">
          <h2 className="innerCon p-5 recievedTotal">{enquiriesLastMonth}</h2>
        </div>
        <h5 className=" text-center mb-4">Enquiries Last Month</h5>
      </div>
      <hr />
      <div>
        <h5 className=" text-center">There are</h5>
        <div className="d-flex flex-column justify-content-center align-items-center py-3">
          <h2 className="innerCon p-5 openTotal">{openEnquiries}</h2>
        </div>
        <h5 className=" text-center mb-4">Open Enquiries</h5>
      </div>

      <div className="d-flex justify-content-center">
        <button
          className="btn btn-primary"
          onClick={() => navigate("/enquiries")}
        >
          Go to enquiries
        </button>
      </div>
    </div>
  )
}
