//React
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useAuthContext } from "../../../Hooks/useAuthContext"

//Fetch
import axios from "axios"

//Bootstrap
import Form from "react-bootstrap/Form"
import FloatingLabel from "react-bootstrap/FloatingLabel"

//Helper
import { validateForm } from "../../../Helpers/FormValidation"

//Icons
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function ChangePasswordForm({
  userDetails,
  setUserDetails,
  setEdit,
}) {
  const { user } = useAuthContext()

  const [formErrors, setFormErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)

  const changePassword = (data) => {
    setSubmitting(true)
    axios
      .post(
        `https://${process.env.REACT_APP_IP}/api/user/${userDetails.id}`,
        data,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Password changed")
          setUserDetails({
            ...userDetails,
            password1: undefined,
            password2: undefined,
          })
          setEdit(false)
        }
      })
      .catch((err) => {
        console.log(err)
        toast.error(
          "Oops that didnt work. Please try again. If the issue persists contact the administrator."
        )
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  useEffect(() => {
    const findFormErrors = () => {
      const newErrors = validateForm(userDetails)

      setFormErrors(newErrors)
    }
    findFormErrors()
  }, [userDetails])

  const setField = (field, value) => {
    setUserDetails({
      ...userDetails,
      [field]: value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const { password, password2 } = formErrors

    if (!password && !password2) {
      const user = {
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        initials: userDetails.initials,
        email: userDetails.email,
        password: userDetails.password1,
        isAdmin: userDetails.isAdmin,
      }

      changePassword(user)
    }
  }

  return (
    <Form onSubmit={handleSubmit} className="primaryShadow p-2 p-md-3">
      <Form.Group>
        <FloatingLabel label="Password" className="mb-3">
          <Form.Control
            required
            value={userDetails.password1 ?? ""}
            type="password"
            placeholder="Password"
            isInvalid={formErrors.password1}
            isValid={formErrors.password1 === ""}
            onChange={(e) => setField("password1", e.target.value)}
          />
          <Form.Control.Feedback className="text-danger" type="invalid">
            {formErrors.password1}
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
      <Form.Group>
        <FloatingLabel label="Re-Type Password" className="mb-3">
          <Form.Control
            required
            value={userDetails.password2 ?? ""}
            type="password"
            placeholder="Re-Type Password"
            isInvalid={formErrors.password2}
            isValid={formErrors.password2 === ""}
            onChange={(e) => setField("password2", e.target.value)}
          />
          <Form.Control.Feedback className="text-danger" type="invalid">
            {formErrors.password2}
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
      <div className="d-flex justify-content-end">
        <button disabled={submitting} type="submit" className="btn btn-primary">
          {!submitting ? (
            "Submit"
          ) : (
            <div>
              Please wait <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          )}
        </button>
      </div>
    </Form>
  )
}
