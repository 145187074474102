//React
import React from "react"
import { useInView } from "react-cool-inview"

//Components
import MainContainer from "../../../Components/Containers/MainContainer"
import SideContainer from "../../../Components/Containers/SideContainer"
import EventCalendar from "../Components/EventCalendar"
import EventList from "../Components/EventList"

export default function EventsHome({
  events,
  setPagination,
  pagination,
  showMore,
  listEvents,
  count,
}) {
  const { observe } = useInView({
    onEnter: () => {
      listEvents()
    },
  })
  return (
    <div>
      <SideContainer classes={"d-xl-none"}>
        <EventList
          events={events}
          setPagination={setPagination}
          pagination={pagination}
          count={count}
        >
          {showMore && <div ref={observe} className="pb-5"></div>}
        </EventList>
      </SideContainer>
      <MainContainer classes={"d-none d-xl-flex"}>
        <div className="col">
          <EventCalendar events={events} />
        </div>
      </MainContainer>
    </div>
  )
}
