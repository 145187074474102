//React

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//Icons
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import ExportCsv from "../../../Components/ExportCsv";
import AssetFilters from "./AssetFilters";

export default function AssetList({
  assets,
  setPagination,
  pagination,
  children,
  count,
}) {
  // const { user, dispatch } = useAuthContext()

  console.log("count", count);

  const [showFilters, setShowFilters] = useState(false);
  const navigate = useNavigate();
  return (
    <div className=" p-2">
      <div className="my-2 d-flex justify-content-between align-items-center">
        <h2 className="text-center">Buildings</h2>
        <button
          className="btn btn-primary"
          onClick={() => navigate("./create_asset")}
        >
          <FontAwesomeIcon icon={faAdd} />
        </button>
      </div>

      <div className="col mb-3">
        <input
          placeholder="Type to search..."
          value={pagination.search}
          onChange={(e) =>
            setPagination((prev) => {
              return { ...prev, search: e.target.value };
            })
          }
          type="text"
          className="form-control mb-2"
        />
        <div className="d-flex col mb-2 ">
          <button
            className={`btn ${showFilters ? "btn-danger" : "btn-primary"}`}
            onClick={() => setShowFilters(!showFilters)}
          >
            {/* <FontAwesomeIcon icon={faFilter} /> */}
            {!showFilters ? "Show" : "Hide"} Filters
          </button>
        </div>
        {showFilters && (
          <AssetFilters pagination={pagination} setPagination={setPagination} />
        )}
      </div>

      {assets.length > 0 ? (
        <div>
          <div className="d-flex justify-content-between mb-2">
            <div className="d-flex align-items-end">{`Showing ${assets.length}/${count}`}</div>
            <ExportCsv
              filename={"CommunityAssets"}
              count={count}
              url={`https://${
                process.env.REACT_APP_IP
              }/api/communityassets?pageSize=${count}&isCsv=true
              &pageNumber=1&search=${pagination.search}&parish=${
                pagination.parish
              }&district=${pagination.district}&minCapacity=${
                pagination.minCapacity
              }&maxCapacity=${pagination.maxCapacity}&contacts=${
                pagination.contacts
              }&h1=${pagination.h1}&h2=${pagination.h2}&h3=${
                pagination.h3
              }&renewalRequired=${pagination.renewalRequired}&createdStart=${
                pagination.createdStart
              }&createdById=${pagination.createdById.value ?? ""}&createdEnd=${
                pagination.createdEnd
              }&hasCBDEntry=${pagination.hasCBDEntry}`}
            />
          </div>
          {assets.map((asset) => (
            <div
              key={asset.id}
              className="primaryShadow mb-2 p-2 hoverMe sidebarItem"
              onClick={() => navigate(`./view_asset/${asset.id}`)}
            >
              <h5 className="mb-2">{asset.assetName}</h5>
              <div>{asset.parish}</div>
            </div>
          ))}
        </div>
      ) : (
        <div>No assets found...</div>
      )}
      {children}
    </div>
  );
}
