//React
import React from "react"
import { useNavigate } from "react-router-dom"

export default function EventStatus({ event, daysTillEvent = 0 }) {
  const navigate = useNavigate()
  return (
    <div className="primaryShadow p-3">
      <h4 className="text-center ">Next Event</h4>
      <hr />
      {event ? (
        <div>
          <div
            className="text-center innerCon pt-3 p-2 d-flex flex-column hoverMe"
            onClick={() => navigate(`/events/view_event/${event.id}`)}
          >
            <h4>{event.title}</h4>
            {event.venue ? <></> : <h5>To Be Confirmed</h5>}
          </div>
          {daysTillEvent === 0 ? (
            <h5 className="text-center p-2">is today!</h5>
          ) : (
            <h5 className="text-center p-2">in {daysTillEvent} days</h5>
          )}
        </div>
      ) : (
        <div>
          <div className="text-center innerCon pt-3 p-2 d-flex flex-column">
            <h4>No upcoming events</h4>
          </div>
          <h5 className="text-center p-2">Head to events and create one</h5>
        </div>
      )}

      <div className="d-flex justify-content-center ">
        <button className="btn btn-primary" onClick={() => navigate("/events")}>
          Go To Events
        </button>
      </div>
    </div>
  )
}
