import axios from "axios"
//Bootstrap
import Form from "react-bootstrap/Form"
import FloatingLabel from "react-bootstrap/FloatingLabel"
import Button from "react-bootstrap/Button"
//React
import { useState, useEffect } from "react"
//Hooks
import { useAuthContext } from "../../Hooks/useAuthContext"
//Custom Styles
import "./LoginForm.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

export default function LoginForm() {
  const [form, setForm] = useState({ rememberMe: false })
  const { dispatch } = useAuthContext()
  const [formErrors, setFormErrors] = useState("")
  const [loginErr, setLoginErr] = useState("")
  const [submitting, setSubmitting] = useState(false)

  const login = (data) => {
    setSubmitting(true)
    axios
      .post(`https://${process.env.REACT_APP_IP}/api/users/login/`, data)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "LOGIN", payload: res.data })
        }
      })
      .catch((err) => {
        console.log(err)
        if (err.response.status === 400) {
          setLoginErr(err.response.data[0])
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  useEffect(() => {
    const findFormErrors = () => {
      const { email, password } = form

      const newErrors = {}

      // email errors
      if (email !== undefined) {
        if (email === "") {
          newErrors.email = "Email is required"
        } else {
          newErrors.email = ""
        }
      }

      if (password !== undefined) {
        if (password === "") {
          newErrors.password = "Password is required"
        } else {
          newErrors.password = ""
        }
      }

      setFormErrors(newErrors)
    }
    findFormErrors()
  }, [form])

  const setField = (field, value) => {
    setLoginErr("")
    setForm({
      ...form,
      [field]: value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!form.email) {
      setFormErrors((prev) => {
        return { ...prev, email: "Email is required" }
      })
    }
    if (!form.password) {
   
      setFormErrors((prev) => {
        return { ...prev, password: "Password is required" }
      })
    }
    if (formErrors.email === "" && formErrors.password === "") {
      login(form)
    }
  }

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <div className="loginErr mb-3 text-center">
        {loginErr && (
          <span className="loginErr mb-3 text-center text-danger">
            {loginErr}
          </span>
        )}
      </div>

      <Form.Group>
        <FloatingLabel
          controlId="floatingInput"
          label="Email address"
          className="mb-3"
        >
          <Form.Control
            type="email"
            placeholder="name@example.com"
            isInvalid={formErrors.email}
            onChange={(e) => setField("email", e.target.value)}
          />
          <Form.Control.Feedback className="text-danger" type="invalid">
            {formErrors.email}
          </Form.Control.Feedback>
        </FloatingLabel>
        <FloatingLabel controlId="floatingPassword" label="Password">
          <Form.Control
            type="password"
            placeholder="Password"
            isInvalid={formErrors.password}
            onChange={(e) => setField("password", e.target.value)}
          />
          <Form.Control.Feedback className="text-danger" type="invalid">
            {formErrors.password}
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
      <Form.Group className="d-flex justify-content-between align-items-center mt-3">
        <Form.Check
          className="text-black"
          type="checkbox"
          label={`Stay logged in?`}
          onChange={(e) => setField("rememberMe", e.target.checked)}
        />
        <Button type="submit" disabled={submitting}>
          {!submitting ? (
            "Login"
          ) : (
            <div>
              Please wait <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          )}
        </Button>
      </Form.Group>
    </Form>
  )
}
