//React
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//Fetch
import axios from "axios";

//Context
import { useAuthContext } from "../../Hooks/useAuthContext";

//Components
import Error from "../../Components/Errors/Error";
import Spinner from "../../Components/Loading/Spinner";
import EnquiriesByDistrict from "./Components/EnquiriesByDistrict";
import EnquiryStatus from "./Components/EnquiryStatus";
import EventStatus from "./Components/EventStatus";
import InteractionStatus from "./Components/InteractionStatus";

export default function Home({ theme }) {
  const { user, dispatch } = useAuthContext();
  const navigate = useNavigate();
  const [homeInfo, setHomeInfo] = useState({});
  const [greet, setGreet] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getHomeInfo = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/user/overview`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        );
        setHomeInfo(res.data);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch({ type: "LOGOUT" });
          navigate("../expired");
        } else {
          setError(error.response.status);
        }
      } finally {
        setLoading(false);
      }
    };

    getHomeInfo();
  }, [user, dispatch, navigate]);

  useEffect(() => {
    const hrs = new Date().getHours();
    if (hrs < 12) setGreet("Good Morning");
    else if (hrs >= 12 && hrs <= 17) setGreet("Good Afternoon");
    else if (hrs >= 17 && hrs <= 24) setGreet("Good Evening");
  }, []);

  if (!loading) {
    return error ? (
      <Error code={error} />
    ) : (
      <div className="container-xl ">
        <div className="my-2">
          <h2 className="ps-3 py-3">
            {greet} {user.name}
          </h2>
        </div>
        <div className="d-flex justify-content-center flex-column flex-md-row gap-4">
          {/* <div className="d-flex flex-column  col col-md-4 gap-4 "> */}
          {/* <InteractionStatus interactions={homeInfo.interactions} /> */}
          <div className="d-flex gap-4 ">
            <EventStatus
              event={homeInfo.nextEvent}
              daysTillEvent={homeInfo.daysTillNextEvent}
            />
          </div>

          <EnquiriesByDistrict
            theme={theme}
            enquiries={homeInfo.enquiriesLast6Months}
          />
          <div className="col-md-3 primaryShadow">
            <EnquiryStatus
              enquiriesLastMonth={homeInfo.enquiriesLastMonth}
              openEnquiries={homeInfo.openEnquiries}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "70vh" }}
      >
        <Spinner text={"Loading"} size="3x"></Spinner>
      </div>
    );
  }
}
