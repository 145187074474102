import axios from "axios"
//React
import React, { useEffect, useState } from "react"
//React Select
import Select from "react-select"
//Bootstrap
import { Form } from "react-bootstrap"
//Global auth via user
import { useAuthContext } from "../../../Hooks/useAuthContext"
//Components
import DistrictSelect from "../../../Components/SelectDropdowns/DistrictSelect"
import ParishSelect from "../../../Components/SelectDropdowns/ParishSelect"
import LocalitySelect from "../../../Components/SelectDropdowns/LocalitySelect"

export default function AssetFilters({ setPagination, pagination }) {
  const { user } = useAuthContext()
  const [users, setUsers] = useState([])

  useEffect(() => {
    const listUsers = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/users?pageSize=500`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        var userSelectList = []
        res.data.map((user) => {
          userSelectList = [
            ...userSelectList,
            { label: user.firstName + ", " + user.lastName, value: user.id },
          ]
        })
        setUsers(userSelectList)
      } catch (error) {
        console.log(error)
      }
    }

    listUsers()
  }, [user])

  const setField = (field, value) => {
    setPagination({
      ...pagination,
      [field]: value,
    })
  }

  const handleReset = () => {
    setPagination((prev) => {
      return {
        ...prev,
        district: "",
        parish: "",
        contacts: "",
        h1: "",
        h2: "",
        h3: "",
        renewalRequired: "",
        createdById: "",
        createdStart: "",
        createdEnd: "",
        hasCBDEntry: "",
        locality: "",
      }
    })
  }

  return (
    <div className="primaryShadow p-2">
      {/* District */}
      <DistrictSelect
        value={pagination.district}
        setField={setField}
        field={"district"}
        bold
      />
      {/* Parish */}
      <ParishSelect
        value={pagination.parish}
        setField={setField}
        field={"parish"}
        bold
      />
      <LocalitySelect
        value={pagination.locality}
        setField={setField}
        field={"locality"}
        bold
      />

      <div className="d-flex flex-column p-1 mt-2">
        {/* Created By */}
        <Form.Group className="mb-2">
          <label>
            <strong>Created By</strong>
          </label>
          <Select
            isClearable
            options={users}
            value={pagination.createdById}
            onChange={(value) => {
              setField("createdById", value ?? "")
            }}
            styles={{ color: "black" }}
            placeholder="Any Staff Member"
          />
        </Form.Group>
        {/* Created Date Range */}
        <Form.Group className="mb-2">
          <label>
            <strong>Created</strong>
          </label>
          <div className="d-flex">
            <Form.Group className="col-6 d-flex flex-column p-1">
              <Form.Label>
                <strong>Start</strong>
              </Form.Label>
              <Form.Control
                type="date"
                value={pagination.createdStart ?? ""}
                onChange={(e) => setField("createdStart", e.target.value)}
              />
            </Form.Group>
            <Form.Group className="col-6 d-flex flex-column p-1">
              <Form.Label>
                <strong>End</strong>
              </Form.Label>
              <Form.Control
                type="date"
                value={pagination.createdEnd ?? ""}
                onChange={(e) => setField("createdEnd", e.target.value)}
              />
            </Form.Group>
          </div>
        </Form.Group>
      </div>
      {/* Has Contacts */}
      <Form.Group className={`mb-1 col`}>
        <Form.Label>
          <strong>Has Contacts</strong>
        </Form.Label>
        <div className="d-flex flex-md-row">
          <Form.Group className="d-flex align-items-center col p-1">
            <Form.Label className="me-3 mt-2">All</Form.Label>
            <Form.Check
              type="radio"
              name="contacts"
              onChange={() => setField("contacts", "")}
              checked={pagination.contacts === ""}
            />
          </Form.Group>
          <Form.Group className="d-flex align-items-center col p-1">
            <Form.Label className="me-3 mt-2">Yes</Form.Label>
            <Form.Check
              type="radio"
              name="contacts"
              onChange={() => setField("contacts", true)}
              checked={pagination.contacts === true}
            />
          </Form.Group>
          <Form.Group className="d-flex align-items-center col p-1">
            <Form.Label className="me-3 mt-2">No</Form.Label>
            <Form.Check
              type="radio"
              name="contacts"
              onChange={() => setField("contacts", false)}
              checked={pagination.contacts === false}
            />
          </Form.Group>
        </div>
      </Form.Group>
      {/* Hallmark Achieved */}
      <div className=" d-flex  flex-column p-1 mt-2">
        <label htmlFor="">
          <strong>Hallmark Achieved</strong>
        </label>
        <div className="d-flex  flex-column ">
          <label htmlFor="">
            <strong>H1</strong>
          </label>
          <div className="d-flex flex-md-row">
            <Form.Group className="d-flex align-items-center col p-1">
              <Form.Label className="me-3 mt-2">All</Form.Label>
              <Form.Check
                type="radio"
                name="h1"
                onChange={() => setField("h1", "")}
                checked={pagination.h1 === ""}
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center col p-1">
              <Form.Label className="me-3 mt-2">Yes</Form.Label>
              <Form.Check
                type="radio"
                name="h1"
                onChange={() => setField("h1", true)}
                checked={pagination.h1 === true}
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center col p-1">
              <Form.Label className="me-3 mt-2">No</Form.Label>
              <Form.Check
                type="radio"
                name="h1"
                onChange={() => setField("h1", false)}
                checked={pagination.h1 === false}
              />
            </Form.Group>
          </div>
          <label htmlFor="">
            <strong>H2</strong>
          </label>
          <div className="d-flex flex-md-row">
            <Form.Group className="d-flex align-items-center col p-1">
              <Form.Label className="me-3 mt-2">All</Form.Label>
              <Form.Check
                type="radio"
                name="h2"
                onChange={() => setField("h2", "")}
                checked={pagination.h2 === ""}
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center col p-1">
              <Form.Label className="me-3 mt-2">Yes</Form.Label>
              <Form.Check
                type="radio"
                name="h2"
                onChange={() => setField("h2", true)}
                checked={pagination.h2 === true}
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center col p-1">
              <Form.Label className="me-3 mt-2">No</Form.Label>
              <Form.Check
                type="radio"
                name="h2"
                onChange={() => setField("h2", false)}
                checked={pagination.h2 === false}
              />
            </Form.Group>
          </div>
          <label htmlFor="">
            <strong>H3</strong>
          </label>
          <div className="d-flex flex-md-row">
            <Form.Group className="d-flex align-items-center col p-1">
              <Form.Label className="me-3 mt-2">All</Form.Label>
              <Form.Check
                type="radio"
                name="h3"
                onChange={() => setField("h3", "")}
                checked={pagination.h3 === ""}
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center col p-1">
              <Form.Label className="me-3 mt-2">Yes</Form.Label>
              <Form.Check
                type="radio"
                name="h3"
                onChange={() => setField("h3", true)}
                checked={pagination.h3 === true}
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center col p-1">
              <Form.Label className="me-3 mt-2">No</Form.Label>
              <Form.Check
                type="radio"
                name="h3"
                onChange={() => setField("h3", false)}
                checked={pagination.h3 === false}
              />
            </Form.Group>
          </div>
        </div>
      </div>
      {/* Hallmark Renewal Required */}
      <div className="d-flex flex-column p-1 mt-2">
        <label htmlFor="">
          <strong>Hallmark Renewal Required</strong>
        </label>
        <div className="d-flex flex-md-row">
          <Form.Group className="d-flex align-items-center col p-1">
            <Form.Label className="me-3 mt-2">All</Form.Label>
            <Form.Check
              type="radio"
              name="hallmarkRenewal"
              onChange={() => setField("renewalRequired", "")}
              checked={pagination.renewalRequired === ""}
            />
          </Form.Group>
          <Form.Group className="d-flex align-items-center col p-1">
            <Form.Label className="me-3 mt-2">Yes</Form.Label>
            <Form.Check
              type="radio"
              name="hallmarkRenewal"
              onChange={() => setField("renewalRequired", true)}
              checked={pagination.renewalRequired === true}
            />
          </Form.Group>
          <Form.Group className="d-flex align-items-center col p-1">
            <Form.Label className="me-3 mt-2">No</Form.Label>
            <Form.Check
              type="radio"
              name="hallmarkRenewal"
              onChange={() => setField("renewalRequired", false)}
              checked={pagination.renewalRequired === false}
            />
          </Form.Group>
        </div>
      </div>
      {/* CBD Entry */}
      <div className="d-flex flex-column p-1 mt-2">
        <label htmlFor="">
          <strong>CBD Entry</strong>
        </label>
        <div className="d-flex flex-md-row">
          <Form.Group className="d-flex align-items-center col p-1">
            <Form.Label className="me-3 mt-2">All</Form.Label>
            <Form.Check
              type="radio"
              name="cbdEntry"
              onChange={() => setField("hasCBDEntry", "")}
              checked={pagination.hasCBDEntry === ""}
            />
          </Form.Group>
          <Form.Group className="d-flex align-items-center col p-1">
            <Form.Label className="me-3 mt-2">Yes</Form.Label>
            <Form.Check
              type="radio"
              name="cbdEntry"
              onChange={() => setField("hasCBDEntry", true)}
              checked={pagination.hasCBDEntry === true}
            />
          </Form.Group>
          <Form.Group className="d-flex align-items-center col p-1">
            <Form.Label className="me-3 mt-2">No</Form.Label>
            <Form.Check
              type="radio"
              name="cbdEntry"
              onChange={() => setField("hasCBDEntry", false)}
              checked={pagination.hasCBDEntry === false}
            />
          </Form.Group>
        </div>
      </div>

      <div className="d-flex justify-content-end mt-3">
        <button className="btn btn-danger" onClick={handleReset}>
          Reset Filters
        </button>
      </div>
    </div>
  )
}
