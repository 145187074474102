//React
import React from "react"
import { useEffect, useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { useAuthContext } from "../../Hooks/useAuthContext"
import { ToastContainer } from "react-toastify"
import { useInView } from "react-cool-inview"

//Fetch
import axios from "axios"

//Components
import SideContainer from "../../Components/Containers/SideContainer"
import InteractionList from "./Components/InteractionList"
import InteractionsHome from "./Pages/InteractionsHome"
import ViewInteraction from "./Pages/ViewInteraction"
import CreateInteraction from "./Pages/CreateInteraction"
import Loading from "../../Components/Loading/Loading"
import Error from "../../Components/Errors/Error"

export default function Interactions() {
  const { user, dispatch } = useAuthContext()
  const navigate = useNavigate()
  const [interactions, setInteractions] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  //Pagination
  const [showMore, setShowMore] = useState(false)
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    search: "",
    accepted: "",
  })

  const listInteractions = async (searching) => {
    try {
      const res = await axios.get(
        `https://${process.env.REACT_APP_IP}/api/interactions?pageNumber=${pagination.pageNumber}&search=${pagination.search}&accepted=${pagination.accepted}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )

      const ints = res.data.interactions
      const count = res.data.count

      if (count === 0) {
        setInteractions([])
        setShowMore(false)
      } else if (ints.length === 0) {
        setShowMore(false)
      } else if (ints.length < 15) {
        if (searching) {
          setInteractions(ints)
        } else {
          setInteractions((prev) => {
            return [...prev, ...ints]
          })
        }
        setShowMore(false)
      } else {
        if (searching) {
          setInteractions(ints)
        } else {
          setInteractions((prev) => {
            return [...prev, ...ints]
          })
        }
        setPagination((prev) => {
          return { ...prev, pageNumber: pagination.pageNumber + 1 }
        })

        setShowMore(true)
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "LOGOUT" })
        navigate("../expired")
      } else {
        setError(error.response.status)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    listInteractions(true)
    // eslint-disable-next-line
  }, [pagination.accepted])

  const { observe } = useInView({
    onEnter: () => {
      listInteractions()
    },
  })

  const updateInteractions = (id) => {
    setInteractions((prev) => {
      return prev.filter((interaction) => {
        return Number(interaction.id) !== Number(id)
      })
    })
    navigate(-1)
  }

  if (!loading) {
    return error ? (
      <Error code={error} />
    ) : (
      <div className="container-xxl">
        <SideContainer classes={"d-none d-xl-block"}>
          <InteractionList
            interactions={interactions}
            setPagination={setPagination}
            pagination={pagination}
          >
            {showMore && <div ref={observe} className="pb-5"></div>}
          </InteractionList>
        </SideContainer>

        <Routes>
          <Route
            path="/*"
            element={
              <InteractionsHome
                interactions={interactions}
                setPagination={setPagination}
                pagination={pagination}
                showMore={showMore}
                listInteractions={listInteractions}
              />
            }
          />
          <Route
            path="/view_interaction/:id"
            element={
              <ViewInteraction updateInteractions={updateInteractions} />
            }
          />
          <Route
            path="/create_interaction/*"
            element={<CreateInteraction setInteractions={setInteractions} />}
          />
        </Routes>
        <ToastContainer />
      </div>
    )
  } else {
    return <Loading />
  }
}
