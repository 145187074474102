//React
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//Fetch
import axios from "axios";
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext";
//Icons
import {
    faAdd,
    faArrowLeft,
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Notifications
import { toast } from "react-toastify";
//Components
import { FloatingLabel, Form } from "react-bootstrap";
import MainContainer from "../../../Components/Containers/MainContainer";
import Loading from "../../../Components/Loading/Loading";

import FormControl from "../../../Components/Forms/FormControl";
import FormCheck from "../../../Components/Forms/FormCheck"

export default function ProjectReport() {
    const navigate = useNavigate();
    const { user, dispatch } = useAuthContext();

    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);


    const [projectsList, setProjectsList] = useState([]);

    const [fromDate, setFromDate] = useState(new Date());
    const [fromDate2, setFromDate2] = useState(new Date().toISOString("en-GB").split('T')[0]);
    const [toDate, setToDate] = useState(new Date());
    const [toDate2, setToDate2] = useState(new Date().toISOString("en-GB").split('T')[0]);
    const [selectedTimesheetCode, setSelectedTimesheetCode] = useState();

    const [projectReport, setProjectReport] = useState();

    function submitForm() {
        console.log("submit clicked", { fromDate, toDate, selectedTimesheetCode })
        axios.post(`https://${process.env.REACT_APP_IP}/api/timesheets/projectreport`,
            {
                FromDate: fromDate.toISOString().split('T')[0],
                ToDate: toDate.toISOString().split('T')[0],
                TimesheetCode: selectedTimesheetCode
            },
            {
                headers: { Authorization: `bearer ${user.token}` },
            }

        )
        .then((response) => {
            console.log("REsponse:", response.data)
            setProjectReport(response.data);
        });

    };

    

    const handleChangeFromDate = (e) => {
        console.log(e.target.value)
        var date = new Date(e.target.value)
        console.log(date)
        if (!isNaN(date) && date.getFullYear() > 2020) {
            console.log("date valid")
            setFromDate(date);

        }
        else {
            console.log("invalid date")
        }
        console.log(e.target.value)
    };

    const handleChangeToDate = (e) => {
        console.log(e.target.value)
        var date = new Date(e.target.value)
        console.log(date)
        if (!isNaN(date) && date.getFullYear() > 2020) {
            console.log("date valid")
            setToDate(date);

        }
        else {
            console.log("invalid date")
        }
        console.log(e.target.value)
    };

    // ON LOAD - Load all users
    useEffect(() => {
        listProjects();
        setLoading(false);
    }, []);




    const listProjects = async () => {


        try {
            const res = await axios.get(`https://${process.env.REACT_APP_IP}/api/timesheetcodes/TimesheetCode?active=true`,
                {
                    headers: { Authorization: `bearer ${user.token}` },
                }
            );

            setProjectsList(res.data);
        } catch (error) {
            if (error.response.status === 401) {
                dispatch({ type: "LOGOUT" });
                navigate("../../expired");
            } else {
                setError(error.response.status);
            }
        } finally {
            setLoading(false);
        }
    };

    const handlePrint = (e) => {
        e.preventDefault();
        window.print();
    };





    if (!loading) {
        return error ? (
            <MainContainer>Some error...</MainContainer>
        ) : (
            <MainContainer>
                

                    {/* Report Options Panel */}
                    <div style={{ border: "1px solid #ccc", backgroundColor: "#eee" }}  className="no-print">
                        <h4>Report Options</h4>
                        <div className="row">
                            <div className="col-4">
                                <Form.Group className="" >
                                    <div className="form-floating">
                                        <Form.Select onChange={(e) => setSelectedTimesheetCode(e.target.value)} value={selectedTimesheetCode}>
                                            <option disabled value="">Select project...</option>
                                            {projectsList.map((u) => (
                                                <option key={u.code} value={u.code}>
                                                    {u.code} {u.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <label>
                                            Project 
                                        </label>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-3">
                                <Form.Group className={`mb-1  p-1`}>
                                    <div className="form-floating">
                                        <input
                                            type="date"
                                            name="fromDate2"
                                            placeholder="From"
                                            defaultValue={fromDate2}
                                            onChange={handleChangeFromDate}
                                            className="form-control"
                                        />
                                        <label>
                                            From
                                        </label>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-3">
                                <Form.Group className={`mb-1  p-1`}>
                                    <div className="form-floating">
                                        <input
                                            type="date"
                                            name="toDate2"
                                            placeholder="To"
                                            defaultValue={toDate2}
                                            onChange={handleChangeToDate}
                                            className="form-control"
                                        />
                                        <label>
                                            To
                                        </label>
                                    </div>
                                </Form.Group>
                            </div>
                           
                            <div className="col-2">
                                <button type="submit" className="btn btn-primary" onClick={submitForm}>Generate</button>
                            </div>
                        </div>
                    </div>


                    {/* Results */}
                    <div style={{marginTop: 40}}>
                    {projectReport && (
                        <div>
                            <div className="d-flex gap-2">
                                <h4>Project Report - {projectReport.project}</h4>
                                <button className="btn btn-primary no-print" onClick={(e) => handlePrint(e)}>Print</button><br />
                            </div>
                                <strong>Period: {new Date(projectReport.fromDate).toLocaleDateString("en-GB")} - {new Date(projectReport.toDate).toLocaleDateString("en-GB")}</strong>
                                
                            <table style={{width: "100%", marginTop: 40} }>
                                <tr>
                                    <th>Date</th>
                                    <th>User</th>
                                    <th>Task</th>
                                    <th>Hours</th>
                                    <th>Miles</th>
                                    <th>Expenses</th>
                                </tr>

                                {projectReport.data.map((report) => {
                                    return (
                                        <tr key={report.Id}>
                                            <td>{ new Date(report.date).toLocaleDateString("en-GB") }</td>
                                            <td>{ report.user }</td>
                                            <td>{ report.task }</td>
                                            <td style={{textAlign:"right"}}>{report.hours}</td>
                                            <td style={{ textAlign: "right" }}>{report.miles}</td>
                                            <td style={{ textAlign: "right" }}>{report.expenses}</td>
                                        </tr>
                                    )
                                })}
                                    <tr style={{borderTop: "1px solid #ccc"} }>
                                        <th colspan="3">Total</th>
                                        <th style={{ textAlign: "right" }}>{projectReport.totalHours}</th>
                                        <th style={{ textAlign: "right" }}>{projectReport.totalMiles}</th>
                                        <th style={{ textAlign: "right" }}>{projectReport.totalExpenses}</th>
                                </tr>
                            </table>
                        </div>
                    )}
                    </div>

                </MainContainer>
        );
    } else {
        return (
            <MainContainer>
                <Loading />
            </MainContainer>
        );
    }
}
