//React
import React, { useEffect, useState } from "react"
//Fetch
import axios from "axios"
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext"
//Components
import ListItem from "../../../Components/ListItem"
import Spinner from "../../../Components/Loading/Spinner"
import ContactSearch from "./ContactSearch"
import QuickContactForm from "./QuickContactForm"

export default function InteractionContactDetails({
  interaction,
  params,
  setContactComplete,
  contactComplete,
  setForm,
  form,
}) {
  const { user } = useAuthContext()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [suggestedContact, setSuggestedContact] = useState({})

  useEffect(() => {
    const getSuggestedContact = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/contact/suggested?phone=${interaction.phone}&email=${interaction.email}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )

        if (res.data !== "") {
          setSuggestedContact(res.data)
        } else {
          setSuggestedContact(null)
          //   setContactComplete(true)
        }
      } catch (error) {
        setError(true)
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    if (interaction.email || interaction.phone) {
      getSuggestedContact()
    } else {
      setSuggestedContact(null)
      setLoading(false)
    }
  }, [interaction, params.id, user])

  const handleCorrectContact = (contact) => {
    setContactComplete(true)
    setForm({
      ...form,
      contact: contact,
      contactId: contact.id,
    })
  }

  if (!loading) {
    return error ? (
      <div>Some error..</div>
    ) : (
      <div className="primaryShadow mb-3">
        {suggestedContact && !contactComplete && (
          <div className="d-flex flex-column p-3">
            Is this your contact?
            <div>
              <ListItem
                label={"Name"}
                value={`${suggestedContact.firstName} ${suggestedContact.lastName}`}
              />
            </div>
            {!contactComplete && (
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary me-2"
                  onClick={() => handleCorrectContact(suggestedContact)}
                >
                  Yes
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setSuggestedContact(null)
                    // setContactComplete(true)
                  }}
                >
                  No
                </button>
              </div>
            )}
          </div>
        )}
        {!suggestedContact && !contactComplete && (
          <div className="p-3">
            <ContactSearch handleCorrectContact={handleCorrectContact} />
            <QuickContactForm
              setForm={setForm}
              form={form}
              setContactComplete={setContactComplete}
            />
          </div>
        )}
        {contactComplete && (
          <div className="p-3">
            <h3 className="ps-1 my-2">Contact Details</h3>
            <hr />
            <div className="mb-3">
              <ListItem
                label={"Name"}
                value={`${form.contact.firstName} ${form.contact.lastName}`}
              />

              <ListItem
                label={"Email"}
                value={
                  form.contact.primaryEmail ? form.contact.primaryEmail : ""
                }
              />

              <ListItem
                label={"Telephone"}
                value={form.contact.landline ? form.contact.landline : ""}
              />

              <ListItem
                label={"Parish"}
                value={form.contact.parish ? form.contact.parish : ""}
              />
            </div>
          </div>
        )}
      </div>
    )
  } else {
    return <Spinner text={"Loading..."} />
  }
}
