//React
import React from "react";
import { useState } from "react";
//Bootstrap
import { FloatingLabel, Form } from "react-bootstrap";
//Components
import FormControl from "../../../Components/Forms/FormControl";
import FloatingParishSelect from "../../../Components/SelectDropdowns/FloatingParishSelect";
import FloatingUnitarySelect from "../../../Components/SelectDropdowns/FloatingUnitarySelect";

export default function QuickContactForm({
  setForm,
  form,
  setContactComplete,
}) {
  const [contactForm, setContactForm] = useState({});

  const setField = (field, value) => {
    setContactForm({
      ...contactForm,
      [field]: value,
    });
  };

  const handleNext = () => {
    setContactComplete(true);
    setForm({
      ...form,
      contact: contactForm,
    });
  };

  return (
    <Form onSubmit={handleNext}>
      <h4>Or create a new contact</h4>
      {/* <p>*Additional details can be added later.</p> */}
      <hr />
      <div className="d-flex flex-column flex-md-row  ">
        <Form.Group className="mb-1 p-1 col">
          <FloatingLabel label={"Title"}>
            <Form.Select
              value={contactForm.title ?? ""}
              onChange={(e) => setField("title", e.target.value)}
              required
            >
              <option disabled value="">
                Select...
              </option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Ms">Ms</option>
              <option value="Miss">Miss</option>
            </Form.Select>
          </FloatingLabel>
        </Form.Group>

        <FormControl
          value={contactForm.firstName}
          label={"First Name"}
          field={"firstName"}
          setField={setField}
          classes={"col-md-5"}
          required
        />
        <FormControl
          value={contactForm.lastName}
          label={"Last Name"}
          field={"lastName"}
          setField={setField}
          classes={"col-md-5"}
          required
        />
      </div>
      <div className="d-flex flex-column flex-md-row ">
        <FormControl
          value={contactForm.groupName}
          label={"Group/Organization Name"}
          field={"groupName"}
          setField={setField}
          classes={"col"}
          required
        />
        <Form.Group className="mb-1 p-1 col-md-6">
          <div className="form-floating">
            <Form.Select
              value={contactForm.contactType ?? ""}
              onChange={(e) => setField("contactType", e.target.value)}
              required
            >
              <option disabled value="">
                Select type...
              </option>
              <option value="Attendee">Attendee</option>
              <option value="Community Asset/Building Group">
                Community Asset/Building Group
              </option>
              <option value="Community Planning Group">
                Community Planning Group
              </option>
              <option value="Individual">Individual</option>
              <option value="Local Authority">Local Authority</option>
              <option value="Strategic Partner">Strategic Partner</option>
              <option value="Other">Other</option>
            </Form.Select>
            <label>
              Contact Type <span className="text-danger">*</span>
            </label>
          </div>
        </Form.Group>
      </div>
      <div className="d-flex flex-column flex-md-row ">
        <FormControl
          value={contactForm.primaryEmail}
          label={"Primary Email"}
          field={"primaryEmail"}
          type={"email"}
          setField={setField}
          classes={"col-md-6"}
          required={!contactForm.landline ? true : false}
        />
        <FormControl
          value={contactForm.landline}
          label={"Landline"}
          field={"landline"}
          type={"tel"}
          setField={setField}
          classes={"col-md-6"}
          required={!contactForm.primaryEmail ? true : false}
        />
      </div>
      <div className="d-flex flex-column flex-md-row ">
        <FloatingParishSelect
          value={contactForm.parish}
          field={"parish"}
          setField={setField}
          classes={"col-md-6"}
        />
        <FloatingUnitarySelect
          value={contactForm.unitaryAuthority}
          field={"unitaryAuthority"}
          setField={setField}
          classes={"col-md-6"}
          required
        />
      </div>
      <div className="d-flex justify-content-end">
        <button className="btn btn-primary me-2" type="submit">
          Next
        </button>
      </div>
    </Form>
  );
}
