//React
import { React, useState, useEffect } from "react"

import { useNavigate } from "react-router-dom"
//Components

import { FloatingLabel, Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"

// Styles
import  "./Timesheet.css"




export default function TimesheetDayView({ day, entries, setEntries, timesheetCodes, date }) {
    
    const [totalHours, setTotalHours] = useState(0);
    const [totalMiles, setTotalMiles] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState(0);

    console.log("entries:",entries)

    

    useEffect(() => {

        var countHours = 0;
        var countMiles = 0;
        var countExpenses = 0;
        entries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        
        setTotalHours(countHours);
        setTotalMiles(countMiles);
        setTotalExpenses(countExpenses);

    }, [entries])


    return (
        <div style={{ marginBottom: 10 }} >
            <p className="timesheetRowHeader">{day} ({date.toLocaleDateString("en-GB")})</p>

            <div className="row">
                <div className="col-6"><p>Task / Activity</p></div>
                <div className="col-2"><p>Timesheet Code</p></div>
                <div className="col-1"><p>Time (Hours)</p></div>
                <div className="col-1"><p>Travel (miles)</p></div>
                <div className="col-1"><p>Expenses (£)</p></div>
            </div>


            {entries.map((entry, index) => (
                <div key={index} className="entry row">
                    <div className="col-6">{entry.enquiryId ? <a href={"/enquiries/view_enquiry/" + entry.enquiryId}>{entry.task}</a> : <p>{entry.task}</p>}</div>
                    <div className="col-2">{entry.timesheetCode}</div>
                    <div className="col-1">{entry.hours}</div>
                    <div className="col-1">{entry.miles}</div>
                    <div className="col-1">{entry.expenses}</div>
                </div>
            ))}
               
            <div className="row no-print">
                <div className="col-6"><p></p></div>
                <div className="col-2 text-end"><p>Total</p></div>
                <div className="col-1"><p>{totalHours} </p></div>
                <div className="col-1"><p>{totalMiles} </p></div>
                <div className="col-1"><p>£{totalExpenses.toFixed(2)}</p></div>
                <div className="col-1"></div>
            </div>
        </div>
    )
}
