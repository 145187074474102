//React
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
//Fetch
import axios from "axios";
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext";
//Icons
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Components
import MainContainer from "../../../Components/Containers/MainContainer";
import Error from "../../../Components/Errors/Error";
import ContactDetails from "../Components/ContactDetails";
import CreateEditContactForm from "../Components/CreateEditContactForm";
import { toast } from "react-toastify";
import ConfirmDialog from "../../../Components/ConfirmDialog"

export default function ViewContact({ updateContacts, tagList, setTagList }) {
  const params = useParams();
  const navigate = useNavigate();
  const { user, dispatch } = useAuthContext();
  const [contact, setContact] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false)

  const [edit, setEdit] = useState(false);

  console.log(contact);

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/contact/${params.id}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        );
        setContact(res.data);
        setError(false);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch({ type: "LOGOUT" });
          navigate("../../expired");
        } else {
          setError(error.response.status);
        }
      } finally {
        setLoading(false);
      }
    };

    getUser();
  }, [params.id, dispatch, user, navigate]);

  const handleDeleteContact = async (e) => {
    setConfirmDelete(true);
}
const handleConfirm = async (e) => {

try {

  const res = await axios.delete(
    `https://${process.env.REACT_APP_IP}/api/contact/${contact.id}`,
    {
      headers: { Authorization: `bearer ${user.token}` },
    }
  )
  
  setConfirmDelete(false);
  toast.success("Contact deleted.");
  navigate(0);

} catch (error) {

    if (error.response.status === 401) {
        dispatch({ type: "LOGOUT" })
        navigate("../../expired")
    } else {
        setError(true)
    }
} finally {
    setLoading(false)
}
}



  

  const handleReactivateContact = async () => {
    try {
      const res = await axios.patch(
        `https://${process.env.REACT_APP_IP}/api/contact/${contact.id}/reactivate`,
        { ...contact, isActive: true },
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      );
      toast.success("Contact re-activated.");
      updateContacts(res.data, "reactivate");
      navigate("../");
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        dispatch({ type: "LOGOUT" });
        navigate("../../expired");
      } else {
        setError(error.response.status);
      }
    }
  };

  if (!loading) {
    return error ? (
      <MainContainer>
        <Error item={"Contact"} code={error} />
      </MainContainer>
    ) : (
      <MainContainer classes={"d-flex flex-column  p-2"}>
        <ConfirmDialog 
          isVisible={confirmDelete} 
          question="Are you sure you want to delete this contact?"
          handleConfirm={handleConfirm}
          handleCancel={function(){setConfirmDelete(false)}}
        ></ConfirmDialog>


        {edit ? (
          <>
            <div className="d-flex justify-content-between mb-2">
              <h2 className="my-2">Edit Contact</h2>
              <button
                className="btn btn-primary my-2 "
                onClick={() => setEdit(false)}
              >
                Back <FontAwesomeIcon icon={faArrowLeft} />
              </button>
            </div>

            <div>
              <CreateEditContactForm
                contact={contact}
                setContact={setContact}
                setEdit={setEdit}
                updateContacts={updateContacts}
                tagList={tagList}
                setTagList={setTagList}
              />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-end mb-2">
              <button
                className="btn btn-primary my-2 "
                onClick={() => navigate(-1)}
              >
                Back <FontAwesomeIcon icon={faArrowLeft} />{" "}
              </button>
            </div>
            <div className="col ">
              <div className="primaryShadow p-3">
                <div className="d-flex justify-content-between">
                  <div className="d-flex gap-2 align-items-center">
                    <div>
                      <h3 className=" my-2">Contact Details</h3>
                    </div>
                    {(contact.actMailings == "No" || !contact.isActive) && (
                      <div className="">
                        <p className="bg-danger text-white p-1 text-center m-0 rounded">
                          DO NOT CONTACT
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="d-flex justify-content-center align-items-center gap-2">
                    <button
                      className="btn btn-primary"
                      onClick={() => setEdit(true)}
                    >
                      Edit
                    </button>
                    {contact.isActive ? (
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDeleteContact(contact.id)}
                      >
                        Delete
                      </button>
                    ) : (
                      <button
                        className="btn btn-danger"
                        onClick={() => handleReactivateContact(contact.id)}
                      >
                        Re-Activate
                      </button>
                    )}
                  </div>
                </div>
                <hr />
                <ContactDetails contact={contact} setContact={setContact} />
              </div>
            </div>
          </>
        )}
      </MainContainer>
    );
  }
}
