//React
import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
//Fetch
import axios from "axios"

//Context
import { useAuthContext } from "../../../Hooks/useAuthContext"

//Icons
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//Components
import MainContainer from "../../../Components/Containers/MainContainer"
import InteractionDetails from "../Components/InteractionDetails"
import ContactAndEnquiry from "../Components/ContactAndEnquiry"
import Error from "../../../Components/Errors/Error"

export default function ViewInteraction({ updateInteractions }) {
  const params = useParams()
  const navigate = useNavigate()
  const { user, dispatch } = useAuthContext()
  const [interaction, setInteraction] = useState({})
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [form, setForm] = useState({
    contactId: null,
    contact: {},
    enquiryId: null,
    enquiry: {},
  })

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })
  }

  useEffect(() => {
    setForm({
      interactionId: params.id,
      contactId: null,
      contact: {},
      enquiryId: null,
      enquiry: {},
    })
  }, [params.id])

  const handleReject = async () => {
    try {
      await axios.patch(
        `https://${process.env.REACT_APP_IP}/api/interaction/${params.id}`,
        { ...interaction, accepted: false },
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      updateInteractions(params.id)
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "LOGOUT" })
        navigate("../../expired")
      } else {
        setError(error.response.status)
      }
    }
  }

  const handleDelete = async () => {
    try {
      await axios.delete(
        `https://${process.env.REACT_APP_IP}/api/interaction/${params.id}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )

      updateInteractions(params.id)
    } catch (error) {
      setError(true)
    }
  }

  useEffect(() => {
    const getInteraction = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/interaction/${params.id}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setInteraction(res.data)
      } catch (error) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    getInteraction()
  }, [params.id, user.token])

  console.log(interaction)

  if (!loading) {
    return error ? (
      <MainContainer>
        <Error item={"Interaction"} code={error} />
      </MainContainer>
    ) : (
      <MainContainer classes={" p-2"}>
        <div className="d-flex justify-content-end mb-2">
          <button
            className="btn btn-primary my-2 "
            onClick={() => navigate(-1)}
          >
            Back <FontAwesomeIcon icon={faArrowLeft} />{" "}
          </button>
        </div>
        <div className="col mb-3">
          <div className="primaryShadow p-3">
            <InteractionDetails interaction={interaction} />
            <div className="d-flex justify-content-end mt-2">
              {interaction.accepted === null && (
                <>
                  <button
                    className="btn btn-primary me-2"
                    onClick={() => setField("accepted", true)}
                  >
                    Accept
                  </button>
                  <button
                    className="btn btn-primary me-2"
                    onClick={() => handleReject()}
                  >
                    Reject
                  </button>
                </>
              )}
              <button className="btn btn-danger" onClick={() => handleDelete()}>
                Delete
              </button>
            </div>
          </div>
        </div>
        {form.accepted && (
          <ContactAndEnquiry
            interaction={interaction}
            params={params}
            setForm={setForm}
            form={form}
            updateInteractions={updateInteractions}
          />
        )}
      </MainContainer>
    )
  }
}
