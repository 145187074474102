import React from "react"
import { Form } from "react-bootstrap"
import ParishList from "../../ParishList.json"

export default function FloatingParishSelect({
  value,
  setField,
  field,
  required = false,
  classes,
}) {
  return (
    <Form.Group className={`mb-1 ${classes} p-1`}>
      <div className="form-floating">
        <Form.Select
          value={value ?? ""}
          required={required}
          onChange={(e) => setField(field, e.target.value)}
        >
          <option value="">Select parish</option>
         
          {ParishList.parishes.map((parish) => (
            <option key={parish.name} value={parish.name}>
              {parish.name}
            </option>
          ))}
        </Form.Select>
        <label>
          Parish{required && <span className="text-danger">*</span>}
        </label>
      </div>
    </Form.Group>
  )
}
