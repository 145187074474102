//React
import React from "react"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
//Fetch
import axios from "axios"
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext"
//Icons
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Notifications
import { toast } from "react-toastify"
//Bootstrap
import { Form } from "react-bootstrap"
//Components
import FormControl from "../../../Components/Forms/FormControl"
import VenueDetails from "./VenueDetails"

export default function CreateEditEventForm({
  event,
  setEvents,
  setEvent,
  updateEvents,
  setEdit,
}) {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const [form, setForm] = useState({})
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (event) {
      setForm(event)
    }
  }, [event])

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })
  }

  const handleSubmit = async (e) => {
    setSubmitting(true)
    e.preventDefault()
    if (event) {
      //Edit
      try {
        const res = await axios.patch(
          `https://${process.env.REACT_APP_IP}/api/event/${form.id}`,
          form,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        toast.success("Event updated.")
        setEdit(false)
        setEvent(res.data)
        updateEvents(res.data)
      } catch (err) {
        console.log(err)
        toast.error(
          "Oops that didnt work. Please try again. If the issue persists contact the administrator."
        )
      } finally {
        setSubmitting(false)
      }
    } else {
      //create
      try {
        const res = await axios.post(
          `https://${process.env.REACT_APP_IP}/api/event`,
          form,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        toast.success("Event created.")
        setForm({})

        setEvents((prev) => {
          return [...prev, res.data]
        })
        navigate(`../view_event/${res.data.id}`)
      } catch (err) {
        console.log(err)
        toast.error(
          "Oops that didnt work. Please try again. If the issue persists contact the administrator."
        )
      } finally {
        setSubmitting(false)
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit} className="primaryShadow p-2 p-md-3">
      <div className="mb-3">
        <h4 className="ps-1">Event Details</h4>
        <hr />
        <div className="d-flex flex-column flex-md-row  ">
          <FormControl
            value={form.title}
            label={"Title"}
            field={"title"}
            setField={setField}
            classes={"col"}
            required
          />
        </div>

        <div className="d-flex flex-column flex-md-row  ">
          <FormControl
            value={form.description}
            label={"Description"}
            field={"description"}
            setField={setField}
            classes={"col"}
            required
          />
        </div>

        <div className="d-flex flex-column flex-md-row ">
          <FormControl
            value={form.start ? form.start : ""}
            type={"datetime-local"}
            label={"Start Date"}
            field={"start"}
            setField={setField}
            classes={"col-md-6"}
            required
          />
          <FormControl
            value={form.end ? form.end : ""}
            type={"datetime-local"}
            label={"End Date"}
            field={"end"}
            setField={setField}
            classes={"col-md-6"}
            required
          />
        </div>
        <div className="d-flex flex-column flex-md-row mb-3">
          <FormControl
            type="number"
            value={form.ticketQuantity}
            label={"Ticket Quantity"}
            field={"ticketQuantity"}
            setField={setField}
            classes={"col-md-4"}
            required
          />
          <FormControl
            value={form.location}
            label={"Location"}
            field={"location"}
            setField={setField}
            classes={"col"}
          />
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button disabled={submitting} type="submit" className="btn btn-primary">
          {!submitting ? (
            "Submit"
          ) : (
            <div>
              Please wait <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          )}
        </button>
      </div>
    </Form>
  )
}
