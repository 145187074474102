import { useEffect, useState } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
//Styles
import "./Global.css"
import "./App.css"
//Gloabal auth via user
import { useAuthContext } from "./Hooks/useAuthContext"
//Components
import LandingPage from "./Pages/LandingPage"
import TopNav from "./Components/Navigation/TopNav"
import Home from "./Pages/Home/Home"
import useLocalStorage from "use-local-storage"
import Users from "./Pages/Users/Users"
import Contacts from "./Pages/Contacts/Contacts"
import Enquiries from "./Pages/Enquiries/Enquiries"
import CommunityAssets from "./Pages/CommunityAssets/CommunityAssets"
import Interactions from "./Pages/Interactions/Interactions"
import Admin from "./Pages/Admin/Admin"
import Events from "./Pages/Events/Events"
import Spinner from "./Components/Loading/Spinner"
import axios from "axios"
import SessionExpired from "./Pages/Errors/SessionExpired"
import Timesheets from "./Pages/Timesheets/Timesheets"

function App() {
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  )
  const { dispatch, user } = useAuthContext()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const existingUser = JSON.parse(localStorage.getItem("user"))

    const getUser = async () => {
      try {
        await axios.get(
          `https://${process.env.REACT_APP_IP}/api/users/checkstatus`,
          {
            headers: { Authorization: `bearer ${existingUser.token}` },
          }
        )

        // const response = await getInfo(res.data)

        dispatch({ type: "WELCOMEBACK", payload: existingUser })
      } catch (error) {
        console.log(error)
        if (error.response.status === 401) {
          dispatch({ type: "LOGOUT", payload: {} })
        }
      } finally {
        setLoading(false)
      }
    }

    if (existingUser) {
      getUser()
    } else {
      setLoading(false)
    }
  }, [dispatch])

  if (!loading) {
    return (
      <BrowserRouter>
        <div className="app" data-theme={theme}>
          <TopNav setTheme={setTheme} theme={theme} />

          <Routes>
            <Route
              path="/"
              element={!user ? <LandingPage /> : <Navigate to={"/home"} />}
            />

            <Route
              path="/home"
              element={user ? <Home theme={theme} /> : <Navigate to={"/"} />}
            />
            <Route
              path="/users/*"
              element={user ? <Users /> : <Navigate to={"/"} />}
            />
            <Route
              path="/admin/*"
              element={user ? <Admin /> : <Navigate to={"/"} />}
            />
            <Route
              path="/contacts/*"
              element={user ? <Contacts /> : <Navigate to={"/"} />}
            />
            <Route
              path="/events/*"
              element={user ? <Events /> : <Navigate to={"/"} />}
            />
            <Route
              path="/enquiries/*"
              element={user ? <Enquiries /> : <Navigate to={"/"} />}
            />
            <Route
              path="/community_assets/*"
              element={user ? <CommunityAssets /> : <Navigate to={"/"} />}
            />
            <Route
              path="/interactions/*"
              element={user ? <Interactions /> : <Navigate to={"/"} />}
            />
            <Route
              path="/expired"
              element={!user ? <SessionExpired /> : <Navigate to={"/home"} />}
            />
            <Route
                path="/timesheets/*"
                element={user ? <Timesheets /> : <Navigate to={"/"} />}
            />
            {/* <Route path="*" element={<Navigate to={"/"} />} /> */}
            {/* Dont forget to remove this!! */}
            {/* <Route path="/Branding" element={<Branding />} /> */}
          </Routes>
        </div>
      </BrowserRouter>
    )
  } else {
    return (
      <div
        className="App d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
        data-theme={theme}
      >
        <Spinner text={"Loading"} />
      </div>
    )
  }
}

export default App
