import React from "react"
///react
import { useInView } from "react-cool-inview"
import { useState } from "react"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useAuthContext } from "../../Hooks/useAuthContext"
import { ToastContainer } from "react-toastify"

//Fetch
import axios from "axios"

//Components
import UsersList from "./Components/UsersList"
import ViewUser from "./Pages/ViewUser"
import SideContainer from "../../Components/Containers/SideContainer"
import MainContainer from "../../Components/Containers/MainContainer"
import Loading from "../../Components/Loading/Loading"
import Home from "./Pages/Home"
import CreateUser from "./Pages/CreateUser"

//Styles
import "./Users.css"

export default function Users() {
  const { user, dispatch } = useAuthContext()
  const navigate = useNavigate()
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  //Pagination
  const [showMore, setShowMore] = useState(false)
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    search: "",
  })

  const listUsers = async (page, searching) => {
    let pageNum = 1
    if (page) {
      pageNum = page
    } else {
      pageNum = pagination.pageNumber
    }
    try {
      const res = await axios.get(
        `https://${process.env.REACT_APP_IP}/api/users?pageNumber=${pageNum}&search=${pagination.search}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      if (res.data.length === 0) {
        setShowMore(false)
      } else if (res.data.length < 15) {
        if (searching) {
          setUsers(res.data)
        } else {
          setUsers((prev) => {
            return [...prev, ...res.data]
          })
        }
        setShowMore(false)
      } else {
        if (searching) {
          setUsers(res.data)
        } else {
          setUsers((prev) => {
            return [...prev, ...res.data]
          })
        }
        setPagination((prev) => {
          return { ...prev, pageNumber: pageNum + 1 }
        })

        setShowMore(true)
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "LOGOUT" })
        navigate("../expired")
      } else {
        setError(error.response.status)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    listUsers(1, true)
    // eslint-disable-next-line
  }, [pagination.search])

  const { observe } = useInView({
    onEnter: () => {
      listUsers()
    },
  })

  const updateUsers = (updatedUser) => {
    const updatedUsers = users.map((user) => {
      if (user.id === updatedUser.id) {
        return updatedUser
      }
      return user
    })

    setUsers(updatedUsers)
  }

  if (!loading) {
    return error ? (
      <MainContainer>
        <div>Some error..</div>
      </MainContainer>
    ) : (
      <div className="container-xxl">
        <SideContainer classes={"d-none d-xl-block"}>
          <UsersList
            users={users}
            setPagination={setPagination}
            pagination={pagination}
          >
            {showMore && <div ref={observe} className="pb-5"></div>}
          </UsersList>
        </SideContainer>

        <Routes>
          <Route
            path="/*"
            element={
              <Home
                users={users}
                setPagination={setPagination}
                pagination={pagination}
                listUsers={listUsers}
                showMore={showMore}
              />
            }
          />
          <Route
            path="/view_user/:id"
            element={<ViewUser updateUsers={updateUsers} />}
          />
          <Route
            path="/create_user/*"
            element={
              user.isAdmin ? (
                <CreateUser setUsers={setUsers} />
              ) : (
                <Navigate to={"/users"} />
              )
            }
          />
        </Routes>
        <ToastContainer />
      </div>
    )
  } else {
    return <Loading />
  }
}
