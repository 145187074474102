//React
import React from "react"
import { useState } from "react"

//Components
import InteractionContactDetails from "./InteractionContactDetails"
import InteractionEnquiryDetails from "./InteractionEnquiryDetails"

export default function ContactAndEnquiry({
  interaction,
  form,
  params,
  setForm,
  updateInteractions,
}) {
  const [contactComplete, setContactComplete] = useState(false)

  return (
    <div>
      <InteractionContactDetails
        interaction={interaction}
        params={params}
        setContactComplete={setContactComplete}
        contactComplete={contactComplete}
        setForm={setForm}
        form={form}
      />
      {contactComplete && (
        <InteractionEnquiryDetails
          interaction={interaction}
          params={params}
          setForm={setForm}
          form={form}
          updateInteractions={updateInteractions}
        />
      )}
    </div>
  )
}
