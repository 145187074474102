import React, { useEffect, useState } from "react"
import axios from "axios"
import { Form } from "react-bootstrap"
import { useAuthContext } from "../../Hooks/useAuthContext"


export default function UserSelect({
  value,
  setField,
  field,
  required = false,
  classes,
}) {

  const [users, setUsers] = useState([])
  const { user } = useAuthContext()

  useEffect(() => {
    const listUsers = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/users?pageSize=500`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        console.log(res.data)
        setUsers(res.data)
      } catch (error) {
        console.log(error)
      }
    }

    listUsers()
  }, [user])



  return (
    <Form.Group className={`mb-1 ${classes}`}>
      <Form.Label>Staff Member</Form.Label>
      <Form.Select
        value={value ?? ""}
        required={required}
        onChange={(e) => setField(field, e.target.value)}
      >
        <option value="">All staff members</option>
        {users.map((user) => (
          <option key={user.id} value={user.id}>
            {user.lastName}, {user.firstName}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  )
}
