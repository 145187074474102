import React from "react"
import { Form } from "react-bootstrap"

export default function FloatingUnitarySelect({
  value,
  setField,
  field,
  required = false,
  classes,
}) {
  return (
    <Form.Group className={`mb-1 ${classes} p-1`}>
      <div className="form-floating">
        <Form.Select
          value={value ?? ""}
          required={required}
          onChange={(e) => setField(field, e.target.value)}
        >
          <option value="" disabled>
            Select authority
          </option>
          <option value="Cumberland">Cumberland</option>
          <option value="Cumbria Wide">Cumbria Wide</option>
          <option value="Outside Cumbria">Outside Cumbria</option>
          <option value="Westmorland & Furness">Westmorland & Furness</option>
        </Form.Select>
        <label>
          Unitary Authority{required && <span className="text-danger">*</span>}
        </label>
      </div>
    </Form.Group>
  )
}
