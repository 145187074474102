//React
import React from "react"
import { useNavigate } from "react-router-dom"
//Infinite Scroll
import { useInView } from "react-cool-inview"

//Icons
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//Components
import MainContainer from "../../../Components/Containers/MainContainer"
import SideContainer from "../../../Components/Containers/SideContainer"
import EnquiryList from "../Components/EnquiryList"

export default function Home({
  enquiries,
  setPagination,
  pagination,
  showMore,
  listEnquiries,
  count,
}) {
  const navigate = useNavigate()

  const { observe } = useInView({
    onEnter: () => {
      listEnquiries()
    },
  })
  return (
    <div>
      <SideContainer classes={"d-xl-none"}>
        <EnquiryList
          enquiries={enquiries}
          setPagination={setPagination}
          pagination={pagination}
          count={count}
        >
          {showMore && <div ref={observe} className="pb-5"></div>}
        </EnquiryList>
      </SideContainer>
      <MainContainer
        classes={
          "d-none d-xl-flex  justify-content-center align-items-center p-4"
        }
      >
        <div>
          Select an enquiry or click
          <button
            className="btn btn-primary mx-2"
            onClick={() => navigate("./create_enquiry")}
          >
            <FontAwesomeIcon icon={faAdd} />
          </button>
          to create a new enquiry
        </div>
      </MainContainer>
    </div>
  )
}
