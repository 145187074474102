import React from "react"
import "./Loading.css"
import Spinner from "./Spinner"

export default function Loading() {
  return (
    <div className="loadingPage d-flex justify-content-center align-items-center">
      <Spinner text={"Loading"} />
    </div>
  )
}
