//React
import React from "react";
import { Route, Routes } from "react-router-dom";
//Notifications
import { ToastContainer } from "react-toastify";
//Components
import SideContainer from "../../Components/Containers/SideContainer";
import AdminList from "./Components/AdminList";
import AdminHome from "./Pages/AdminHome";
import ExternalRequests from "./Pages/ExternalRequests";
import ManageTags from "./Pages/ManageTags";
import ManageTimesheetCodes from "./Pages/ManageTimesheetCodes";
import ExpenseReport from "./Pages/ExpenseReport";
import ProjectReport from "./Pages/ProjectReport";

export default function Admin() {
  return (
    <div className="container-xxl">
      <SideContainer classes={"d-none d-xl-block"}>
        <AdminList />
      </SideContainer>
      <Routes>
        <Route path="/*" element={<AdminHome />} />
        <Route path="/external_requests" element={<ExternalRequests />} />
        <Route path="/manage_tags" element={<ManageTags />} />
        <Route path="/manage_timesheet_codes" element={<ManageTimesheetCodes />}/>
        <Route path="/projectreport" element={<ProjectReport />}/>
        <Route path="/expensereport" element={<ExpenseReport />}/>
      </Routes>
      <ToastContainer />
    </div>
  );
}
