//React
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

//Icons
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//Components
import EventFilters from "./EventsFilters"

export default function EventList({
  events,
  setPagination,
  pagination,
  children,
  count,
}) {
  // const { event, dispatch } = useAuthContext()

  const [showFilters, setShowFilters] = useState(false)
  const navigate = useNavigate()

  return (
    <div className=" p-2">
      <div className="my-2 d-flex justify-content-between align-items-center">
        <h2 className="text-center">Events</h2>
        <button
          className="btn btn-primary"
          onClick={() => navigate("./create_event")}
        >
          <FontAwesomeIcon icon={faAdd} />
        </button>
      </div>

      <div className="col mb-3">
        <input
          placeholder="Type to search..."
          value={pagination.search}
          onChange={(e) =>
            setPagination((prev) => {
              return { ...prev, search: e.target.value }
            })
          }
          type="text"
          className="form-control mb-2"
        />
        <div className="d-flex col mb-2">
          <button
            className={`btn ${showFilters ? "btn-danger" : "btn-primary"}`}
            onClick={() => setShowFilters(!showFilters)}
          >
            {/* <FontAwesomeIcon icon={faFilter} /> */}
            {!showFilters ? "Show" : "Hide"} Filters
          </button>
        </div>
        {showFilters && (
          <EventFilters pagination={pagination} setPagination={setPagination} />
        )}
      </div>

      {events.length > 0 ? (
        <div>
          <div className="d-flex justify-content-end sticky-top mb-1">
            <div>{`Showing ${events.length}/${count}`}</div>
          </div>
          {events.map((event) => (
            <div
              key={event.id}
              className="primaryShadow mb-2 p-2 hoverMe sidebarItem"
              onClick={() => navigate(`./view_event/${event.id}`)}
            >
              <h5 className="mb-2">{event.title}</h5>
              <div className="mb-1">
                {new Date(event.start).toLocaleDateString("en-GB")}{" "}
                {event.end
                  ? "- " + new Date(event.end).toLocaleDateString("en-GB")
                  : ""}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>No events found...</div>
      )}
      {children}
    </div>
  )
}
