//React
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//Icons
import { faAdd, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Components
import CreateInteractionForm from "./CreateInteractionForm";
import EditInteractionForm from "./EditInteractionForm";
import ViewContactLink from "./ViewContactLink";
//Styles
import "./InteractionListAndForm.css";
import axios from "axios";
import { useAuthContext } from "../../../Hooks/useAuthContext";

export default function InteractionsListAndForm({
  interactions,
  enquiryId,
  setEnquiry,
}) {
  const { user, dispatch } = useAuthContext();
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [editId, setEditId] = useState();

  const sortedInteractions = interactions.sort(function (a, b) {
    if (a.dateAndTime < b.dateAndTime) {
      return -1;
    }
    if (a.dateAndTime > b.dateAndTime) {
      return 1;
    }
    return 0;
  });

  const updateEnquiry = (newInteraction) => {
    const newInteractions = [...interactions, newInteraction];

    setEnquiry((prev) => {
      return {
        ...prev,
        dateOfLastContact: newInteraction.enquiry.dateOfLastContact,
        interactions: newInteractions.sort(function (a, b) {
          if (a.dateAndTime < b.dateAndTime) {
            return -1;
          }
          if (a.dateAndTime > b.dateAndTime) {
            return 1;
          }
          return 0;
        }),
      };
    });
    setShowForm(false);
    };

    const handleEditInteraction = async (id) => {
        setEditId(id);
    }

  const handleDeleteInteraction = async (id) => {
    try {
      const res = await axios.delete(
        `https://${process.env.REACT_APP_IP}/api/interaction/${id}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      );
      console.log(res.data);
      setEnquiry((prev) => {
        return {
          ...prev,
          interactions: prev.interactions.filter(
            (interaction) => interaction.id !== id
          ),
        };
      });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "LOGOUT" });
        navigate("../../expired");
      } else {
        // setError(error.response.status)
      }
    }
  };
  return (
    <div>
      <div className="d-flex justify-content-between">
        <h3 className="ps- mb-3">Interactions</h3>
        <div>
          {!showForm ? (
            <button
              className="btn btn-primary"
              onClick={() => setShowForm(true)}
            >
              <FontAwesomeIcon icon={faAdd} />
            </button>
          ) : (
            <button
              className="btn btn-danger"
              onClick={() => setShowForm(false)}
            >
              Cancel
            </button>
          )}
        </div>
      </div>

      <hr />
          {!showForm ?  (

             

        <div>
          <div>
            {sortedInteractions && sortedInteractions.length > 0 ? (
              sortedInteractions.map((interaction) => (
                  <>
                      {editId == interaction.id ? (<div>

                          <EditInteractionForm
                              interaction={interaction}
                              enquiryId={enquiryId }
                              setEditId={setEditId}
                          /></div>) : (

                <div
                  key={interaction.id}
                  className={
                    interaction.userId
                      ? "d-flex align-items-end flex-column"
                      : ""
                  }
                >
                  <div
                    className={`mb-3  p-3 ${interaction.interactionDirection == "Incoming" ? "incoming me-5 col-10" : "outgoing ms-5 col-10"} -  ${interaction.type}`}
                    key={interaction.id}
                  >
                  <h4 className="text-decoration-underline">{`${interaction.interactionDirection == "Incoming" ? "Incoming" : "Outgoing"} - ${interaction.type}`}</h4>

                          {interaction.interactionDirection == "Incoming" ?
                              /* Show Contact - User */
                              (<div>{interaction.contact ? (<Link to={`/contacts/view_contact/${interaction.contact.id}`}>{`${interaction.contact.firstName} ${interaction.contact.lastName}`}</Link>) : "Unknown"} >
                                  {interaction.user ? <Link to={`/users/view_user/${interaction.user.id}`}> {`${interaction.user.firstName} ${interaction.user.lastName}`}</Link> : " Unknown"}
                                  {" (" + interaction.dateAndTime + ")"}
                              </div>)
                              
                              :
                              /* Show User - Contact */
                              (<div>{interaction.user ? <Link to={`/users/view_user/${interaction.user.id}`}>{`${interaction.user.firstName} ${interaction.user.lastName}`}</Link> : "Unknown"} >
                                  {interaction.contact ? (<Link to={`/contacts/view_contact/${interaction.contact.id}`}>{`${interaction.contact.firstName} ${interaction.contact.lastName}`}</Link>) : " Unknown"}
                                  {" (" + interaction.dateAndTime + ")"}
                              </div>)

                          }




                  
                    {interaction.callDuration && (
                      <div
                        className={`d-flex flex-column flex-md-row mt-2 px-1  `}
                      >
                        <div>Call Duration:</div>
                        <div className="ms-2">
                          {interaction.callDuration} hrs
                        </div>
                      </div>
                    )}
                    {interaction.timeSpent && (
                      <div
                        className={`d-flex flex-column flex-md-row mt-2 px-1  `}
                      >
                        <div>Total Time Spent:</div>
                        <div className="ms-2">{interaction.timeSpent} hrs</div>
                      </div>
                    )}
                    <div className={`d-flex flex-column flex-md-row py-2 px-1`}>
                      <div>{interaction.description}</div>
                    </div>
                    <div className="d-flex justify-content-end">
                        {" "}
                        <button
                            className="btn btn-default"
                            onClick={() => handleEditInteraction(interaction.id)}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDeleteInteraction(interaction.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  </div>
                          </div>)}
                </>
              ))
            ) : (
              <div className="mb-3 text-center ">No interactions found.</div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <CreateInteractionForm
            enquiryId={enquiryId}
            updateEnquiry={updateEnquiry}
          />
        </div>
      )}
    </div>
  );
}
