import React from "react";
import { Form } from "react-bootstrap";
import ParishList from "../../ParishList.json";

export default function ParishSelect({
  value,
  setField,
  field,
  required = false,
  classes,
  bold = false,
}) {
  return (
    <Form.Group className={`mb-1 ${classes}`}>
      {bold ? (
        <Form.Label>
          <strong>Parish</strong>
        </Form.Label>
      ) : (
        <Form.Label>Parish</Form.Label>
      )}
      <Form.Select
        value={value ?? ""}
        required={required}
        onChange={(e) => setField(field, e.target.value)}
      >
        <option value="">All parishes</option>
        {ParishList.parishes.map((parish) => (
          <option key={parish.name} value={parish.name}>
            {parish.name}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
}
