//React
import React from "react";
import { useNavigate } from "react-router-dom";

export default function AdminList() {
  const navigate = useNavigate();

  return (
    <div className=" p-2">
      <div className="my-2 d-flex justify-content-between align-items-center">
        <h2 className="text-center">Admin</h2>
      </div>

      <div className="primaryShadow mb-2 p-2 hoverMe sidebarItem" onClick={() => navigate(`./external_requests`)}>
        <div className="mb-1">Manage External Requests</div>
      </div>
      <div className="primaryShadow mb-2 p-2 hoverMe sidebarItem" onClick={() => navigate(`./manage_tags`)}>
        <div className="mb-1">Manage Tags</div>
      </div>
      <div className="primaryShadow mb-2 p-2 hoverMe sidebarItem" onClick={() => navigate(`./manage_timesheet_codes`)}>
        <div className="mb-1">Manage Timesheet Codes</div>
      </div>

          <h4>Timesheet Reports</h4>
          <div className="primaryShadow mb-2 p-2 hoverMe sidebarItem" onClick={() => navigate(`./expensereport`)}>
              <div className="mb-1">Expense Report</div>
          </div>
          <div className="primaryShadow mb-2 p-2 hoverMe sidebarItem" onClick={() => navigate(`./projectreport`)}>
              <div className="mb-1">Project Report</div>
          </div>
    </div>
  );
}
