//React
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useAuthContext } from "../../../Hooks/useAuthContext";

//Icons
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Fetch
import axios from "axios";

//Components
import MainContainer from "../../../Components/Containers/MainContainer";
import Error from "../../../Components/Errors/Error";
import ListItem from "../../../Components/ListItem";
import ChangePasswordForm from "../Components/ChangePasswordForm";
import EditUserForm from "../Components/EditUserForm";
import Spinner from "../../../Components/Loading/Spinner";

//Styles
import "./ViewUser.css";

export default function ViewUser({ updateUsers }) {
  const params = useParams();
  const navigate = useNavigate();
  const { user, dispatch } = useAuthContext();
  const [userDetails, setUserDetails] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const [edit, setEdit] = useState(false);

  const [buildingsList, setBuildingsList] = useState([]);
  const [contactsList, setContactsList] = useState([]);
  const [enquiriesList, setEnquiriesList] = useState([]);
  const [interacionsList, setInteractionsList] = useState([]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/user/profile/${params.id}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        );
        console.log("res.data", res.data);
        setUserDetails(res.data.user);
        setBuildingsList(res.data.buildingsList);
        setContactsList(res.data.contactsList);
        setEnquiriesList(res.data.enquiriesList);
        setInteractionsList(res.data.interactionsList);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch({ type: "LOGOUT" });
          navigate("../../expired");
        } else {
          setError(error.response.status);
        }
      } finally {
        setLoading(false);
      }
    };

    getUser();
  }, [params.id, user, dispatch, navigate]);

  if (!loading) {
    return error ? (
      <MainContainer>
        <Error item={"User"} code={error} />
      </MainContainer>
    ) : (
      <MainContainer classes={"d-flex flex-column p-2"}>
        {edit ? (
          <>
            <div className="d-flex justify-content-between mb-2">
              <h2 className="my-2">Edit user</h2>
              <button
                className="btn btn-primary my-2 "
                onClick={() => setEdit(false)}
              >
                Back <FontAwesomeIcon icon={faArrowLeft} />
              </button>
            </div>

            <div>
              <EditUserForm
                userDetails={userDetails}
                setUserDetails={setUserDetails}
                setEdit={setEdit}
                updateUsers={updateUsers}
              />
              <h2 className="my-3">Change Password</h2>
              <ChangePasswordForm
                userDetails={userDetails}
                setUserDetails={setUserDetails}
                setEdit={setEdit}
              />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-end mb-2">
              <button
                className="btn btn-primary my-2 "
                onClick={() => navigate(-1)}
              >
                Back <FontAwesomeIcon icon={faArrowLeft} />{" "}
              </button>
            </div>
            <div className="col">
              <div className="primaryShadow p-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <h3 className=" my-2">{`${userDetails.firstName} ${userDetails.lastName} (${userDetails.initials})`}</h3>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    {user.isAdmin && (
                      <button
                        className="btn btn-primary"
                        onClick={() => setEdit(true)}
                      >
                        Edit
                      </button>
                    )}
                  </div>
                </div>
                <hr />
                <div className="ps-2">
                  <h4>User Details</h4>
                  <ListItem label={"Email"} value={userDetails.email} />
                  <ListItem label={"Created"} value={new Date(userDetails.dateCreated).toLocaleDateString("en-GB")} />
                  <ListItem label={"Admin"} value={userDetails.isAdmin ? "Yes" : "No"} />
                  <ListItem label={"Target Hours"} value={userDetails.targetHours + " Hours"} />
                  <ListItem label={"Holiday Allowance"} value={userDetails.holidayAllowance + " Hours"} />
                  <ListItem label={"Holiday Period Start Date"} value={userDetails.holidayPeriodStartDate && new Date(userDetails.holidayPeriodStartDate).toLocaleDateString("en-GB")} />
                </div>
                <hr />
                <div>
                  <h4>Recent User Activity (Last 30 Days)</h4>
                  <h5>Contacts Created</h5>
                  {contactsList && contactsList.length > 0 ? (
                    contactsList.map((contacts) => (
                      <ListItem
                        label={new Date(contacts[0].created).toLocaleDateString(
                          "en-GB"
                        )}
                        value={
                          <div key={contacts[0].created} className="d-flex">
                            {contacts &&
                              contacts.length > 0 &&
                              contacts.map((contact) => (
                                <div key={contact.id} className="me-2">
                                  <Link
                                    to={`../../contacts/view_contact/${contact.id}`}
                                  >
                                    {contact.firstName + " " + contact.lastName}
                                  </Link>
                                </div>
                              ))}
                          </div>
                        }
                      />
                    ))
                  ) : (
                    <div className="mb-2">N/A</div>
                  )}
                  <h5>Buildings Created</h5>
                  {buildingsList && buildingsList.length > 0 ? (
                    buildingsList.map((buildings) => (
                      <ListItem
                        label={new Date(
                          buildings[0].createdDate
                        ).toLocaleDateString("en-GB")}
                        value={
                          <div
                            key={buildings[0].createdDate}
                            className="d-flex"
                          >
                            {buildings &&
                              buildings.length > 0 &&
                              buildings.map((building) => (
                                <div key={building.id} className="me-2">
                                  <Link
                                    to={`../../community_assets/view_asset/${building.id}`}
                                  >
                                    {building.assetName}
                                  </Link>
                                </div>
                              ))}
                          </div>
                        }
                      />
                    ))
                  ) : (
                    <div className="mb-2">N/A</div>
                  )}
                  <h5>Enquiries Opened</h5>
                  {enquiriesList && enquiriesList.length > 0 ? (
                    enquiriesList.map((enquiries) => (
                      <ListItem
                        label={new Date(
                          enquiries[0].dateOfQuery
                        ).toLocaleDateString("en-GB")}
                        value={
                          <div
                            key={enquiries[0].dateOfQuery}
                            className="d-flex"
                          >
                            {enquiries &&
                              enquiries.length > 0 &&
                              enquiries.map((enquiry) => (
                                <div key={enquiry.id} className="me-2">
                                  <Link
                                    to={`../../enquiries/view_enquiry/${enquiry.id}`}
                                  >
                                    {enquiry.title}
                                  </Link>
                                </div>
                              ))}
                          </div>
                        }
                      />
                    ))
                  ) : (
                    <div>N/A</div>
                  )}
                </div>
                <hr />
                <div>
                  <h4>Enquiry Interactions - Time Spent</h4>
                  <div>
                    {interacionsList && interacionsList.length > 0 ? (
                      interacionsList.map((interaction) => (
                        <ListItem
                          label={new Date(interaction.date).toLocaleDateString(
                            "en-GB"
                          )}
                          value={interaction.timeSpent + " (hrs)"}
                        />
                      ))
                    ) : (
                      <div>N/A</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </MainContainer>
    );
  } else {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner style={{ marginTop: 270, marginLeft: 430 }} />
      </div>
    );
  }
}
