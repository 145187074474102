//React
import React from "react"
import { useNavigate } from "react-router-dom"
//Components
import ListItem from "../../../Components/ListItem"

export default function EnquiryList({ enquiries }) {
  const navigate = useNavigate()
  return (
    <div>
      <h3 className="ps- mb-3">Enquiries</h3>
      <hr />
      {enquiries && enquiries.length > 0 ? (
        enquiries.map((enquiry) => (
          <div
            key={enquiry.id}
            className="ps-2 mb-2  p-2 hoverMe innerCon"
            onClick={() =>
              navigate(`../../enquiries/view_enquiry/${enquiry.id}`)
            }
          >
            <h4 className="text-decoration-underline">
              {`${enquiry.title ? enquiry.title + " - " : ""} ${new Date(
                enquiry.dateOfQuery
              ).toLocaleDateString("en-GB")}`}{" "}
            </h4>
            <ListItem label={"Group"} value={enquiry.groupName} />
            <ListItem label={"Group Type"} value={enquiry.groupType} />
            <ListItem label={"Parish"} value={enquiry.parish} />
            <div className="d-flex justify-content-end">
              <button className="btn btn-primary">View</button>
            </div>
          </div>
        ))
      ) : (
        <div className="mb-3 text-center ">No enquiries found.</div>
      )}
    </div>
  )
}
