import React from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Spinner.css";

export default function Spinner({ text, style }) {
  return (
    <div
      style={style}
      className="h-100 d-flex flex-column justify-content-center align-items-center spinner"
    >
      <h3>{text}</h3>
      <FontAwesomeIcon icon={faSpinner} size="3x" spin />
    </div>
  );
}
