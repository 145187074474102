import React from "react"
import { useNavigate } from "react-router-dom"

export default function Error({ code, item }) {
  const navigate = useNavigate()
  const Wrapper = ({ children }) => {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ height: "90vh" }}
      >
        {children}
      </div>
    )
  }

  switch (code) {
    case 404:
      return (
        <Wrapper>
          <h4 className="mb-2">{item} not found.</h4>
          <h5 className="mb-2">Please try again.</h5>
          <div>
            <button className="btn btn-primary" onClick={() => navigate(-1)}>
              Go Back
            </button>
          </div>
        </Wrapper>
      )
    default:
      return (
        <Wrapper>
          <h4 className="mb-2">Oops something went wrong</h4>
          <h5 className="mb-2">
            If this issue persists contact your administrator.
          </h5>
          <div>
            <button className="btn btn-primary" onClick={() => navigate(-1)}>
              Go Back
            </button>
          </div>
        </Wrapper>
      )
  }
}
