import React from "react";
import { Form } from "react-bootstrap";
import DistrictList from "../../DistrictList.json";

export default function DistrictSelect({
  value,
  setField,
  field,
  required = false,
  classes,
  bold = false,
}) {
  return (
    <Form.Group className={`mb-1 ${classes}`}>
      {bold ? (
        <Form.Label>
          <strong>District</strong>
        </Form.Label>
      ) : (
        <Form.Label>District</Form.Label>
      )}
      <Form.Select
        value={value ?? ""}
        required={required}
        onChange={(e) => setField(field, e.target.value)}
      >
        <option value="">All districts</option>
        {DistrictList.districts.map((district) => (
          <option key={district.name} value={district.name}>
            {district.name}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
}
