import React from "react"

import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js"
import { Pie } from "react-chartjs-2"

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend, Title)

export default function EnquiriesPie({ theme, enquiries }) {
  const options = {
    plugins: {
      title: {
        display: true,
        // text: "Enquiries By District",
        color: theme === "dark" ? "white" : "black",
        font: {
          size: "26px",
          weight: "300",
        },
      },
      legend: {
        labels: {
          color: theme === "dark" ? "white" : "black",
        },
      },
    },
    responsive: true,
  }
  const data = {
    labels: [
      "Allerdale",
      "Barrow",
      "Carlisle",
      "Copeland",
      "Eden",
      "South Lakeland",
    ],
    datasets: [
      {
        // label: "# of Assets",
        data: [
          enquiries.filter((asset) => asset.district === "Allerdale").length,
          enquiries.filter((asset) => asset.district === "Barrow").length,
          enquiries.filter((asset) => asset.district === "Carlisle").length,
          enquiries.filter((asset) => asset.district === "Copeland").length,
          enquiries.filter((asset) => asset.district === "Eden").length,
          enquiries.filter((asset) => asset.district === "South Lakeland")
            .length,
        ],
        backgroundColor: [
          "rgba(15, 148, 59, 0.6)",
          "rgba(163, 54, 61, 0.6)",
          "rgba(255, 166, 0, 0.6)",
          "rgba(0, 0, 255, 0.6)",
          "rgba(153, 102, 255, 0.5)",
          "rgba(205, 240, 81, 0.5)",
          "rgba(235, 114, 49, 0.5)",
        ],
        borderColor: [
          "rgba(15, 148, 59, 0.6)",
          "rgba(163, 54, 61, 0.6)",
          "rgba(255, 166, 0, 0.6)",
          "rgba(0, 0, 255, 0.6)",
          "rgba(153, 102, 255, 0.5)",
          "rgba(205, 240, 81, 0.5)",
          "rgba(235, 114, 49, 0.5)",
        ],
        borderWidth: 1,
      },
    ],
  }
  return <Pie options={options} data={data} />
}
