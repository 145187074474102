//React
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

//Icons
import { faAdd, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function InteractionList({
  interactions,
  setPagination,
  children,
}) {
  const [active, setActive] = useState("New")
  const navigate = useNavigate()
  return (
    <div className=" p-2">
      <div className="my-2 d-flex justify-content-between align-items-center">
        <h2 className="text-center">Interactions</h2>
        <button
          className="btn btn-primary"
          onClick={() => navigate("./create_interaction")}
        >
          <FontAwesomeIcon icon={faAdd} />
        </button>
      </div>

      <div className="col mb-3">
        <div className="mb-2">
          <button
            className={`btn btn-primary me-2 ${
              active === "New" ? "active" : ""
            }`}
            onClick={() => {
              setPagination((prev) => {
                return { ...prev, accepted: "" }
              })
              setActive("New")
            }}
          >
            New
          </button>
          <button
            className={`btn btn-primary ${
              active === "Archived" ? "active" : ""
            }`}
            onClick={() => {
              setPagination((prev) => {
                return { ...prev, accepted: "false" }
              })
              setActive("Archived")
            }}
          >
            Archived
          </button>
        </div>
      </div>

      {interactions.length > 0 ? (
        <div>
          {interactions.map((interaction) => (
            <div
              key={interaction.id}
              className="primaryShadow mb-2 p-2 hoverMe sidebarItem"
              onClick={() => navigate(`./view_interaction/${interaction.id}`)}
            >
              <div className="p-1 mb-2">
                {interaction.email && (
                  <FontAwesomeIcon icon={faEnvelope} size="lg" />
                )}
                {interaction.phone && (
                  <FontAwesomeIcon icon={faPhone} size="lg" />
                )}
              </div>

              <div className="mb-1">
                Recieved: {new Date(interaction.dateAndTime).toUTCString()}
                {interaction.lastName}
              </div>

              <div>
                From: {interaction.email ? interaction.email : ""}{" "}
                {interaction.phone ? interaction.phone : ""}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="p-2">No interactions found...</div>
      )}
      {children}
    </div>
  )
}
