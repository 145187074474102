//React
import React from "react"
import { useNavigate } from "react-router-dom"

//Components
import EnquiriesPie from "./EnquiriesPi"
//Styles
import "./CommunityAssetsStatus.css"

export default function EquiriesByDistrict({ theme, enquiries = [] }) {
  const navigate = useNavigate()
  return (
    <div className="col-md-4 primaryShadow communityAssets">
      <div className="p-3 ">
        <h4 className="text-center mb-3">Enquiries By District</h4>
        <hr />
        <div className="pt-2">
          <h5 className="py-3 text-center innerCon">
            <span className="inlineNumber mx-1">
              {enquiries ? enquiries.length : "0"}
            </span>{" "}
            {enquiries.length === 1 ? "Enquiry" : "Enquiries"} in the last 6
            months
          </h5>
        </div>
        <div className="text-white p-3 mb-4">
          {enquiries.length > 0 ? (
            <EnquiriesPie theme={theme} enquiries={enquiries} />
          ) : (
            <></>
          )}
        </div>

        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary"
            onClick={() => navigate("/enquiries")}
          >
            Go To Enquiries
          </button>
        </div>
      </div>
    </div>
  )
}
