//React
import React from "react"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
//Fetch
import axios from "axios"
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext"
//Icons
import { faAdd, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Notifications
import { toast } from "react-toastify"
//Bootstrap
import { FloatingLabel, Form } from "react-bootstrap"
//Components
import FloatingParishSelect from "../../../Components/SelectDropdowns/FloatingParishSelect"
import FormCheck from "../../../Components/Forms/FormCheck"
import FormControl from "../../../Components/Forms/FormControl"
import FloatingDistrictSelect from "../../../Components/SelectDropdowns/FloatingDistrictSelect"
import FloatingUnitarySelect from "../../../Components/SelectDropdowns/FloatingUnitarySelect"
import ContactSearch from "../../../Components/Search/ContactSearch"
import FloatingLocalitySelect from "../../../Components/SelectDropdowns/FloatingLocalitySelect"
import AssetAddContact from "./AssetAddContact"

export default function CreateEditAssetForm({
  asset,
  setAssets,
  setAsset,
  updateAssets,
  setEdit,
  edit,
}) {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const [form, setForm] = useState({
    contacts: [],
    contactsToRemove: [],
    locality: "Unknown",
  })
  const [loading, setLoading] = useState(false)
  const [addContact, setAddContact] = useState(false)

  useEffect(() => {
    if (asset) {
      setForm({ ...asset, contactsToRemove: [] })
    }
  }, [asset])

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })
  }
  console.log(form)
  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    if (asset) {
      //Edit
      try {
        const res = await axios.patch(
          `https://${process.env.REACT_APP_IP}/api/communityasset/${form.id}`,
          form,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        toast.success("Building updated.")
        setEdit(false)
        setAsset(res.data)
        updateAssets(res.data)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    } else {
      //create
      try {
        const res = await axios.post(
          `https://${process.env.REACT_APP_IP}/api/communityasset`,
          form,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        toast.success("Building created.")
        setForm({ isAdmin: false })

        setAssets((prev) => {
          return [...prev, res.data]
        })
        navigate(`../view_asset/${res.data.id}`)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleRemoveContact = (contact) => {
    setForm((prev) => ({
      ...prev,
      contacts: prev.contacts.filter((c) => c.id !== contact.id),
      contactsToRemove: [...prev.contactsToRemove, contact.id],
    }))
  }

  return (
    <Form onSubmit={handleSubmit} className="primaryShadow p-2 p-md-3">
      <div className="mb-3">
        <h4 className="ps-2">Building Details</h4>
        <hr />
        <div className="d-flex flex-column flex-md-row  ">
          <FormControl
            value={form.assetName}
            label={"Building Name"}
            field={"assetName"}
            setField={setField}
            required={true}
            classes={"col"}
          />

          <Form.Group className="mb-1 p-1 col-md-4">
            <FloatingLabel label={"Ownership Type"}>
              <Form.Select
                value={form.ownershipType ?? ""}
                onChange={(e) => setField("ownershipType", e.target.value)}
              >
                <option disabled value="">
                  Select...
                </option>
                <option value="Freehold">Freehold</option>
                <option value="Leasehold">Leasehold</option>
                <option value="Other">Other</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
        </div>

        <div className="d-flex flex-column flex-md-row ">
          <FormControl
            value={form.location}
            label={"Location"}
            field={"location"}
            setField={setField}
            classes={"col-md-4"}
          />
          <FloatingParishSelect
            value={form.parish}
            field={"parish"}
            setField={setField}
            classes={"col-md-4"}
            required
          />
          <FloatingDistrictSelect
            value={form.district}
            field={"district"}
            setField={setField}
            classes={"col-md-4"}
            required
          />
        </div>

        <div className="d-flex flex-column flex-md-row ">
          <Form.Group className="mb-1 p-1 col-md-4">
            <FloatingLabel label={"Building Type"}>
              <Form.Select
                value={form.assetType ?? ""}
                onChange={(e) => setField("assetType", e.target.value)}
              >
                <option disabled value="">
                  Select...
                </option>
                <option value="Faith Group Community Building">
                  Faith Group Community Building
                </option>
                <option value="Local Authority Community Centre">
                  Local Authority Community Centre
                </option>
                <option value="Special Interest Group Community Building">
                  Special Interest Group Community Building
                </option>
                <option value="Village Hall Community Building">
                  Village Hall Community Building
                </option>
                <option value="WI Hall">WI Hall</option>
                <option value="TBC">TBC</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>

          <FloatingLocalitySelect
            value={form.locality}
            label={"Locality/Panel"}
            field={"locality"}
            setField={setField}
            classes={"col-md-4"}
          />
          <FloatingUnitarySelect
            value={form.unitaryAuthority}
            field={"unitaryAuthority"}
            setField={setField}
            classes={"col-md-4"}
            required
          />
        </div>
        <div className="d-flex flex-column flex-md-row ">
          <FormCheck
            value={form.registeredCharity}
            label={"Registered Charity"}
            field={"registeredCharity"}
            setField={setField}
            classes={"col-md-5"}
          />
        </div>
        <div className="d-flex flex-column flex-md-row ">
          {form.registeredCharity && (
            <>
              <FormControl
                value={form.charityNumber}
                label={"Charity Number"}
                field={"charityNumber"}
                setField={setField}
                classes={"col-md-4"}
              />
              <FormControl
                value={form.ccRegName}
                label={"Charity Reg Name"}
                field={"ccRegName"}
                setField={setField}
                classes={"col-md-4"}
              />
              <Form.Group className="mb-1 p-1 col-md-4">
                <FloatingLabel label={"Charity Type"}>
                  <Form.Select
                    value={form.charityType ?? ""}
                    onChange={(e) => setField("charityType", e.target.value)}
                  >
                    <option disabled value="">
                      Select...
                    </option>
                    <option value="Charity Company">Charity Company</option>
                    <option value="CIO Association">CIO Association</option>
                    <option value="CIO Foundation">CIO Foundation</option>
                    <option value="Unincorporated ">Unincorporated</option>
                    <option value="TBC">TBC</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
            </>
          )}
        </div>
        <div className="d-flex flex-column flex-md-row  ">
          <FormControl
            value={
              form.buildingConditionSurveyDate
                ? form.buildingConditionSurveyDate.split("T")[0]
                : ""
            }
            type={"date"}
            label={"Building Condition Survey"}
            field={"buildingConditionSurveyDate"}
            setField={setField}
            classes={"col-md-6"}
          />
          <FormControl
            value={
              form.energySurveyDate ? form.energySurveyDate.split("T")[0] : ""
            }
            type={"date"}
            label={"Energy Survey"}
            field={"energySurveyDate"}
            setField={setField}
            classes={"col-md-6"}
          />
        </div>

        <div className="d-flex flex-column flex-md-row  ">
          <FormControl
            value={form.h1AchievedDate ? form.h1AchievedDate.split("T")[0] : ""}
            type={"date"}
            label={"H1 Achieved"}
            field={"h1AchievedDate"}
            setField={setField}
            classes={"col-md-6"}
          />
          <FormControl
            value={form.h1RenewalDate ? form.h1RenewalDate.split("T")[0] : ""}
            type={"date"}
            label={"H1 Renewal Due"}
            field={"h1RenewalDate"}
            setField={setField}
            classes={"col-md-6"}
          />
        </div>
        <div className="d-flex flex-column flex-md-row  ">
          <FormControl
            value={form.h2AchievedDate ? form.h2AchievedDate.split("T")[0] : ""}
            type={"date"}
            label={"H2 Achieved"}
            field={"h2AchievedDate"}
            setField={setField}
            classes={"col-md-6"}
          />
          <FormControl
            value={form.h2RenewalDate ? form.h2RenewalDate.split("T")[0] : ""}
            type={"date"}
            label={"H2 Renewal Due"}
            field={"h2RenewalDate"}
            setField={setField}
            classes={"col-md-6"}
          />
        </div>
        <div className="d-flex flex-column flex-md-row  ">
          <FormControl
            value={form.h3AchievedDate ? form.h3AchievedDate.split("T")[0] : ""}
            type={"date"}
            label={"H3 Achieved"}
            field={"h3AchievedDate"}
            setField={setField}
            classes={"col-md-6"}
          />
          <FormControl
            value={form.h3RenewalDate ? form.h3RenewalDate.split("T")[0] : ""}
            type={"date"}
            label={"H3 Renewal Due"}
            field={"h3RenewalDate"}
            setField={setField}
            classes={"col-md-6"}
          />
        </div>

        <div className="d-flex flex-column flex-md-row mb-2">
          <FormCheck
            value={form.cbdEntry}
            label={"CBD Entry"}
            field={"cbdEntry"}
            setField={setField}
            classes={"col-md-5"}
          />
        </div>

        <div className="">
          <div className="d-flex justify-content-between">
            <h4 className="ms-2">
              {addContact ? "Create Contact" : "Contacts"}
            </h4>
            <div>
              {addContact ? (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => setAddContact(false)}
                >
                  Cancel
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setAddContact(true)}
                >
                  Create Contact
                </button>
              )}
            </div>
          </div>
          <hr />

          {addContact ? (
            <>
              <AssetAddContact
                setAddContact={setAddContact}
                setBuilding={setForm}
              />
            </>
          ) : (
            <>
              <ContactSearch setForm={setForm} contacts={form.contacts} />
              <div className="my-grid-container">
                {form.contacts.map((contact) => (
                  <div
                    className=" p-2 innerCon mb-1 d-flex justify-content-between align-items-center "
                    key={contact.id}
                  >
                    <div>
                      <h5>
                        {contact.firstName} {contact.lastName}
                      </h5>
                      <div>{contact.contactType}</div>
                    </div>
                    <div className="">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleRemoveContact(contact)
                        }}
                      >
                        X
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        {/* HIDE ON CREATE => DISPLAY ON EDIT */}
      </div>

      <div className="d-flex justify-content-end">
        <button disabled={loading} type="submit" className="btn btn-primary">
          {!loading ? (
            "Submit"
          ) : (
            <div>
              Please wait <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          )}
        </button>
      </div>
    </Form>
  )
}
