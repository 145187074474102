//React
import React from "react"
import { Link } from "react-router-dom"
//Components
import ListItem from "../../../Components/ListItem"
import EnquiryList from "../../Contacts/Components/EnquiryList"
import ContactItem from "./ContactItem"

export default function AssetDetails({ asset }) {
  return (
    <>
      <div className="mb-3 ps-2">
        <ListItem label={"Building Name"} value={asset.assetName} />
        <ListItem label={"Location"} value={asset.location} />
        <ListItem label={"Parish"} value={asset.parish} />
        <ListItem label={"District"} value={asset.district} />
        <ListItem label={"Unitary Authority"} value={asset.unitaryAuthority} />

        <ListItem label={"Building Type"} value={asset.assetType} />

        <ListItem value={asset.ownershipType} label={"Ownership Type"} />
        <ListItem
          label={"Locality/Panel"}
          value={asset.locality ? asset.locality : "Unknown"}
        />
        <ListItem
          value={asset.registeredCharity ? "Yes" : "No"}
          label={"Registered Charity"}
        />
        {asset.ccRegName ? (
          <ListItem value={asset.ccRegName} label={"Charity Reg Name"} />
        ) : (
          <ListItem value={asset.assetName} label={"Charity Reg Name"} />
        )}
        <ListItem value={asset.charityNumber} label={"Charity Number"} />
        <ListItem value={asset.charityType} label={"Charity Type"} />

        <ListItem label={"CBD Entry"} value={asset.cbdEntry ? "Yes" : "No"} />
        <ListItem
          label={"Building Condition Survey"}
          value={
            asset.buildingConditionSurveyDate
              ? new Date(asset.buildingConditionSurveyDate).toLocaleDateString(
                  "en-GB"
                )
              : ""
          }
        />
        <ListItem
          label={"Energy Survey"}
          value={
            asset.energySurveyDate
              ? new Date(asset.energySurveyDate).toLocaleDateString("en-GB")
              : ""
          }
        />

        <hr />
        <ListItem
          label={"H1 Achieved"}
          value={
            asset.h1AchievedDate
              ? new Date(asset.h1AchievedDate).toLocaleDateString("en-GB")
              : ""
          }
        />
        <ListItem
          label={"H1 Renewal"}
          value={
            asset.h1RenewalDate
              ? new Date(asset.h1RenewalDate).toLocaleDateString("en-GB")
              : ""
          }
        />
        <ListItem
          label={"H2 Achieved"}
          value={
            asset.h2AchievedDate
              ? new Date(asset.h2AchievedDate).toLocaleDateString("en-GB")
              : ""
          }
        />
        <ListItem
          label={"H2 Renewal"}
          value={
            asset.h2RenewalDate
              ? new Date(asset.h2RenewalDate).toLocaleDateString("en-GB")
              : ""
          }
        />
        <ListItem
          label={"H3 Achieved"}
          value={
            asset.h3AchievedDate
              ? new Date(asset.h3AchievedDate).toLocaleDateString("en-GB")
              : ""
          }
        />
        <ListItem
          label={"H3 Renewal"}
          value={
            asset.h3RenewalDate
              ? new Date(asset.h3RenewalDate).toLocaleDateString("en-GB")
              : ""
          }
        />
        <div className="mt-4">
          <h3 className="ps- mb-3">Contacts</h3>
          <hr />
          {asset.contacts.length > 0 ? (
            <div
              className={`d-flex flex-column flex-md-row flex-md-wrap gap-3 mb-3  p-2`}
            >
              {asset.contacts.map((contact) => (
                <ContactItem contact={contact} />
              ))}
            </div>
          ) : (
            <div className="mb-3 text-center ">
              Not a contact for any Community Buildings.
            </div>
          )}
        </div>
        <div className="mt-4">
          <EnquiryList enquiries={asset.enquiries} />
        </div>
      </div>

      {/* <h3 className="mb-3 my-2">Questions 11 - 23</h3>
      <hr />
      <div className="mb-3 ps-2">
        <ListItem label={"Stage"} value={asset.stage ? "Yes" : "No"} />
        <ListItem
          label={"Sprung Floor"}
          value={asset.sprungFloor ? "Yes" : "No"}
        />
        <ListItem
          label={"Hearing Loop"}
          value={asset.hearingLoop ? "Yes" : "No"}
        />
        <ListItem
          label={"Sports Markings"}
          value={asset.sportsMarkings ? "Yes" : "No"}
        />

        <ListItem label={"State Of Repair"} value={asset.stateOfRepair} />
        <ListItem label={"Heating System"} value={asset.heatingSystem} />
        <ListItem label={"Construction"} value={asset.construction} />
        <ListItem label={"Other Facilities"} value={asset.otherFacilities} />
        <ListItem
          label={"Ofsted Inspection"}
          value={asset.ofstedInspection ? "Yes" : "No"}
        />
        <ListItem
          label={"Physical Condition"}
          value={asset.physicalCondition}
        />
        <ListItem
          label={"1yr Reserves"}
          value={asset.yearsReserves ? "Yes" : "No"}
        />
        <ListItem label={"Insurer"} value={asset.insurer} />
        <ListItem label={"Insurance Premium"} value={asset.insurancePremium} />
        <ListItem
          label={"Liability Arranged By"}
          value={asset.liabilityArrangedBy}
        />
        <ListItem
          label={"Liability Included"}
          value={asset.liabilityIncluded ? "Yes" : "No"}
        />
        <ListItem
          label={"Liability Cost if Extra"}
          value={asset.liabilityCost}
        />
        <ListItem
          label={"Annual Running Costs"}
          value={asset.annualRunningCosts}
        />
        <ListItem label={"Annual Income"} value={asset.annualIncome} />

        <ListItem
          label={"Letter Sent On"}
          value={
            asset.letterSent
              ? new Date(asset.letterSent).toLocaleDateString("en-GB")
              : ""
          }
        />
      </div>
      <h3 className=" my-2">Questions 24 - 25</h3>
      <hr />
      <div className="mb-3 ps-2">
        <ListItem
          label={"Applied for Funding"}
          value={asset.appliedForFunding ? "Yes" : "No"}
        />
        <ListItem
          value={asset.neighbourhoodForum}
          label={"Neighbourhood Forum"}
        />
        <ListItem value={asset.hadfieldTrust} label={"Hadfield Trust"} />
        <ListItem value={asset.districtCouncil} label={"District Council"} />
        <ListItem value={asset.parishCouncil} label={"Parish Council"} />
        <ListItem
          value={asset.cumbriaCommunityFoundation}
          label={"Cumbria Foundation"}
        />
        <ListItem
          value={asset.northernRockFoundation}
          label={"Northern Rock Foundation"}
        />
        <ListItem value={asset.awardsForAll} label={"Awards For All"} />
        <ListItem value={asset.CommunityFund} label={"Community Fund"} />
        <ListItem
          value={asset.communityParishTrust}
          label={"Community Parish Trust"}
        />
        <ListItem value={asset.otherSuccessful} label={"Other Successful"} />
        <ListItem
          value={asset.otherUnSuccessful}
          label={"Other Un-Successful"}
        />
      </div>

      <h3 className="my-2">Questions 24 - 25</h3>
      <hr />
      <div className="mb-3 ps-2">
        <ListItem
          value={asset.localHourlyCharge}
          label={"Local Hourly Charge"}
        />
        <ListItem
          value={asset.localSessionalCharge}
          label={"Local Sessional Charge"}
        />
        <ListItem value={asset.localDailyCharge} label={"Local Daily Charge"} />
        <ListItem
          value={asset.nonLocalHourlyCharge}
          label={"Non-Local Hourly Charge"}
        />
        <ListItem
          value={asset.nonLocalSessionalCharge}
          label={"Non-Local Sessional Charge"}
        />
        <ListItem
          value={asset.nonLocalDailyCharge}
          label={"Non-Local Daily Charge"}
        />
        <ListItem
          value={asset.privateHourlyCharge}
          label={"Private Hourly Charge"}
        />
        <ListItem
          value={asset.privateSessionalCharge}
          label={"Private Sessional Charge"}
        />
        <ListItem
          value={asset.privateDailyCharge}
          label={"Private Daily Charge"}
        />
        <ListItem
          label={"Have Constitution?"}
          value={asset.haveConstitution ? "Yes" : "No"}
        />
        <ListItem
          label={"Use Constitution?"}
          value={asset.useConstitution ? "Yes" : "No"}
        />
        <ListItem
          value={asset.notUsingConstitutionComments}
          label={"Not Using Constitution Comments"}
        />
      </div>
      <h3 className=" my-2">Questions 24 - 25</h3>
      <hr />
      <div className="mb-3 ps-2">
        <ListItem
          value={asset.frequencyOfCommittee}
          label={"Committee Frequency"}
        />
        <ListItem value={asset.numberOnCommittee} label={"No On Committee"} />
        <ListItem
          label={"User Groups?"}
          value={asset.userGroupsRepresented ? "Yes" : "No"}
        />
        <ListItem
          value={asset.noOfRepresentatives}
          label={"No Of Representatives"}
        />
        <ListItem
          value={asset.percentageAttending}
          label={"Percentage Attending"}
        />
        <ListItem
          label={"Holding Trustees Known?"}
          value={asset.holdingTrusteesKnown ? "Yes" : "No"}
        />
        <ListItem
          label={"Use Hiring Agreement?"}
          value={asset.useHiringAgreement ? "Yes" : "No"}
        />
        <ListItem
          label={"Have Maintenance Plan?"}
          value={asset.haveMaintenancePlan ? "Yes" : "No"}
        />
        <ListItem
          label={"Employ Caretaker?"}
          value={asset.employCaretaker ? "Yes" : "No"}
        />
        <ListItem
          label={"Employ Cleaner?"}
          value={asset.employCleaner ? "Yes" : "No"}
        />
        <ListItem
          label={"Employ Secretary?"}
          value={asset.employBookingSecretary ? "Yes" : "No"}
        />
        <ListItem value={asset.otherEmployees} label={"Other Employees"} />
        <ListItem value={asset.noOfWeeklyUsers} label={"No Of Weekly Users"} />
        <ListItem value={asset.hallUsage} label={"Hall Usage"} />

        <ListItem value={asset.yoga} label={"Yoga"} />
        <ListItem value={asset.taiChi} label={"Tai Chi"} />
        <ListItem value={asset.bowling} label={"Bowling"} />
        <ListItem value={asset.badminton} label={"Badminton"} />

        <ListItem value={asset.wi} label={"WI"} />
        <ListItem value={asset.keepFit} label={"Keep Fit"} />
        <ListItem value={asset.playGroup} label={"Play Group"} />
        <ListItem value={asset.youthClub} label={"Youth Club"} />

        <ListItem value={asset.dancing} label={"Dancing"} />
        <ListItem value={asset.artClass} label={"Art Class"} />
        <ListItem value={asset.mothersToddlers} label={"Mothers & Toddlers"} />
        <ListItem value={asset.dayClub} label={"Day Club"} />

        <ListItem value={asset.actingPlays} label={"Acting & Plays"} />
        <ListItem value={asset.sewing} label={"Sewing"} />
        <ListItem value={asset.dogTraining} label={"Dog Training"} />
        <ListItem value={asset.scottishDancing} label={"Scottish Dancing"} />
        <ListItem value={asset.parishCouncils} label={"Parish Councils"} />

        <ListItem value={asset.noOfMiscWeekly} label={"Misc Weekly"} />
        <ListItem value={asset.noOfMiscMonthly} label={"Misc Monthly"} />
        <ListItem value={asset.noOfMisc6Monthly} label={"Misc 6 Monthly"} />
        <ListItem value={asset.noOfMiscYearly} label={"Misc Yearly"} />

        <ListItem
          label={"Hall Feels Underused?"}
          value={asset.hallUnderused ? "Yes" : "No"}
        />
        <ListItem label={"Have PEL?"} value={asset.PEL ? "Yes" : "No"} />
      </div>

      <h3 className="my-2">Questions 44 - Advertise Using:</h3>
      <hr />
      <div className="mb-3 ps-2">
        <ListItem label={"Posters?"} value={asset.adsPoster ? "Yes" : "No"} />
        <ListItem
          label={"Word Of Mouth?"}
          value={asset.adsWordOfMouth ? "Yes" : "No"}
        />
        <ListItem
          label={"Notice Board?"}
          value={asset.adsNoticeBoard ? "Yes" : "No"}
        />

        <ListItem
          label={"Local Press?"}
          value={asset.adsLocalPress ? "Yes" : "No"}
        />
        <ListItem label={"Flyers?"} value={asset.adsFlyer ? "Yes" : "No"} />
        <ListItem
          label={"Parish Newsletter?"}
          value={asset.adsParishNewsletter ? "Yes" : "No"}
        />
        <ListItem value={asset.otherAds} label={"Other Advertising"} />
      </div>
      <h3 className=" my-2">Questions 44 - Advertise Using:</h3>
      <hr />
      <div className="mb-3 ps-2">
        <ListItem
          label={"Parish Council"}
          value={asset.PCFinancialAssistance ? "Yes" : "No"}
        />
        <ListItem
          label={"District Council"}
          value={asset.dcFinancialAssistance ? "Yes" : "No"}
        />
        <ListItem
          label={"County Council"}
          value={asset.CCFinancialAssistance ? "Yes" : "No"}
        />
        <ListItem
          label={"None"}
          value={asset.noFinancialAssistance ? "Yes" : "No"}
        />
      </div>
      <h3 className="my-2">Questions 44 - Advertise Using:</h3>
      <hr />
      <div className="mb-3 ps-2">
        <ListItem
          value={asset.hallHistoryDescription}
          label={"Hall History/Description"}
        />
        <ListItem value={asset.future} label={"Future"} />
        <ListItem
          value={asset.additionalComments}
          label={"Additional Comments"}
        />
        <ListItem value={asset.noOfTables} label={"No Of Tables"} />
        <ListItem value={asset.noOfChairs} label={"No Of Chairs"} />
        <ListItem value={asset.noOfCrockery} label={"No Of Crockery"} />
        <ListItem value={asset.noOfCutlery} label={"No Of Cutlery"} />
        <ListItem
          value={asset.portableEquipment}
          label={"Portable Equipment"}
        />

        <ListItem
          label={"H2 Recieved"}
          value={
            asset.h2AchievedDate
              ? new Date(asset.h2AchievedDate).toLocaleDateString("en-GB")
              : ""
          }
        />
        <ListItem
          label={"H2 Renewal"}
          value={
            asset.h2RenewalDate
              ? new Date(asset.h2RenewalDate).toLocaleDateString("en-GB")
              : ""
          }
        />
        <ListItem
          label={"Alcohol License"}
          value={asset.alcoholLicense ? "Yes" : "No"}
        /> */}
      {/* </div> */}
    </>
  )
}
