import React from "react"
import { Form } from "react-bootstrap"

export default function FloatingTimesheetCodeSelect({
  value,
  setField,
  field,
  timesheetCodeList,
  required = false,
  classes,
}) {
  return (
      <Form.Group className="mt-1 mx-1 col-6">
          <div className="form-floating">
              <Form.Select
                  value={value ?? ""}
                  required
                  onChange={(e) => setField(field, e.target.value)}
                  styles={{
                      control: (baseStyles) => ({
                          ...baseStyles,
                          height: 58,
                      }),
                  }}
                  className="me-2"
              >
                  <option value="" disabled>
                      Select timesheet code
                  </option>
                  {timesheetCodeList.map((timesheetCode) => (
                      <option key={timesheetCode.id} value={timesheetCode.id}>
                          {timesheetCode.code} - {timesheetCode.name}
                      </option>
                  ))}
              </Form.Select>
              <label>Timesheet Code <span className="text-danger">*</span></label>
          </div>
      </Form.Group>
  )
}
