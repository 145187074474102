//React
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//Fetch
import axios from "axios";
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext";
//Icons
import {
  faAdd,
  faArrowLeft,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Notifications
import { toast } from "react-toastify";
//Components
import MainContainer from "../../../Components/Containers/MainContainer";
import Loading from "../../../Components/Loading/Loading";
import CreateTag from "../Components/CreateTag";
import DeleteAllUnused from "../Components/DeleteAllUnused";

export default function ManageTags() {
  const navigate = useNavigate();
  const { user, dispatch } = useAuthContext();

  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [tagType, setTagType] = useState("ENQUIRY");

  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    const listTags = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/tags?tagType=${tagType}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        );

        setTags(res.data);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch({ type: "LOGOUT" });
          navigate("../../expired");
        } else {
          setError(error.response.status);
        }
      } finally {
        setLoading(false);
      }
    };

    listTags();
  }, [dispatch, user, navigate, tagType]);

  const handleDelete = async (id) => {
    setDeleteLoading(true);
    try {
      await axios.delete(`https://${process.env.REACT_APP_IP}/api/tag/${id}`, {
        headers: { Authorization: `bearer ${user.token}` },
      });

      setTags((prev) => {
        return prev.filter((tag) => {
          return Number(tag.id) !== Number(id);
        });
      });

      toast.success("Tag deleted.");
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "LOGOUT" });
        navigate("../../expired");
      } else {
        setError(error.response.status);
      }
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleEditTag = (tag) => {
    setSelectedTag(tag);
    setShowForm(true);
  };

  if (!loading) {
    return error ? (
      <MainContainer>Some error...</MainContainer>
    ) : (
      <MainContainer>
        <div className="d-flex justify-content-between mb-2">
          <div className="d-flex gap-2">
            {/* <button
              className={`btn btn-primary my-2 ${
                tagType === "CONTACT" && "active"
              }`}
              onClick={() => setTagType("CONTACT")}
            >
              Contact
            </button> */}
            <button
              className={`btn btn-primary my-2 ${
                tagType === "ENQUIRY" && "active"
              }`}
              onClick={() => setTagType("ENQUIRY")}
            >
              Enquiry
            </button>
          </div>
          <div>
            <button
              className="btn btn-primary my-2 "
              onClick={() => navigate(-1)}
            >
              Back <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          </div>
        </div>
        <div className=" primaryShadow p-3">
          <div className="d-flex justify-content-between">
            <h3 className=" mb-3">
              {tagType === "CONTACT" ? "Contact" : "Enquiry"} Tags
            </h3>

            <div>
              {!showForm ? (
                <button
                  className="btn btn-primary"
                  onClick={() => setShowForm(true)}
                >
                  <FontAwesomeIcon icon={faAdd} />
                </button>
              ) : (
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setShowForm(false);
                    setSelectedTag({});
                  }}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
          <hr />
          {!showForm && (
            <div className="col ">
              <div>
                {tags.length > 0 ? (
                  <div>
                    {tags.map((tag) => (
                      <div key={tag.id} className="innerCon p-3 mb-2">
                        <div
                          className={`d-flex flex-md-row justify-content-between  px-1 `}
                        >
                          <div className=" d-flex align-items-center ">
                            {tag.value} ({tag.timesUsed})
                          </div>

                          <div className="d-flex justify-content-end ">
                            <button
                              className="btn btn-primary  me-2"
                              onClick={() => handleEditTag(tag)}
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={() => handleDelete(tag.id)}
                              disabled={deleteLoading}
                            >
                              {!deleteLoading ? (
                                "Delete"
                              ) : (
                                <div>
                                  Please wait
                                  <FontAwesomeIcon icon={faSpinner} spin />
                                </div>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="d-flex justify-content-end mt-3">
                      <DeleteAllUnused setTags={setTags} />
                    </div>
                  </div>
                ) : (
                  <div>No tags created yet.</div>
                )}
              </div>
            </div>
          )}
          {showForm && (
            <CreateTag
              setTags={setTags}
              setShowForm={setShowForm}
              tag={selectedTag}
              tags={tags}
              setSelectedTag={setSelectedTag}
              tagType={tagType}
            />
          )}
        </div>
      </MainContainer>
    );
  } else {
    return (
      <MainContainer>
        <Loading />
      </MainContainer>
    );
  }
}
